import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  BrivoAccessPoint,
  BrivoCommunitySite,
  BrivoSite,
  BrivoStationAccessPoint,
  BrivoStationAccessPointActivity,
} from '@greco/domain-brivo';

//@Controller('brivo')
@Injectable()
export class BrivoService {
  constructor(private http: HttpClient) {}

  //@Get()
  async listBrivoSites(page?: number, limit?: number) {
    return await toPromise(
      this.http.get<PaginatedDto<BrivoSite & { communitySite?: BrivoCommunitySite }>>('/api/brivo', {
        params: { page: page?.toString() || '0', limit: limit?.toString() || '10' },
      })
    );
  }

  //@Get('community/:communityId')
  async getCommunitySite(communityId: string) {
    return await toPromise(this.http.get<BrivoCommunitySite>(`/api/brivo/community/${communityId}`));
  }

  //@Get('community/:communityId/access-points')
  async listAccessPointsForCommunity(communityId: string, page?: number, limit?: number) {
    return await toPromise(
      this.http.get<PaginatedDto<BrivoAccessPoint & { stationAccessPoint?: BrivoStationAccessPoint }>>(
        `/api/brivo/community/${communityId}/access-points`,
        {
          params: { page: page?.toString() || '0', limit: limit?.toString() || '10' },
        }
      )
    );
  }

  //@Get('station/:stationId/access-points')
  async getAccessPointsForStation(stationId: string) {
    return await toPromise(this.http.get<BrivoStationAccessPoint[]>(`/api/brivo/station/${stationId}/access-points`));
  }

  //@Get('station/:stationId/activity/recent')
  async getMostRecentStationAccessPointActivity(stationId: string) {
    return await toPromise(
      this.http.get<BrivoStationAccessPointActivity[]>(`/api/brivo/station/${stationId}/activity/recent`)
    );
  }

  //@Post('community/:communityId/:siteId')
  async linkSiteToCommunity(communityId: string, siteId: string) {
    return await toPromise(this.http.post<BrivoCommunitySite>(`/api/brivo/community/${communityId}/${siteId}`, {}));
  }

  //@Post('station/:stationId/:accessPointId')
  async linkAccessPointToStation(stationId: string, accessPointId: string) {
    return await toPromise(
      this.http.post<BrivoStationAccessPoint>(`/api/brivo/station/${stationId}/${accessPointId}`, {})
    );
  }

  // @Post('station/:stationId/:accessPointId/open')
  async openAccessPoint(stationId: string, accessPointId: string, userId?: string) {
    return await toPromise(
      this.http.post<BrivoStationAccessPointActivity>(`/api/brivo/station/${stationId}/${accessPointId}/open`, {
        userId,
      })
    );
  }

  // @Delete('station/:stationId/:accessPointId')
  async removeAccessPointFromStation(stationId: string, accessPointId: string) {
    return await toPromise(
      this.http.delete<BrivoStationAccessPoint>(`/api/brivo/station/${stationId}/${accessPointId}`)
    );
  }
}
