import { SecurityResource } from '@greco/security';

export const CHECKOUT_STATION_SECURITY_RESOURCE = 'checkout_station';

export enum CheckoutStationSecurityActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export const CheckoutStationSecurityResource: SecurityResource = {
  key: CHECKOUT_STATION_SECURITY_RESOURCE,
  module: 'Sales',
  title: 'Checkout Stations',
  description: 'Create and manage checkout stations',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CheckoutStationSecurityActions.CREATE,
      title: 'Create',
      description: 'Ability to create checkout stations in a community',
    },
    {
      key: CheckoutStationSecurityActions.UPDATE,
      title: 'Update',
      description: 'Ability to update checkout stations in a community',
    },
  ],
};
