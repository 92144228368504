import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import { Contact, ContactStats } from '@greco/identity-contacts';
import {
  CreateContactDto,
  ImportContactDto,
  UpdateContactDto,
  UpdateMemberNumbertDto,
} from '@greco/nestjs-identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { of } from 'rxjs';

@Injectable()
export class ContactService {
  constructor(private http: HttpClient, private snackbar: MatSnackBar, private userService: UserService) {}

  // Get('stats')
  async getStats(communityId: string): Promise<ContactStats> {
    return toPromise(this.http.get<ContactStats>('/api/contacts/stats', { params: { communityId } }));
  }

  // @Get('count')
  async getCount(communityId: string): Promise<{ count: number }> {
    return toPromise(this.http.get<{ count: number }>('/api/contacts/count', { params: { communityId } }));
  }

  async editContact(contactId: string, updateContactDto: UpdateContactDto) {
    return toPromise(this.http.put<Contact>(`/api/contacts/${contactId}`, updateContactDto));
  }

  async editContactMemberNumber(contactId: string, dto: UpdateMemberNumbertDto) {
    return toPromise(this.http.put<Contact>(`/api/contacts/${contactId}/member-number`, dto));
  }

  async getUserContacts(userId?: string) {
    userId = userId ?? (await this.userService.getSelf())?.id;
    return userId ? toPromise(this.http.get<Contact[]>(`/api/contacts/user-contacts/${userId}`)) : toPromise(of([]));
  }

  async subscribeUserToCommunity(communityId: string, userId?: string) {
    if (!userId) userId = await toPromise(this.userService.getUserId());
    const communityIds = (await this.getUserContacts(userId)).map(c => c.community.id);
    if (communityIds.includes(communityId)) {
      this.snackbar.open('Already subscribed to community!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      return toPromise(of(false));
    } else {
      return toPromise(this.http.post<Contact>(`/api/contacts/${communityId}/${userId}`, null));
    }
  }

  // @Get()
  async paginateContacts(
    queryBuilder: RequestQueryBuilder,
    communityId: string,
    pagination?: PaginatedQueryParams
  ): Promise<PaginatedDto<Contact>> {
    return await toPromise(
      this.http.get<PaginatedDto<Contact>>('/api/contacts', {
        params: {
          ...queryBuilder.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  /** \@Get('export?communityId=') */
  async exportContacts(queryBuilder: RequestQueryBuilder, communityId: string) {
    return await toPromise(
      this.http.get<DataExport>('/api/contacts/export', {
        params: {
          ...queryBuilder.queryObject,
          ...(communityId && { communityId }),
        },
      })
    );
  }

  // @Get(':contactId')
  async getContact(contactId: string, ignoreError?: boolean): Promise<Contact> {
    return toPromise(
      this.http.get<Contact>(`/api/contacts/${contactId}`, {
        headers: { 'X-GrecoIgnoreErrors': '' + ignoreError },
      })
    );
  }

  // @Get('member/:memberNumber')
  async getContactByMemberNumber(memberNumber: string, ignoreError?: boolean): Promise<Contact> {
    return toPromise(
      this.http.get<Contact>(`/api/contacts/member/${memberNumber}`, {
        headers: { 'X-GrecoIgnoreErrors': '' + ignoreError },
      })
    );
  }

  // @Get(':contactByEmail')
  async getContactByEmail(email: string, communityId: string, ignoreError?: boolean): Promise<Contact> {
    return toPromise(
      this.http.get<Contact>(`/api/contacts/email`, {
        headers: { 'X-GrecoIgnoreErrors': '' + ignoreError },
        params: {
          email,
          communityId,
        },
      })
    );
  }

  async getContactByUserId(userId: string, communityId: string, ignoreError?: boolean): Promise<Contact> {
    return toPromise(
      this.http.get<Contact>(`/api/contacts/userId`, {
        headers: { 'X-GrecoIgnoreErrors': '' + ignoreError },
        params: {
          userId,
          communityId,
        },
      })
    );
  }

  // @Post()
  async createContact(createContactDto: CreateContactDto): Promise<Contact> {
    return await toPromise(this.http.post<Contact>('/api/contacts', createContactDto));
  }

  // @Post('import')
  async importContacts(importContactDto: ImportContactDto): Promise<any> {
    return toPromise(this.http.post('/api/contacts/import', importContactDto));
  }

  // @Put(':id')
  async updateContact(contactId: string, updateContactDto: UpdateContactDto): Promise<Contact> {
    return toPromise(this.http.put<Contact>(`/api/contacts/${contactId}`, updateContactDto));
  }

  // @Delete(':contactId')
  async deleteContact(contactId: string): Promise<boolean> {
    return toPromise(this.http.delete<boolean>(`/api/contacts/${contactId}`));
  }
}
