<greco-simple-dialog
  [data]="{ title: data.contact ? 'Member Passes' : 'My Passes', content: data.contact ? 'This is the member\'s number or email shown as a QR code.' : 'Please show this code at the door when checking-in to the club.', showCloseButton: true, hideDefaultButton: true }"
>
  <ng-container *ngIf="contacts?.length">
    <qrcode
      style="margin: auto; display: block; width: fit-content"
      [qrdata]="select.value?.memberNumber || select.value?.email"
      [elementType]="'svg'"
      [width]="256"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
    <mat-form-field
      style="width: 100%"
      [style.display]="data.contact || !(contacts | some: notNull) ? 'none' : 'inline-block'"
    >
      <mat-label>Community</mat-label>
      <mat-select #select [value]="contacts[0]">
        <mat-select-trigger>
          <p style="line-height: 1.25em; margin-bottom: 0"><strong>{{select.value.community.name}}</strong></p>
          <p style="line-height: 1.25em; margin-bottom: 0; font-size: 75%">
            {{select.value.memberNumber ? '#' + select.value.memberNumber : select.value.email }}
          </p>
        </mat-select-trigger>
        <mat-option *ngFor="let contact of contacts" [value]="contact">
          <p style="line-height: 1.25em; margin-bottom: 0"><strong>{{contact.community.name}}</strong></p>
          <p style="line-height: 1.25em; margin-bottom: 0; font-size: 75%">
            {{contact.memberNumber ? '#' + contact.memberNumber : contact.email }}
          </p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</greco-simple-dialog>
