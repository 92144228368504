import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { User } from '@greco/identity-users';
import { Toast } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[custom-toast-component]',
  styles: [
    `
      :host {
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 10px 10px 10px 10px;
        width: 300px;
        border-radius: 3px 3px 3px 3px;
        pointer-events: all;
        cursor: pointer;
      }
      .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
    `,
  ],
  template: `
    <div [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div style="display: flex; flex-direction: row; gap: 8px; align-items: center">
        <greco-user-avatar *ngIf="user" [user]="user" size="large" [showOnlineStatus]="false"></greco-user-avatar>
        <div style="display: flex; flex-direction: column;">
          <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
          </div>
          <div
            *ngIf="message && options.enableHtml"
            role="alert"
            [class]="options.messageClass"
            [innerHTML]="message"
          ></div>
          <div
            *ngIf="message && !options.enableHtml"
            role="alert"
            [class]="options.messageClass"
            [attr.aria-label]="message"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomUserToast extends Toast {
  public user?: User;
}
