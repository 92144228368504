import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SecurityService } from '@greco/ngx-security-util';
import { TypeformService } from '@greco/ngx-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription } from '@greco/sales-subscriptions';
import { CommunityFormType } from '@greco/typeform';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CancelSubscriptionDialog, ChangePaymentMethodDialog } from '../../dialogs';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss'],
})
export class SubscriptionsTableComponent implements OnDestroy {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private securitySvc: SecurityService,
    private typeformSvc: TypeformService,
    private communitySvc: CommunityService,
    private subscriptionSvc: SubscriptionsService
  ) {}

  now = new Date().getTime();

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User;

  @Input() accountId?: string;

  @Input() showAccountColumn = false;

  @Output() rowClick = new EventEmitter<any>();

  loading = false;

  cancelling: string[] = [];
  cancellationFormId: string | null = null;

  paginationMeta?: IPaginationMeta;
  currentPagination: PaginationMetadata | null = null;
  private _pageSizes$ = new BehaviorSubject<number[]>([5, 10, 20, 50]);
  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 10 });
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 5 });
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  readonly user$ = this.userSvc.getUserId();
  private refresh$ = new BehaviorSubject<null>(null);

  readonly showCancellationOptions$ = this.userSvc.getUserId().pipe(
    switchMap(userId => (userId ? this.userSvc.getUser(userId) : of(null))),
    map(user => user?.isSuperAdmin || false)
  );

  subscriptions$ = combineLatest([this._user$, this.filters$, this.pagination$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(async ([user, filters, pagination]) => {
      let userId: string | undefined = user?.id;
      if (!userId) await toPromise(this.userSvc.getUserId()).then((value: string | undefined) => (userId = value));

      return userId
        ? await this.subscriptionSvc.getPaginatedSubscriptionsByUserAndAccount(filters, pagination, '', userId)
        : null;
    }),
    tap(data => setTimeout(() => (this.paginationMeta = data?.meta))),
    map(data => data?.items || []),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [5, 10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
      this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  ngOnDestroy() {
    this._user$.complete();
    this._pageSizes$.complete();
    this.refresh$.complete();
  }

  async getTypeform(subscription: Subscription) {
    const communityId = await toPromise(this.communitySvc.getCommunityIdByAccountId(subscription.accountId));
    if (!communityId) return;

    const formconfig = await this.typeformSvc.getCommunityTypeformConfig(
      communityId,
      CommunityFormType.SUBSCRIPTION_CANCEL
    );

    if (!formconfig) {
      this.snacks.open('No form currently configured to complete this action.', 'Ok', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
      return;
    }

    this.router.navigate([`/form/${formconfig.formId}`]);
  }

  async cancel(subscription: Subscription) {
    await toPromise(this.matDialog.open(CancelSubscriptionDialog, { data: subscription }).afterClosed());
    this.refresh$.next(null);
  }

  async changePaymentMethodDialog(subscription: Subscription, user?: User) {
    await toPromise(
      this.matDialog.open(ChangePaymentMethodDialog, { data: { subscription, user }, maxWidth: '90%' }).afterClosed()
    );
    this.refresh$.next(null);
  }

  refresh() {
    this.refresh$.next(null);
  }
}
