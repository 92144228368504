import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventDetails as EventDetailsModel,
  EventResourceAssignment,
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  EventSecurityResource,
  EventSecurityResourceAction,
  EventTemplate,
  EventTemplateResourceAssignment,
  PersonResource,
  Resource,
  ResourceAssignmentStatus,
  ResourceAvailabilityStatus,
  ResourceTag,
  ResourceType,
  TypeformBookingRequirement,
} from '@greco/booking-events';
import type { BookDto, CreateEventSeriesDto } from '@greco/nestjs-booking-events';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { userDefaultTimezone } from '@greco/timezone';
import moment from 'moment';
import { RRule } from 'rrule';
import { Subject } from 'rxjs';
import {
  EventFromTemplateDetails,
  EventFromTemplateInputComponent,
  EventTemplateDetails,
  SeriesSchedule,
} from '../../components';
import { BookingService, CourseService, EventService } from '../../services';
import { BusyResourcesDialog } from '../busy-resources/busy-resources.dialog';
import { SpecifyConfirmationsDialog } from '../specify-confirmations/specify-confirmations.dialog';

@Component({
  templateUrl: './create-event-from-template.dialog.html',
  styleUrls: ['./create-event-from-template.dialog.scss'],
})
export class CreateEventFromTemplateDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      startDate: Date | null;
      resource: Resource;
      forSeries: boolean;
      calendarId: string;
      communityId: string;
      resourceTag: ResourceTag;
      eventTemplate: EventTemplate;
    },
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private courseSvc: CourseService,
    private formBuilder: FormBuilder,
    private bookingSvc: BookingService,
    private comSecSvc: CommunitySecurityService,
    private dialogRef: MatDialogRef<CreateEventFromTemplateDialog>
  ) {}

  createCalled = false;
  createAttendeeCalled = false;

  availability: { [key: string]: ResourceAvailabilityStatus } = {};
  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  canDoubleBook = false;
  canCreateBookings = false;
  canCreateCustomEvents = false;

  @ViewChild('details') private details?: ElementRef<EventFromTemplateInputComponent>;

  formGroup = this.formBuilder.group({
    details: [
      {
        resources: this.data.resource ? [this.data.resource] : [],
        private: !!this.data.resource,
      },
    ],
    schedule: [null],
  });

  dialogData: DialogData = {
    title: 'Create New ' + (this.data.forSeries ? 'Series' : 'Event'),
    subtitle: 'Provide the details for the new ' + (this.data.forSeries ? 'series' : 'event'),
    showCloseButton: false,
  };

  resetDetailsValue: EventTemplateDetails = {
    color: '',
    title: '',
    titleLocked: false,
    imageUrl: null,
    imageUrlLocked: false,
    colorLocked: false,
    description: '',
    descriptionLocked: false,
    tags: [],
    tagsLocked: false,
    resourceAssignments: [],
    autoAssign: false,
    resourcesLocked: false,
    enableUserSpotBooking: false,
    enableUserSpotBookingLocked: false,
    duration: null,
    durationLocked: false,
    checkInWindow: null,
    checkInWindowLocked: false,
    maxCapacity: null,
    maxCapacityLocked: false,
    private: true,
    privateLocked: false,
    typeform: [],
    typeformLocked: false,
    equipmentTitle: '',
    equipmentOptions: [],
    equipmentLocked: false,
  };

  private _onDestroy$ = new Subject<void>();

  cancel() {
    this.dialogRef.close();
  }

  async create() {
    try {
      if (this.createCalled) return;
      this.createCalled = true;
      const { events, preBookings } = await this._createEventFromTemplate();

      if (events?.length) {
        await new Promise(res => setTimeout(res, 500));

        let message = `New ${this.data.forSeries ? 'series' : 'event'} created!`;
        if (preBookings.length) message += ` ${preBookings.length} bookings are being created.`;
        this.snacks.open(message, 'Ok', { duration: 6000, panelClass: 'mat-primary' });

        if (preBookings.length) {
          // 1. Get all previews and alert of any errors.
          const dtos = preBookings.reduce(
            (acc, bkg) => [
              ...acc,
              ...events.reduce((acc, { id: eventId }, index) => {
                const shouldBePending = index >= events.length - bkg.requiredPendingBookings;
                if (!shouldBePending || bkg.bookPending) {
                  acc.push({
                    eventId,

                    skipBoosters: true,
                    applyBalance: true,

                    completed: !shouldBePending,
                    allowPendingBookings: bkg.bookPending,

                    userId: bkg.user.id,
                    bookedById: bkg.user.id,

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    bookingOptionId: bkg.bookingOption!.id,
                    paymentMethodId: bkg.paymentMethod?.id,
                  });
                }

                return acc;
              }, <BookDto[]>[]),
            ],
            <BookDto[]>[]
          );
          // console.log({ dtos });

          const previews = await this.bookingSvc.previewMultiple(dtos);
          // console.log({ previews });

          // 2. Create all bookings
          const _bookings = await this.bookingSvc.confirmMultiple(
            previews.map(preview => ({
              booking: preview.dto,
              bookingHash: preview.hash,
              purchaseHash: preview.purchase?.hash,
            }))
          );
          // console.log({ bookings });
        }

        this.dialogRef.close({ event: events[0], action: 'create' });
      }
    } catch (err: any) {
      console.error(err);
      if (!(err instanceof HttpErrorResponse)) {
        this.snacks.open('Error. ' + err.message ? err.message : err, 'Ok', { panelClass: 'mat-warn' });
      }
      this.dialogRef.close();
    }
  }

  async _createEventFromTemplate(): Promise<{
    events: (EventDetailsModel & { id: string })[];
    preBookings: EventFromTemplateDetails['preBookings'];
  }> {
    const details: EventFromTemplateDetails = this.formGroup.value.details;
    const schedule: SeriesSchedule = this.formGroup.value.schedule;

    // Check if resource is on the curent template
    const resourceOnTemplate = details.resourceAssignments?.find(
      assignment => assignment.resourceId === this.data.resource?.id
    );

    // If the resource isn't on the template, add it (in place of an empty assignment)
    if (this.data.resource && this.data.resourceTag && !resourceOnTemplate) {
      const newAssignment: EventTemplateResourceAssignment = {
        id: '',
        resourceId: this.data.resource.id,
        resourceTagId: this.data.resourceTag.id,
        eventTemplateId: this.data.eventTemplate.id,
        resourceStatus: ResourceAssignmentStatus.CONFIRMED,
      };

      details.resourceAssignments = (details.resourceAssignments as EventTemplateResourceAssignment[]).concat([
        newAssignment,
      ]);

      const index = details.resourceAssignments.findIndex(
        assignment =>
          assignment.resourceTagId === this.data.resourceTag.id &&
          (!assignment?.resourceId || assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION)
      );

      if (index !== -1) details.resourceAssignments.splice(index, 1);
    }

    const resourceTagIds = details.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment.resourceTag && assignment.resourceTagId) {
        if (!acc.includes(assignment?.resourceTagId)) {
          acc.push(assignment.resourceTagId);
        }
      }
      return acc;
    }, [] as string[]);

    let resourceAssignments = (details.resourceAssignments || []).map(
      a => <EventResourceAssignment>{ ...a, eventId: '' }
    );
    const busyResources: EventResourceAssignment[] = [];
    const newConfirmedResources: EventResourceAssignment[] = [];

    if (details.roomAssignment) {
      resourceAssignments = resourceAssignments?.filter(assignment => assignment?.resource?.type !== ResourceType.ROOM);
      resourceAssignments = [...(resourceAssignments || []), { ...details.roomAssignment, eventId: '' }];
    }

    if (details.zoomAssignment) {
      resourceAssignments = resourceAssignments?.filter(assignment => assignment?.resource?.type !== ResourceType.ZOOM);
      resourceAssignments = [...(resourceAssignments || []), { ...details.zoomAssignment, eventId: '' }];
    }

    resourceAssignments.forEach(assignment => {
      // Check if resource is busy
      if (assignment.resource && this.availability[assignment.resource.id] === ResourceAvailabilityStatus.BUSY) {
        busyResources.push(assignment);
      }

      // Check if resource is newly added
      if (
        assignment.resource &&
        assignment.resource.type === ResourceType.PERSON &&
        assignment.resourceStatus === ResourceAssignmentStatus.CONFIRMED
      ) {
        newConfirmedResources.push(assignment);
      }
    });

    let confirmationResult: string[] = [];
    if (busyResources.length) {
      const result = await toPromise(
        this.matDialog
          .open(BusyResourcesDialog, {
            data: {
              resourceAssignments: [...busyResources],
              canDoubleBook$: this.canDoubleBook,
            },
          })
          .afterClosed()
      );
      if (!result?.continue) {
        this.createCalled = false;
        this.createAttendeeCalled = false;
        return { events: [], preBookings: [] };
      }

      busyResources.forEach(item => {
        if ((item.resource as PersonResource).user?.email) {
          confirmationResult.push((item.resource as PersonResource).user?.email as string);
        }
      });

      if (newConfirmedResources.length && result?.continue) {
        const result = await toPromise(
          this.matDialog
            .open(SpecifyConfirmationsDialog, {
              data: {
                communityId: this.data.communityId,
                assignments: [...newConfirmedResources],
              },
            })
            .afterClosed()
        );

        if (!result?.emails.length) {
          this.createCalled = false;
          this.createAttendeeCalled = false;
          return { events: [], preBookings: [] };
        }

        confirmationResult = result?.emails;
      }
    }

    if (newConfirmedResources.length && !busyResources.length) {
      const result = await toPromise(
        this.matDialog
          .open(SpecifyConfirmationsDialog, {
            data: {
              communityId: this.data.communityId,
              assignments: newConfirmedResources,
            },
          })
          .afterClosed()
      );

      if (!result?.emails.length) {
        this.createCalled = false;
        this.createAttendeeCalled = false;
        return { events: [], preBookings: [] };
      }

      confirmationResult = result?.emails;
    }

    if (details.resourceAssignments?.length) {
      details.resourceAssignments.forEach(assignment => {
        delete assignment.resourceTag;
      });
    }

    const data = {
      startDate: details.startDate || new Date(),
      timezone: details.timezone || userDefaultTimezone(),

      resourceTagIds,
      resourceAssignments,
      autoAssign: details.autoAssign || false,
      enableUserSpotBooking: details.enableUserSpotBooking || false,
      checkInWindow: details.checkInWindow || 0,
      maxCapacity: details.maxCapacity || 10,
      description: details.description || '',
      private: details.private || false,
      communityId: this.data.communityId,
      calendarId: this.data.calendarId,
      duration: details.duration || 45,
      color: details.color || '',
      title: details.title || '',
      imageUrl: details.imageUrl || '',
      zoomMeetingId: details.zoomMeetingId || '',
      tags: (details.tags || []).map(tag => ({
        id: tag.id,
        communityId: this.data.communityId,
        calendarId: this.data.calendarId,
        label: tag.label,
      })),
      typeform: (details.typeform || []).map(({ id, reusable, required }) => ({
        formId: id,
        reusable,
        required,
      })),
      equipmentTitle: details.equipmentTitle || '',
      equipmentOptions: details.equipmentOptions || [],
    };
    const events: (EventDetailsModel & { id: string })[] = [];

    if (!this.data.forSeries) {
      const body = {
        createEventDto: confirmationResult ? { ...data, confirmationEmails: confirmationResult } : data,
        eventTemplateId: this.data.eventTemplate.id,
        eventTemplate: this.data.eventTemplate,
        customAccess: this.canCreateCustomEvents,
      };

      const event = await this.eventSvc.createEventFromTemplate(body);
      events.push(event);

      if ((details as any)?.extraDates) {
        const dates = (details as any)?.extraDates as Date[];

        await Promise.all(
          dates.map(async date => {
            if (date) {
              const event = await this.eventSvc.createEventFromTemplate({
                createEventDto: confirmationResult
                  ? { ...data, confirmationEmails: confirmationResult, startDate: date }
                  : { ...data, startDate: date },
                eventTemplateId: this.data.eventTemplate.id,
                eventTemplate: this.data.eventTemplate,
                customAccess: this.canCreateCustomEvents,
              });

              events.push(event);
            }
          })
        );
      }
    } else {
      const startDate = this.formGroup.value.schedule.startDate;
      const endDate = this.formGroup.value.schedule.endDate;
      if (endDate && new Date(startDate).getTime() >= new Date(endDate).getTime()) {
        this.snacks.open('Cannot create a series with an end date before the start date!', 'Ok', {
          panelClass: 'mat-warn',
        });
      }

      const recurrence = this.computeRecurrence(startDate);

      const seriesData: CreateEventSeriesDto = {
        ...data,
        startDate,
        endDate: endDate ? moment(endDate).endOf('day').toDate() : endDate,
        availableAsCourse: schedule.availableAsCourse || false,
        availableAsInstances: schedule.availableAsInstances || false,
        recurrence: recurrence || [],
      };

      const seriesBody = {
        createEventSeriesDto: seriesData,
        eventTemplate: this.data.eventTemplate,
        customAccess: this.canCreateCustomEvents,
        eventTemplateId: this.data.eventTemplate.id,
      };

      const series = await this.eventSvc.createSeriesFromTemplate(seriesBody);
      events.push(series);

      if (schedule.courseImage) {
        const formData = new FormData();
        formData.append('file', schedule.courseImage[0]);

        await this.courseSvc.uploadCourseImage(series.id, formData);
      }
    }

    return { events, preBookings: details.preBookings };
  }

  computeRecurrence(startDate: Date, days?: any) {
    const daysValue = days ? days : this.formGroup.get('schedule')?.value;
    return this.weekDays.reduce(
      (acc, day) => [
        ...acc,
        ...(daysValue[day]?.map((date: Date) =>
          new RRule({
            dtstart: startDate,
            freq: RRule.WEEKLY,
            byweekday: {
              monday: RRule.MO,
              tuesday: RRule.TU,
              wednesday: RRule.WE,
              thursday: RRule.TH,
              friday: RRule.FR,
              saturday: RRule.SA,
              sunday: RRule.SU,
            }[day],
            byhour: [moment(date).hour()],
            byminute: [moment(date).minute()],
            bysecond: [0],
          }).toString()
        ) || ([] as string[])),
      ],
      [] as string[]
    );
  }

  refreshAvailability(schedule: SeriesSchedule) {
    const { startDate, timezone, endDate, resources, availableAsCourse, availableAsInstances, courseImage, ...days } =
      schedule;

    if (days && startDate) {
      const computedRecurrence = this.computeRecurrence(startDate, days);
      if (computedRecurrence) (this.details as any)?.refreshAvailability(computedRecurrence, startDate);
    }
  }

  resetDetails() {
    this.resetDetailsValue = {
      color: this.data.eventTemplate?.color || null,
      title: this.data.eventTemplate?.title || null,
      titleLocked: this.data.eventTemplate?.titleLocked,
      imageUrl: this.data.eventTemplate?.imageUrl || null,
      imageUrlLocked: this.data.eventTemplate?.imageUrlLocked,
      colorLocked: this.data.eventTemplate?.colorLocked,
      description: this.data.eventTemplate?.description || null,
      descriptionLocked: this.data.eventTemplate?.descriptionLocked,
      tags: this.data.eventTemplate?.tags || null,
      tagsLocked: this.data.eventTemplate?.tagsLocked,
      resourceTags: this.data.eventTemplate?.resourceTags || null,
      resourceAssignments: this.data.eventTemplate?.resourceAssignments || null,
      autoAssign: this.data.eventTemplate?.autoAssign || false,
      resourcesLocked: this.data.eventTemplate?.resourcesLocked,
      enableUserSpotBooking: this.data.eventTemplate?.enableUserSpotBooking || false,
      enableUserSpotBookingLocked: this.data.eventTemplate?.enableUserSpotBookingLocked || false,
      duration: this.data.eventTemplate?.duration || null,
      durationLocked: this.data.eventTemplate?.durationLocked,
      checkInWindow: this.data.eventTemplate?.checkInWindow ?? null,
      checkInWindowLocked: this.data.eventTemplate?.checkInWindowLocked,
      maxCapacity: this.data.eventTemplate?.maxCapacity,
      maxCapacityLocked: this.data.eventTemplate?.maxCapacityLocked,
      private: this.data.eventTemplate?.private || false,
      privateLocked: this.data.eventTemplate?.privateLocked,
      zoomAssignment:
        this.data.eventTemplate?.resourceAssignments?.find(
          assignment => assignment.resource?.type === ResourceType.ZOOM
        ) || null,
      zoomMeetingId: this.data.eventTemplate?.zoomMeetingId,
      typeform: (
        (this.data.eventTemplate?.requirements || []).filter(
          req => req.type === 'typeform'
        ) as TypeformBookingRequirement[]
      ).map(({ form, reusable, required }) => ({ ...form, reusable, required })),
      typeformLocked: this.data.eventTemplate?.requirementsLocked,

      equipmentTitle: this.data.eventTemplate?.equipmentTitle,
      equipmentOptions: this.data.eventTemplate?.equipmentOptions,
      equipmentLocked: this.data.eventTemplate?.equipmentLocked,
    };

    this.formGroup.reset({ details: this.resetDetailsValue });
    this.formGroup.markAsPristine();

    if (this.data.eventTemplate) this.formGroup.enable();
    else this.formGroup.disable();
  }

  async ngOnInit() {
    this.canCreateBookings = await this.comSecSvc.hasAccess(
      this.data.communityId,
      EventBookingSecurityResource.key,
      EventBookingSecurityResourceAction.CREATE
    );

    this.canDoubleBook = await this.comSecSvc.hasAccess(
      this.data.communityId,
      EventResourceSecurityResource.key,
      EventResourceSecurityResourceAction.DOUBLE_BOOK
    );

    this.canCreateCustomEvents = await this.comSecSvc.hasAccess(
      this.data.communityId,
      EventSecurityResource.key,
      EventSecurityResourceAction.CREATE_CUSTOM
    );

    this.resetDetails();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
