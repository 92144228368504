<ng-container *grecoLet="exports$ | async as dataExports">
  <div class="dialog-container" *grecoLet="selectedExport$ | async as selectedExport">
    <div class="header">
      <h2 *ngIf="!selectedExport" class="title">Recent Exports</h2>

      <div *ngIf="selectedExport" style="display: flex; align-items: center">
        <button mat-icon-button (click)="closeDataExport()">
          <mat-icon style="font-size: 16px; line-height: 16px; height: 16px; width: 16px">arrow_back_ios</mat-icon>
        </button>
        <h2 class="title">Data Export</h2>
      </div>
    </div>

    <div *ngIf="!selectedExport" class="content">
      <div
        *ngIf="!dataExports?.length"
        style="display: flex; align-items: center; justify-content: center; padding: 16px"
      >
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>

      <div *ngFor="let dataExport of dataExports" class="data-export" (click)="openDataExport(dataExport)">
        <div>
          <p style="font-weight: bold">{{dataExport.title}}</p>
          <p>{{dataExport.created | date: 'mediumDate'}}</p>
        </div>

        <div
          *ngIf="!dataExport.completed"
          style="width: 40px; height: 40px; display: flex; align-items: center; justify-content: center"
        >
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>

        <a
          *ngIf="dataExport.completed"
          mat-icon-button
          target="_blank"
          [href]="dataExport.downloadUrl"
          (click)="$event.stopImmediatePropagation()"
        >
          <mat-icon>download</mat-icon>
        </a>
      </div>
    </div>

    <div *ngIf="selectedExport" class="content">
      <div class="export-description">
        <p
          [innerHTML]="(selectedExport.failed ? 'Export failed, please try again.' : (selectedExport?.description || 'Preparing your export')) | safe: 'html'"
        ></p>
        <mat-progress-bar
          *ngIf="!selectedExport.failed"
          style="border-radius: 4px"
          mode="determinate"
          [value]="(selectedExport?.completion || 0) * 100"
        ></mat-progress-bar>
      </div>
    </div>

    <div class="footer buttons">
      <button mat-stroked-button (click)="close()">Close</button>

      <button *ngIf="!selectedExport && dataExports?.length" mat-flat-button color="primary" (click)="startExport()">
        Start Export
      </button>

      <a
        *ngIf="selectedExport?.completed"
        mat-flat-button
        color="primary"
        target="_blank"
        [href]="selectedExport?.downloadUrl"
      >
        Download
      </a>

      <button
        *ngIf="selectedExport && !selectedExport.completed"
        #thisisthebutton
        mat-flat-button
        color="primary"
        [disabled]="selectedExport.sendEmail"
        (click)="sendByEmail(selectedExport); thisisthebutton.disabled = true"
      >
        <mat-icon>{{thisisthebutton.disabled ? 'done' : 'mail'}}</mat-icon>
        <span>Send by Email</span>
      </button>

      <!-- <button mat-stroked-button>
      <mat-icon>{{thisisthebutton.disabled ? 'done' : 'mail'}}</mat-icon>
      <span>Email {{user.email}}</span>
    </button> -->
    </div>
  </div>
</ng-container>
