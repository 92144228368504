import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrivoCommunitySite, BrivoSecurityActions, BrivoSecurityResource } from '@greco/domain-brivo';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BrivoService } from '../../services';

@Component({
  selector: 'greco-brivo-community-settings',
  templateUrl: './community-settings.component.html',
  styleUrls: ['./community-settings.component.scss'],
})
export class BrivoCommunitySettingsComponent {
  constructor(
    private snacks: MatSnackBar,
    private brivoSvc: BrivoService,
    private formBuilder: FormBuilder,
    private communitySecuritySvc: CommunitySecurityService
  ) {}

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId!: string;

  refresh$ = new BehaviorSubject<null>(null);

  loading = true;

  form = this.formBuilder.group({
    site: [null as string | null],
  });

  canLinkSite$ = this._communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.communitySecuritySvc.hasAccess(
            communityId,
            BrivoSecurityResource.key,
            BrivoSecurityActions.LINK_SITE
          )
        : false
    ),
    shareReplay(1)
  );

  readonly communitySite$: Observable<BrivoCommunitySite | null> = combineLatest([
    this._communityId$,
    this.refresh$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([communityId]) => (communityId ? await this.brivoSvc.getCommunitySite(communityId) : null)),
    tap(communitySite => this.form.reset({ site: communitySite?.siteExternalId || null })),
    tap(() => (this.loading = false))
  );

  readonly sites$ = combineLatest([this._communityId$, this.refresh$]).pipe(
    switchMap(async () => await this.brivoSvc.listBrivoSites(1, 100)),
    map(sites => sites.items?.filter(site => !site.communitySite))
  );

  // canManageCalendly$ = this._communityId$.pipe(
  //   switchMap(async communityId =>
  //     communityId
  //       ? await this.comSecSvc.hasAccess(
  //           communityId,
  //           CalendlySecurityResource.key,
  //           CalendlySecurityResourceAction.MANAGE
  //         )
  //       : false
  //   )
  // );

  async selectSite(
    siteId: string,
    sites: { id: string; siteName: string }[],
    communitySite?: BrivoCommunitySite | null
  ) {
    if (!siteId) return;
    if (siteId === communitySite?.siteExternalId) return;

    const site = sites.find(site => site.id === siteId);

    if (!site) return;

    try {
      await this.brivoSvc.linkSiteToCommunity(this.communityId, siteId);

      this.snacks.open(`Successfully linked site: ${site.siteName} to community`, 'Ok!', {
        duration: 5000,
        panelClass: 'mat-primary',
      });
    } catch (err) {
      this.snacks.open(`Failed to link site: ${site.siteName}`, 'Ok!', { duration: 5000, panelClass: 'mat-warn' });
    }

    this.refresh$.next(null);
  }
}
