import { User } from '@greco/identity-users';

export interface AlertLog {
  id: string;

  userId: string;
  user?: User;

  created: Date;
  modified: Date;
  resolved?: Date | null;

  type: string;
  priority: AlertLogPriority;

  title: string;
  description: string;

  correlationId?: string;
  correlationLabel?: string;
}

export enum AlertLogPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  INFO = 'INFO',
}
