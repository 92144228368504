import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Subscription, SubscriptionFreezePeriod } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class SubscriptionFreezeService {
  constructor(private http: HttpClient) {}

  // @Get()
  paginateFreezes(subscriptionId: string, query: RequestQueryBuilder, pagination?: PaginatedQueryParams) {
    return toPromise(
      this.http.get<PaginatedDto<SubscriptionFreezePeriod>>(this.path(), {
        params: {
          subscriptionId,
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('all')
  getAllFreezes(subscriptionId: string) {
    return toPromise(this.http.get<SubscriptionFreezePeriod[]>(this.path('all'), { params: { subscriptionId } }));
  }

  // @Get(':freezeId')
  getFreeze(freezeId: string) {
    return toPromise(this.http.get<SubscriptionFreezePeriod>(this.path(freezeId)));
  }

  // @Post(':subscriptionId')
  async createFreeze(
    subscriptionId: string,
    startDate: Date,
    endDate: Date | null,
    reason?: string,
    proration?: boolean
  ) {
    return toPromise(
      this.http.post<SubscriptionFreezePeriod>(this.path(subscriptionId), { startDate, endDate, reason, proration })
    );
  }

  // @Patch(':freezeId')
  async updateFreeze(freezeId: string, startDate?: Date, endDate?: Date | null, reason?: string, proration?: boolean) {
    return toPromise(
      this.http.patch<SubscriptionFreezePeriod>(this.path(freezeId), { startDate, endDate, reason, proration })
    );
  }

  // @Patch('unfreeze/:subscriptionId')
  async unfreeze(subscriptionId: string, freezeId?: string) {
    return toPromise(this.http.patch<SubscriptionFreezePeriod>(this.path('unfreeze/' + subscriptionId), { freezeId }));
  }

  // @Delete(':freezeId')
  async deleteFreeze(freezeId: string) {
    return toPromise(this.http.delete<SubscriptionFreezePeriod>(this.path(freezeId)));
  }

  private path(suffix?: string) {
    return '/api/subscription-freezes' + (suffix && !suffix.startsWith('/') ? '/' + suffix : suffix || '');
  }

  async getFrozenSubscriptions(userId: string) {
    return toPromise(this.http.get<Subscription[]>(this.path(`/frozen-subscriptions/${userId}`)));
  }
}
