<!-- [style.border-color]="isSelected ? 'var(--primary-color)' : undefined" -->

<!-- [style.background-color]="isSelected ? 'var(--primary-color)' : '#f5f5f5'" -->
<!-- [style.border-color]="isSelected ? 'var(--primary-color)' : '#f5f5f5'" -->
<greco-box
  *grecoLet="isSelected$ | async as isSelected"
  style="
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;
    overflow: hidden;
    position: relative;
    border: 0;
  "
  (click)="addToCart()"
>
  <div
    *ngIf="isSelected"
    style="
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: var(--primary-color);
      opacity: 0.5;
      z-index: -1;
    "
  ></div>
  <div
    *ngIf="!isSelected"
    style="width: 100%; height: 100%; position: absolute; background-color: #f5f5f5; z-index: -1"
  ></div>

  <!-- && inventoryAddon.displayStockNumber -->
  <div
    *ngIf="inventoryAddon && inventoryAddon.enabled"
    style="
      border-radius: 100%;
      position: absolute;
      top: 4px;
      right: 4px;
      background-color: var(--primary-color);
      color: white;
      padding: 2px;
      min-height: 16px;
      min-width: 16px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    [style.background-color]="
      (variantInventory?.availableInventoryCount || 0) > 0 ? 'var(--primary-color)' : 'var(--warn-color)'
    "
  >
    <small style="padding: 0; margin: 0">{{ variantInventory?.availableInventoryCount || 0 }}</small>
  </div>
  <img
    [src]="$any(variant.image)?.imageURL || 'assets/lf3/icon_square_pad.png'"
    style="height: 96px; width: 96px; aspect-ratio: 1/1; object-fit: cover"
  />
  <div style="display: flex; flex-direction: column; height: 96px; width: 100%">
    <p style="text-overflow: ellipsis; flex: 1; padding: 8px; overflow: hidden">
      {{ variant.title || (variant.variantOpts || [] | pluck : 'value')?.join(', ') }}
    </p>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        padding-top: 0px;
      "
    >
      <p style="padding: 0; margin: 0">
        <strong>{{ variant.price / 100 | currency }}</strong>
      </p>
      <!-- <ng-container *ngIf="!isSelected"> -->
      <mat-icon
        color="primary"
        *ngIf="
          !(
            inventoryAddon &&
            inventoryAddon.enabled &&
            (!variantInventory || (variantInventory.availableInventoryCount || 0) < 1)
          );
          else outOfStock
        "
      >
        add_box
      </mat-icon>
      <!-- </ng-container> -->
      <ng-template #outOfStock>
        <mat-chip-list>
          <mat-chip color="warn">{{ inventoryAddon?.outOfStockMessage || 'Out of Stock' }}</mat-chip>
        </mat-chip-list>
      </ng-template>
    </div>
  </div>
</greco-box>
