import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { toPromise } from '@greco-fit/util';
import {
  AccountLinkPrivilege,
  AccountLinkingSecurityResource,
  AccountLinkingSecurityResourceAction,
} from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { UserService as AuthUserService } from '@greco/ngx-identity-auth';
import { CommunityService } from '@greco/ngx-identity-communities';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ContactService } from '@greco/ngx-identity-contacts';
import { UserService } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountLinkingService } from '../../services';
import { UserAddGuestDialog } from '../user-add-guest/user-add-guest.dialog';

@Component({
  templateUrl: './link-account.dialog.html',
  styleUrls: ['./link-account.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LinkAccountDialog implements OnInit, OnDestroy {
  constructor(
    private matDialog: MatDialog,
    private userSvc: UserService,
    private contactSvc: ContactService,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private authSvc: AuthUserService,
    private communitySvc: CommunityService,
    private linkingSvc: AccountLinkingService,
    private comSecuritySvc: CommunitySecurityService,

    @Inject(MAT_DIALOG_DATA) private data: { accessor: User },
    private dialogRef: MatDialogRef<LinkAccountDialog>
  ) {
    this.user = data.accessor;
  }
  signedInUser$ = this.authSvc.user$;
  private _onDestroy$ = new Subject<void>();

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  user!: User;

  inviteFormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    active: [false],
    usePerks: [true],
    twoWay: [false],
  });

  childFormGroup = this.formBuilder.group({
    displayName: ['', Validators.required],
  });

  linkChildFormGroup = this.formBuilder.group({
    child: [null, Validators.required],
  });

  inviteInvalid$ = new BehaviorSubject(true);
  linkChildInvalid$ = new BehaviorSubject(true);
  childInvalid$ = new BehaviorSubject(true);
  tab$ = new BehaviorSubject<number>(0);

  canCreateChild$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async ([user, signedInUser]) => {
      const access = await this.comSecuritySvc.communitiesWithAccess(
        AccountLinkingSecurityResource.key,
        AccountLinkingSecurityResourceAction.CREATE_CHILD
      );
      return {
        hasAccess: (user && user.id === signedInUser?.id) || (access?.length > 0 ? true : false),
        admin: access?.length > 0 ? true : false,
      };
    })
  );

  canRequestLink$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async ([user, signedInUser]) => {
      if (user && user.id === signedInUser?.id) return true;
      const access = await this.comSecuritySvc.communitiesWithAccess(
        AccountLinkingSecurityResource.key,
        AccountLinkingSecurityResourceAction.REQUEST
      );
      if (access?.length) return true;
      else return false;
    })
  );

  canCreateLink$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async () => {
      const access = await this.comSecuritySvc.communitiesWithAccess(
        AccountLinkingSecurityResource.key,
        AccountLinkingSecurityResourceAction.CREATE_LINK
      );
      if (access?.length) return true;
      else return false;
    })
  );

  permissions$ = combineLatest([this.canRequestLink$, this.canCreateLink$, this.canCreateChild$]).pipe(
    map(([canRequestLink, canCreateLink, canCreateChild]) => {
      return { canRequestLink, canCreateLink, canCreateChild };
    })
  );

  dialogButtons$ = combineLatest([
    this.tab$,
    this.inviteInvalid$,
    this.childInvalid$,
    this.linkChildInvalid$,
    this.permissions$,
  ]).pipe(
    switchMap(async ([tab, inviteInvalid, childInvalid, linkChildInvalid, permissions]) => {
      const buttons: any[] = [{ label: 'Cancel', role: 'cancel' }];

      if (tab === 0) {
        if (permissions.canRequestLink) {
          buttons.push({
            label: 'Request to Link',
            role: 'requestlink',
            disabled: inviteInvalid,
            resultFn: async () => await this.requestLink(),
          });
        }
        if (permissions.canCreateLink) {
          buttons.push({
            label: 'Setup Link',
            role: 'create',
            disabled: inviteInvalid,
            resultFn: async () => {
              await this.createLink();
            },
          });
        }

        return buttons;
      } else {
        if (permissions.canCreateChild.hasAccess) {
          if (!permissions.canCreateChild.admin) {
            buttons.push({
              label: 'Create',
              role: 'create',
              disabled: childInvalid,
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              resultFn: async () => await this.addChild(false),
            });
          } else {
            buttons.push({
              label: 'Setup Link',
              role: 'create',
              disabled: !linkChildInvalid ? false : !childInvalid ? false : true,
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              resultFn: async () => await this.addChild(true),
            });
          }
        }

        return buttons;
      }
    })
  );

  async addChild(admin: boolean) {
    if (!admin) {
      if (this.childFormGroup.invalid) return;

      const displayName = this.childFormGroup.value.displayName;
      if (!displayName) return;

      try {
        const child = await this.linkingSvc.createChildAccount(this.user.id, { displayName });
        this.dialogRef.close(child);
      } catch (e: any) {
        if (e.error && e.error.message.includes('Account child already exists')) {
          this.snackbar.open('Account with this child name already exists.', 'Ok', {
            duration: 3000,
            panelClass: 'mat-warn',
          });
        }
      }
    } else {
      const selectedChild = this.linkChildFormGroup.value.child;
      const displayName = this.childFormGroup.value.displayName;
      if (!displayName && !selectedChild) return;

      if (selectedChild) {
        const link = await this.linkingSvc.linkChildAccount(this.user.id, selectedChild.id);
        this.dialogRef.close(link);
        return;
      }

      if (displayName) {
        try {
          const child = await this.linkingSvc.createChildAccount(this.user.id, { displayName });
          this.dialogRef.close(child);
          return;
        } catch (e: any) {
          if (e.error && e.error.message.includes('Account child already exists')) {
            this.snackbar.open('Account with this child name already exists.', 'Ok', {
              duration: 3000,
              panelClass: 'mat-warn',
            });
          }
        }
      }
    }
  }

  tabChange(event: MatTabChangeEvent) {
    this.tab$.next(event.index);
    // this.inviteFormGroup.reset({ email: '', active: false, userPerks: true, twoWay: false });
    this.childFormGroup.reset();
  }

  async adminConfirm() {
    const active = this.inviteFormGroup.value.active || false;
    if (active) {
      return await this.createLink();
    } else return await this.requestLink();
  }

  async requestLink() {
    const email = this.inviteFormGroup.value.email;
    const user = await this.userSvc.findOneByEmail(email);
    if (!user) {
      this.snackbar.open('No user exists with this email', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });

      const comSubs = await this.contactSvc.getUserContacts(this.user.id);
      if (!comSubs.length) return;

      const dialog = this.matDialog.open(UserAddGuestDialog, {
        data: { user: this.user, communityId: comSubs[0].community.id, email },
      });

      this.dialogRef.close(await toPromise(dialog.afterClosed()));
      return;
    }

    const privileges: AccountLinkPrivilege[] = [];
    if (this.inviteFormGroup.value.usePerks) privileges.push(AccountLinkPrivilege.USE_PERKS);

    const registeredLinks = await this.linkingSvc.getPrivilegeLinksForAccessor(this.user.id);
    const checkExistence = registeredLinks.some(link => link.account?.email === email);
    if (checkExistence) {
      this.snackbar.open('This user has already been linked.', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      return;
    }

    const link = await this.linkingSvc.requestAccess({
      accessorId: this.user.id,
      accountId: user.id,
      privileges,
      twoWay: this.inviteFormGroup.value.twoWay || false,
    });
    this.dialogRef.close(link);
  }

  async createLink() {
    const email = this.inviteFormGroup.value.email;
    const user = await this.userSvc.findOneByEmail(email);
    if (!user) {
      const comSubs = await this.contactSvc.getUserContacts(this.user.id);
      if (!comSubs.length) return;

      const dialog = this.matDialog.open(UserAddGuestDialog, {
        data: { user: this.user, communityId: comSubs[0].community.id, email },
      });

      this.dialogRef.close(await toPromise(dialog.afterClosed()));
    }

    const privileges: AccountLinkPrivilege[] = [];
    if (this.inviteFormGroup.value.usePerks) privileges.push(AccountLinkPrivilege.USE_PERKS);

    try {
      const link = await this.linkingSvc.createLink({
        accessorId: this.user.id,
        accountId: user.id,
        privileges,
        twoWay: this.inviteFormGroup.value.twoWay || false,
      });

      this.dialogRef.close(link);
    } catch (error: any) {
      if (error.error.message.includes('Link already exists')) {
        this.snackbar.open('This account is already linked.', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
      }
    }
  }

  async ngOnInit() {
    this.inviteFormGroup.valueChanges
      .pipe(startWith(this.inviteFormGroup.value), takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.inviteInvalid$.next(this.inviteFormGroup.invalid);
      });

    this.childFormGroup.valueChanges
      .pipe(startWith(this.childFormGroup.value), takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.childInvalid$.next(this.childFormGroup.invalid);
      });

    this.linkChildFormGroup.valueChanges
      .pipe(startWith(this.linkChildFormGroup.value), takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.linkChildInvalid$.next(this.linkChildFormGroup.invalid);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
