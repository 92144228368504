<greco-simple-dialog [data]="dialogData" *ngIf="user$ | async as user">
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 16px">
      <mat-form-field style="flex: 1">
        <mat-label>First Name</mat-label>
        <input matInput required formControlName="firstName" />
      </mat-form-field>

      <mat-form-field style="flex: 1">
        <mat-label>Last Name</mat-label>
        <input matInput required formControlName="lastName" />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput required formControlName="email" />
      <mat-hint *ngIf="duplicateEmail">* A contact with that email already exists!</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput type="tel" mask="(000) 000-0000" formControlName="phoneNumber" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Member Number</mat-label>
      <input matInput type="text" name="numOnly" formControlName="memberNumber" />
    </mat-form-field>

    <div
      style="display: flex; flex-direction: column; align-items: center; gap: 8px"
      [style.display]="(canInviteContact$ | async) ? 'block' : 'none'"
    >
      <mat-checkbox
        formControlName="newUser"
        color="primary"
        (change)="changeSubscriptionOption($event)"
        style="width: 100%"
        >Ensure User (Sends Invitation if necessary)
        <mat-icon
          style="line-height: 18px; width: 18px; height: 18px; font-size: 18px; flex: 1"
          matTooltip="Selecting this option will create a user, generate a password and send the user an invitation to login via email."
          >info</mat-icon
        ></mat-checkbox
      >
      <mat-checkbox
        *ngIf="(canSubscribeMember$ | async) && isNewUserSelected"
        formControlName="subscription"
        color="primary"
        style="flex: 1"
        >Subscribe to Community</mat-checkbox
      >
    </div>
  </form>
</greco-simple-dialog>

<div class="dialog-buttons">
  <button mat-button [disabled]="createCalled" (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="formGroup.invalid || createCalled" (click)="create()">
    <mat-icon *ngIf="createCalled" class="spin">loop</mat-icon> Create
  </button>
</div>
