import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AlertLogsService } from '../../services';

@Component({
  selector: 'greco-user-alert-history-table',
  templateUrl: './user-alert-history-table.component.html',
  styleUrls: ['./user-alert-history-table.component.scss'],
})
export class UserAlertHistoryTableComponent {
  constructor(
    private alertLogsSvc: AlertLogsService,
    private securitySvc: SecurityService,
    private snacks: MatSnackBar
  ) {}

  now = new Date().getTime();

  @PropertyListener('userId') userId$ = new BehaviorSubject<string | null>(null);
  @Input() userId?: string | null;

  pagination?: IPaginationMeta;
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  canResolve$ = this.userId$.pipe(
    switchMap(async userId => {
      if (!userId) return false;

      return await this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.RESOLVE_ALERT_LOG, {}, true);
    })
  );

  userAlertHistory$ = combineLatest([this.userId$, this.page$]).pipe(
    switchMap(async ([userId, page]) =>
      userId
        ? await this.alertLogsSvc.paginate(userId, page?.page, page?.limit)
        : { items: [], meta: { itemCount: 0, totalItems: 0, itemsPerPage: 10, totalPages: 0, currentPage: 1 } }
    ),
    tap(data => (this.pagination = data?.meta))
  );

  async resolveLog(alertLogId: string) {
    try {
      await this.alertLogsSvc.resolveLog(alertLogId);
      this.snacks.open('Alert log resolved!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
      this.page$.next(this.page$.value);
    } catch (err) {
      console.error(err);
      this.snacks.open('Error resolving alert log', 'Ok', { duration: 2000, panelClass: 'mat-warn' });
      this.page$.next(this.page$.value);
    }

    this.now = new Date().getTime();
  }
}
