import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { UpdateUserDto } from '@greco/nestjs-identity-users-util';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable()
export class UserService implements OnDestroy {
  public refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  get authUser$() {
    return this.ngAuth.user;
  }

  user$ = combineLatest([this.authUser$, this.refresh$]).pipe(
    switchMap(([auth]) => (auth?.uid ? this.getUser(auth.uid) : of(null))),
    shareReplay(1)
  );

  private _onDestroy$ = new Subject<void>();

  constructor(
    private ngAuth: AngularFireAuth,
    private ngFire: AngularFirestore,
    private http: HttpClient,
    private snacks: MatSnackBar
  ) {
    this.authUser$.pipe(takeUntil(this._onDestroy$)).subscribe(async user => {
      if (user) await toPromise(this.http.put(`/api/users/${user.uid}`, {}));
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  // Pasted here to avoid circular dependency
  async getUserContacts(userId?: string) {
    userId = userId ?? (await this.getSelf())?.id;
    return userId ? toPromise(this.http.get<Contact[]>(`/api/contacts/user-contacts/${userId}`)) : toPromise(of([]));
  }

  getUserId(user?: string | null): Observable<string | undefined> {
    return user ? of(user) : this.ngAuth.user.pipe(map(authUser => authUser?.uid));
  }

  getUser(id: string): Observable<User | null> {
    return this.http.get<User>('/api/users/' + id);
  }

  async getSelf(): Promise<User | null> {
    return toPromise(this.user$);
  }

  async getSelfLatest(): Promise<User | null> {
    return toPromise(this.ngAuth.user.pipe(switchMap(auth => (auth?.uid ? this.getUser(auth.uid) : of(null)))));
  }

  async signIn(
    ngAuth?: firebase.auth.AuthCredential | firebase.auth.AuthProvider
  ): Promise<firebase.auth.UserCredential>;
  async signIn(email: string, password: string): Promise<firebase.auth.UserCredential>;
  async signIn(
    emailOrProvider?: string | firebase.auth.AuthProvider | firebase.auth.AuthCredential,
    passwordOrIdToken?: string
  ): Promise<firebase.auth.UserCredential> {
    // Anonymous sign-in
    if (!emailOrProvider && !passwordOrIdToken) return await this.ngAuth.signInAnonymously();

    const currentUser = await this.ngAuth.currentUser;

    if (typeof emailOrProvider === 'string') {
      // Email & password sign-in
      if (!passwordOrIdToken) throw new Error('Unable to sign-in. Missing password.');
      else if (currentUser?.isAnonymous) {
        return await currentUser.linkWithCredential(
          firebase.auth.EmailAuthProvider.credential(emailOrProvider, passwordOrIdToken)
        );
      } else {
        return await this.ngAuth.signInWithEmailAndPassword(emailOrProvider, passwordOrIdToken);
      }
    } else if (emailOrProvider) {
      // Credentials sign-in
      if (currentUser?.isAnonymous) {
        const credential =
          'signInMethod' in emailOrProvider
            ? emailOrProvider
            : (await this.ngAuth.signInWithPopup(emailOrProvider)).credential;
        if (!credential) throw new Error('Unable to sign-in. Invalid credentials.');
        return await currentUser.linkWithCredential(credential);
      } else if ('signInMethod' in emailOrProvider) {
        return await this.ngAuth.signInWithCredential(emailOrProvider);
      } else {
        try {
          return await this.ngAuth.signInWithPopup(emailOrProvider);
        } catch (err: any) {
          console.error(err);
          if ((err as any)?.code === 'auth/popup-closed-by-user') {
            this.snacks.open('Authentication cancelled by user', 'Ok', {
              duration: 2500,
            });
          }
        }
      }
    }

    throw new Error('Invalid sign-in request');
  }

  async register(email: string, password: string, info?: { displayName?: string; phoneNumber?: string }) {
    const userCreds = await ((
      await this.ngAuth.currentUser
    )?.isAnonymous
      ? (await this.ngAuth.currentUser)?.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email, password))
      : this.ngAuth.createUserWithEmailAndPassword(email, password));
    if (info?.displayName && userCreds?.user?.uid) {
      await userCreds?.user.updateProfile({ displayName: info?.displayName });
      await Promise.all([
        // TODO(adaoust): Eager attempt to create SQL via server
        this.update(userCreds.user.uid, {
          displayName: info?.displayName || undefined,
          phoneNumber: info?.phoneNumber || undefined,
        }),
        this.ngFire.doc(`users/${userCreds?.user?.uid}`).set(
          {
            displayName: info?.displayName || undefined,
            phoneNumber: info?.phoneNumber || undefined,
          },
          { merge: true }
        ),
      ]);
      try {
        window.dataLayer = window.dataLayer || [];
        if (window.dataLayer.push) {
          window.dataLayer.push({
            event: 'accountCreated',
            accountEmail: email || '',
            accountName: info?.displayName || '',
            accountPhone: info?.phoneNumber || '',
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    // await userCreds?.user?.sendEmailVerification();
    return userCreds;
  }

  signOut() {
    return this.ngAuth.signOut();
  }

  isSignedIn$() {
    return this.ngAuth.authState.pipe(map(state => !!state));
  }

  resetPassword(email: string) {
    return this.ngAuth.sendPasswordResetEmail(email, { url: location.origin });
  }

  async update(userId: string, data: UpdateUserDto): Promise<User> {
    return toPromise(this.http.put<User>(`/api/users/${userId}`, data).pipe(tap(() => this.refresh$.next())));
  }

  async uploadUserPicture(userId: string, formData: FormData): Promise<User> {
    return toPromise(this.http.post<User>(`/api/users/${userId}/picture`, formData));
  }

  // @Post(':userId/verify_email')
  async verifyEmail(userId: string): Promise<User> {
    return await toPromise(this.http.post<User>(`/api/users/${userId}/verify_email`, {}));
  }

  // @Get(':userId/password_reset')
  async getPasswordResetLink(userId: string): Promise<{ url: string }> {
    return await toPromise(this.http.get<{ url: string }>(`/api/users/${userId}/password_reset`, {}));
  }

  async sendResetPasswordEmail(email: string): Promise<void> {
    return await this.ngAuth.sendPasswordResetEmail(email, {
      url: location.origin,
    });
  }

  async sendEmailVerification(): Promise<void> {
    const _user = await this.ngAuth.currentUser;
    // if (user) await user.sendEmailVerification();
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<string> {
    const user = await this.ngAuth.currentUser;
    if (!user?.email) return 'Failed to update password: User not signed-in';

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
      await user.reauthenticateWithCredential(credential);
    } catch (err) {
      console.error(err);
      return 'Failed to update password: Unable to re-authenticate';
    }

    try {
      await user.updatePassword(newPassword);
      return 'Password updated!';
    } catch (err) {
      console.error(err);
      return 'Failed to update password';
    }
  }
}
