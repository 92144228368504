import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TimezoneModule } from '@greco/feature-timezone';
import { FiltersModule } from '@greco/ngx-filters';
import { CommunitySecurityServiceModule } from '@greco/ngx-identity-community-staff-util';
import { LetModule } from '@greco/ngx-let-util';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { SafePipeModule } from 'safe-pipe';
import { BrivoCommunitySettingsComponent } from './components';
import { BrivoService } from './services';

@NgModule({
  imports: [
    CommonModule,
    FiltersModule,
    RouterModule,
    TableModule,
    ReactiveFormsModule,
    SafePipeModule,

    // Mat
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTooltipModule,

    // Greco
    LetModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    SimpleDialogModule,
    TimezoneModule,
    CommunitySecurityServiceModule,
  ],
  declarations: [BrivoCommunitySettingsComponent],
  exports: [BrivoCommunitySettingsComponent],
  providers: [BrivoService],
})
export class BrivoWebModule {}
