import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription, SubscriptionFreezePeriod } from '@greco/sales-subscriptions';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionFreezeService, SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-freeze-subscription-dialog',
  templateUrl: './freeze-subscription.dialog.html',
  styleUrls: ['./freeze-subscription.dialog.scss'],
})
export class FreezeSubscriptionDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      subscription: Subscription;
      freeze?: SubscriptionFreezePeriod;
      disableStart?: boolean;
      disableEnd?: boolean;
      disableReason?: boolean;
    },
    private dialogRef: MatDialogRef<FreezeSubscriptionDialog>,
    private freezeSvc: SubscriptionFreezeService,
    private breakpointObserver: BreakpointObserver,
    private subscriptionSvc: SubscriptionsService,
    private securitySvc: SecurityService,
    private snacks: MatSnackBar,
    private fb: FormBuilder
  ) {
    if (data?.freeze) {
      this.freeze = data.freeze;
    }

    if (data?.subscription) {
      this.subscription = data.subscription;
    }

    if (data?.disableStart) {
      this.disableStart = data.disableStart;
      this.selectedIndex = 1;
    }

    if (data?.disableEnd) {
      this.disableEnd = data.disableEnd;
    }

    if (data?.disableReason) {
      this.disableReason = data.disableReason;
    }
  }

  @PropertyListener('subscription') subscription$ = new BehaviorSubject<Subscription | undefined>(undefined);
  @Input() subscription?: Subscription;

  @PropertyListener('freeze') freeze$ = new BehaviorSubject<SubscriptionFreezePeriod | undefined>(undefined);
  @Input() freeze?: SubscriptionFreezePeriod;

  @Input() disableStart = false;

  @Input() disableEnd = false;

  @Input() disableReason = false;

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));
  readonly updateReasons = ['Financial', 'Medical', 'Non-Usage', 'Other', 'Vacation', 'Health', 'Work'];

  communityId?: string;

  @ViewChild('formContainer', { read: ViewContainerRef }) formContainer?: ViewContainerRef;

  dialogData: DialogData = {
    title: 'Freeze Membership',
    subtitle: 'Select the dates you wish to make the freeze.',
    hideDefaultButton: true,
    showCloseButton: true,
  };

  formGroup = this.fb.group({
    startDate: [moment().toDate()],
    startDateRadioButton: ['custom'],
    reason: [''],
    endDate: [null],
    endDateRadioButton: ['custom'],
    proration: [true],
  });

  resetValue = {
    startDate: moment().toDate(),
    startDateRadioButton: 'custom',
    reason: null as string | null,
    endDate: null as Date | null,
    endDateRadioButton: 'custom',
    proration: true,
  };

  minimalDate = moment().toDate();
  maximalDate = moment().add(5, 'years').toDate();

  processing = false;

  now = new Date().getTime();

  selectedIndex = 0;

  async ngOnInit() {
    if (!this.freeze) return;

    this.resetValue = {
      startDate: moment(this.freeze.startDate).toDate(),
      startDateRadioButton: 'custom',
      reason: this.freeze.reason,
      endDate: this.freeze.endDate ? moment(this.freeze.endDate).toDate() : null,
      endDateRadioButton: this.freeze.endDate ? 'custom' : 'indefinite',
      proration: this.freeze.proration,
    };

    this.formGroup.reset(this.resetValue);
  }

  setDate(type: 'start' | 'end', dateType: 'now' | 'indefinite' | 'endOfPeriod') {
    let date = null;
    switch (dateType) {
      case 'now':
        date = moment().toDate();
        break;
      case 'indefinite':
        date = null;
        break;
      case 'endOfPeriod': {
        const now = moment().toDate();
        let periodEnd = this.subscription?.periodEnd ? moment(this.subscription.periodEnd).toDate() : now;
        if (periodEnd.getTime() < now.getTime()) {
          periodEnd = now;
        }
        date = periodEnd;
        break;
      }
    }

    if (type === 'start') {
      this.formGroup.patchValue({ startDate: date });
    } else {
      this.formGroup.patchValue({ endDate: date });
    }
  }

  setCustomDate(type: 'start' | 'end', date: Date) {
    console.log('set custom date');
    if (type === 'start') {
      this.formGroup.patchValue({ startDate: moment(date).startOf('day').toDate() });
    } else {
      this.formGroup.patchValue({ endDate: moment(date).startOf('day').toDate() });
    }
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    if (!this.subscription) return;
    this.processing = true;

    try {
      const startDate: Date = this.formGroup.get('startDate')?.value;
      const endDate: Date | null = this.formGroup.get('endDate')?.value;
      const reason: string = this.formGroup.get('reason')?.value;
      const proration: boolean = this.formGroup.get('proration')?.value || false;

      if (endDate && startDate.getTime() >= endDate.getTime()) {
        this.snacks.open('End date must be after start date!', 'Ok', {
          duration: 3000,
          panelClass: 'mat-warn',
        });
        this.processing = false;
        return;
      }

      if (!this.freeze) {
        const response = await this.freezeSvc.createFreeze(
          this.subscription.id,
          moment(startDate).startOf('day').toDate(),
          endDate && endDate.getTime() > new Date().getTime() ? moment(endDate).startOf('day').toDate() : endDate,
          reason,
          proration
        );

        this.close(response);
      } else {
        const dto = {
          startDate:
            startDate.getTime() !== this.freeze.startDate.getTime()
              ? moment(startDate).startOf('day').toDate()
              : undefined,
          endDate:
            endDate === null && this.freeze.endDate
              ? null
              : endDate?.getTime() !== this.freeze.endDate?.getTime()
              ? endDate && endDate.getTime() > new Date().getTime()
                ? moment(endDate).startOf('day').toDate()
                : endDate
              : undefined,
        };
        const response = await this.freezeSvc.updateFreeze(
          this.freeze.id,
          dto.startDate,
          dto.endDate,
          reason,
          proration
        );
        this.close(response);
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
  }
}
