import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { AlertLog } from '@greco/domain-alert-logs';

@Injectable()
export class AlertLogsService {
  constructor(private http: HttpClient) {}

  async paginate(userId: string, page?: number, limit?: number) {
    return await toPromise(
      this.http.get<PaginatedDto<AlertLog>>(`/api/alert-logs/${userId}/paginate`, {
        params: {
          page: page?.toString() || '1',
          limit: limit?.toString() || '10',
        },
      })
    );
  }

  async resolveLog(alertLogId: string) {
    return await toPromise(this.http.patch(`/api/alert-logs/${alertLogId}/resolve`, null));
  }
}
