<ng-container *ngIf="isUser$ | async; else staffEditor">
  <greco-collapsible-section
    #section
    [header]="{ title: label, icon: 'info', iconColor: danger ? 'warn' : undefined }"
    [disabled]="alwaysOpen"
    [expanded]="alwaysOpen"
  >
    <ng-content header select="[header]"></ng-content>

    <greco-form-save-buttons
      *ngIf="section.expanded && !readonly"
      header
      [form]="form"
      [saveAction]="save"
      [resetValue]="resetValue"
      (formReset)="form.markAsPristine()"
    ></greco-form-save-buttons>

    <ng-container>
      <div
        *ngIf="missingFields$ | async as missingFields"
        style="color: var(--danger-color, red); font-size: 85%; margin-bottom: 16px"
      >
        Missing information: {{ missingFields }}
      </div>
    </ng-container>

    <form [formGroup]="form">
      <!-- Profile Picture -->
      <mat-form-field id="picture-form-field" floatLabel="always" appearance="standard" style="width: 100%">
        <greco-user-avatar matPrefix size="small" [showOnlineStatus]="false" [user]="form.value"></greco-user-avatar>

        <mat-label style="padding-left: 24px">Profile Picture</mat-label>
        <input
          [readonly]="readonly"
          [disabled]="resetValue.photoURL"
          style="padding-left: 16px"
          matInput
          readonly
          [placeholder]="resetValue.photoURL ? 'Your profile picture is set' : 'Upload new photo'"
          [value]="profilePictureFile?.name || ''"
        />

        <input
          #fileInput
          [readonly]="readonly || resetValue.photoURL"
          style="display: none"
          type="file"
          accept="image/*"
          (change)="setProfilePicture($any($event.target).files[0])"
        />

        <div matSuffix style="display: flex" *ngIf="!resetValue.photoURL">
          <button mat-button color="primary" (click)="fileInput.click()" [disabled]="readonly">Select File</button>
          <button mat-button color="primary" (click)="takePhoto()" [disabled]="readonly">Use Webcam</button>
        </div>

        <mat-hint *ngIf="resetValue.photoURL" style="color: rgba(14, 15, 15, 0.38); margin-bottom: 0">
          You may ask a staff to update your profile picture
        </mat-hint>
      </mat-form-field>

      <!-- Email -->
      <mat-form-field appearance="standard" style="width: 100%" [class.mat-form-field-disabled]="resetValue.email">
        <mat-label>Email Address</mat-label>
        <input
          [readonly]="readonly || resetValue.email"
          matInput
          type="email"
          required
          formControlName="email"
          placeholder="What's your email address?"
        />
        <mat-hint *ngIf="resetValue.email" style="color: rgba(14, 15, 15, 0.38); margin-bottom: 0">
          You may ask a staff to update your email
        </mat-hint>
      </mat-form-field>

      <!-- Name -->
      <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 16px">
        <mat-form-field appearance="standard" style="flex: 1" [class.mat-form-field-disabled]="resetValue.firstName">
          <mat-label>First Name</mat-label>
          <input
            [readonly]="readonly || resetValue.firstName"
            matInput
            required
            formControlName="firstName"
            placeholder="What's your first name?"
          />
          <mat-hint *ngIf="resetValue.firstName" style="color: rgba(14, 15, 15, 0.38); margin-bottom: 0">
            You may ask a staff to update your name
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="standard" style="flex: 1" [class.mat-form-field-disabled]="resetValue.lastName">
          <mat-label>Last Name</mat-label>
          <input
            [readonly]="readonly || resetValue.lastName"
            matInput
            required
            formControlName="lastName"
            placeholder="What's your last name?"
          />
        </mat-form-field>
      </div>

      <!-- Friendly Name -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Nickname</mat-label>
        <input [readonly]="readonly" matInput formControlName="friendlyName" placeholder="What should we call you?" />
      </mat-form-field>

      <!-- Phone Number -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Phone Number</mat-label>
        <input
          [readonly]="readonly"
          matInput
          type="tel"
          mask="(000) 000-0000"
          formControlName="phoneNumber"
          required
          placeholder="Phone number"
        />
        <mat-hint
          [style.color]="
            form.controls.phoneNumber.touched && form.controls.phoneNumber.invalid
              ? 'var(--danger-color, red)'
              : 'unset'
          "
        >
          <span *ngIf="form.controls.phoneNumber.touched && form.controls.phoneNumber.invalid">*Required - </span>
          At what phone number can we reach you?
        </mat-hint>
      </mat-form-field>

      <!-- Address -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Address</mat-label>
        <greco-address-input required [disabled]="readonly" formControlName="address"></greco-address-input>
      </mat-form-field>

      <!-- Birthday -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Birthday</mat-label>
        <input
          matInput
          [max]="nowDate"
          [readonly]="readonly"
          required
          [disabled]="readonly"
          [matDatepicker]="birthday"
          formControlName="birthday"
          placeholder="Birthday"
          (click)="birthday.open()"
        />
        <mat-datepicker-toggle [disabled]="readonly" matSuffix [for]="birthday"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="readonly" startView="multi-year" #birthday></mat-datepicker>
        <mat-hint
          [style.color]="
            form.controls.birthday.touched && form.controls.birthday.invalid ? 'var(--danger-color, red)' : 'unset'
          "
        >
          <span *ngIf="form.controls.birthday.touched && form.controls.birthday.invalid">*Required - </span>
          When's your birthday?
        </mat-hint>
      </mat-form-field>

      <!-- Gender -->
      <div style="margin-bottom: -1.34375em">
        <mat-form-field appearance="standard" style="width: 100%">
          <mat-label>Gender</mat-label>
          <mat-select #select formControlName="gender" required>
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Non-binary">Non-binary</mat-option>
            <mat-option value="Transgender">Transgender</mat-option>
            <mat-option value="Intersex">Intersex</mat-option>
            <mat-option value="Prefer not to say">Prefer not to say</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>

          <mat-hint
            *ngIf="form.controls.gender.value !== 'Other'"
            [style.color]="
              form.controls.gender.touched && form.controls.gender.invalid ? 'var(--danger-color, red)' : 'unset'
            "
          >
            <span *ngIf="form.controls.gender.touched && form.controls.gender.invalid">*Required - </span>
            What's your gender?
          </mat-hint>
        </mat-form-field>

        <mat-form-field
          appearance="standard"
          class="other-field"
          [floatLabel]="'always'"
          [style.overflow]="select.value === 'Other' ? 'auto' : 'hidden'"
          [@heightExpansion]="select.value === 'Other' ? 'expanded' : 'collapsed'"
        >
          <mat-label style="margin-left: 12px">Other</mat-label>
          <input matInput placeholder="Please Specify..." formControlName="genderOther" />

          <mat-hint
            *ngIf="form.controls.gender.value === 'Other'"
            [style.color]="
              form.controls.genderOther.touched && form.controls.genderOther.invalid
                ? 'var(--danger-color, red)'
                : 'unset'
            "
          >
            <span *ngIf="form.controls.genderOther.touched && form.controls.genderOther.invalid">*Required - </span>
            What's your gender?
          </mat-hint>
        </mat-form-field>
      </div>

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>
          Emergency Contact Name
          <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
        </mat-label>
        <input [readonly]="readonly" matInput formControlName="emergencyContactName" />
        <mat-hint
          [style.color]="
            form.controls.emergencyContactName.touched && form.controls.emergencyContactName.invalid
              ? 'var(--danger-color, red)'
              : 'unset'
          "
        >
          <span *ngIf="form.controls.emergencyContactName.touched && form.controls.emergencyContactName.invalid">
            *Required -
          </span>
          Who should we call in case of an emergency?
        </mat-hint>
      </mat-form-field>

      <!-- Emergency Phone Number -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Emergency Contact Phone Number</mat-label>
        <input
          [readonly]="readonly"
          matInput
          required
          type="tel"
          mask="(000) 000-0000"
          formControlName="emergencyPhoneNumber"
        />
        <mat-hint
          [style.color]="
            form.controls.emergencyPhoneNumber.touched && form.controls.emergencyPhoneNumber.invalid
              ? 'var(--danger-color, red)'
              : 'unset'
          "
        >
          <span *ngIf="form.controls.emergencyPhoneNumber.touched && form.controls.emergencyPhoneNumber.invalid">
            *Required -
          </span>
          At what number can we reach your emergency contact?
        </mat-hint>
      </mat-form-field>

      <!-- <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>
        Emergency Contact Email
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
      </mat-label>
      <input
        [readonly]="readonly"
        matInput
        formControlName="emergencyContactEmail"
        placeholder="What is your emergency contacts email?"
      />
    </mat-form-field> -->

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>
          Emergency Contact Relationship
          <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
        </mat-label>
        <input [readonly]="readonly" matInput formControlName="emergencyContactRelationship" />
        <mat-hint
          [style.color]="
            form.controls.emergencyContactRelationship.touched && form.controls.emergencyContactRelationship.invalid
              ? 'var(--danger-color, red)'
              : 'unset'
          "
        >
          <span
            *ngIf="
              form.controls.emergencyContactRelationship.touched && form.controls.emergencyContactRelationship.invalid
            "
          >
            *Required -
          </span>
          What is your relationship to the emergency contact?
        </mat-hint>
      </mat-form-field>
    </form>
  </greco-collapsible-section>
</ng-container>

<ng-template #staffEditor>
  <greco-user-profile-details-optional
    [preventReload]="preventReload"
    [user]="user"
    [readonly]="readonly"
    [danger]="danger"
    [alwaysOpen]="alwaysOpen"
    [label]="label"
    (saved)="saved.emit($event)"
  >
    <ng-content header select="[staffHeader]"></ng-content>
  </greco-user-profile-details-optional>
</ng-template>
