import { SecurityResource } from '@greco/security';

export enum ContactResourceAction {
  READ = 'READ',
  EXPORT = 'EXPORT',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ_SUBSCRIPTIONS = 'READ_SUBSCRIPTIONS',
  INVITE_USER = 'INVITE_USER',
  SUBSCRIBE = 'SUBSCRIBE',
  DELETE_NOTE = 'DELETE_NOTE',
  RESOLVE_ALERT_LOG = 'RESOLVE_ALERT_LOG',
}

export const ContactResource: SecurityResource = {
  key: 'identity_contact',
  module: 'Identity',

  title: 'Contact',
  description: 'Community Contacts',

  context: class {
    communityId: string;
    contactId: string;
    parentCommunityId: string;
    userId: string;
  },

  actions: [
    { key: ContactResourceAction.READ, title: 'Read', description: 'Ability to view contacts' },
    { key: ContactResourceAction.EXPORT, title: 'Export', description: 'Ability to export contacts' },
    { key: ContactResourceAction.CREATE, title: 'Create/Import', description: 'Ability to create and import contacts' },
    {
      key: ContactResourceAction.INVITE_USER,
      title: 'Invite User',
      description: 'Ability to create user accounts and invite them via email',
    },
    { key: ContactResourceAction.UPDATE, title: 'Update', description: "Ability to update a contact's information" },
    { key: ContactResourceAction.DELETE, title: 'Delete', description: 'Ability to remove a contact' },
    {
      key: ContactResourceAction.READ_SUBSCRIPTIONS,
      title: 'Read Subscriptions',
      description: "Read a user's subscriptions",
    },
    { key: ContactResourceAction.SUBSCRIBE, title: 'Subscribe', description: 'Subscribe to a community' },
    {
      key: ContactResourceAction.DELETE_NOTE,
      title: 'Delete Member Note',
      description: 'Ability to delete member notes',
    },
    {
      key: ContactResourceAction.RESOLVE_ALERT_LOG,
      title: 'Resolve Alert log',
      description: 'Ability to resolve member alert logs (used for gate access at Altea Ottawa)',
    },
  ],
};
