/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { User } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { UserService } from '@greco/ngx-identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { AccountLinkingService } from '@greco/web-account-linking';
import { Subject } from 'rxjs';

@Component({
  selector: 'greco-checkout-station-pay-dialog',
  templateUrl: './checkout-station-pay.dialog.html',
  styleUrls: ['./checkout-station-pay.dialog.scss'],
})
export class CheckoutStationPayDialog implements OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CheckoutStationPayDialog>,
    private formBuilder: FormBuilder,
    private clipboardSvc: ClipboardService,
    private linkSvc: AccountLinkingService,
    private securitySvc: SecurityService,
    private userSvc: UserService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      communityId: string;
      user: User;
      billedTo: User;
      variants: { variantId: string; quantity: number }[];
      applyCoupons: boolean;
    }
  ) {
    this.communityId = data.communityId;
    this.user = data.user;
    this.billedTo = data.billedTo;
    this.variants = data.variants;
    this.applyCoupons = data.applyCoupons;
  }

  user!: User;
  billedTo!: User;
  variants: { variantId: string; quantity: number }[] = [];
  applyCoupons = true;

  private onDestroy$ = new Subject<void>();

  communityId: string;

  dialogData: DialogData = {
    title: 'Checkout',
    subtitle: '',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  async close(success = false) {
    return this.dialogRef.close(success);
  }

  // async ngOnInit() {
  //   this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.onDestroy$)).subscribe(async () => {
  //     const contact = this.form.value.contact as Contact;
  //     this.billedTo = undefined;

  //     // This setTimeout is required for weird timing issues. The accounts for the user and the purchasedBy update seperately so
  //     //  this makes sure to wait for them both to be updated by the above line before giving them new values
  //     setTimeout(async () => {
  //       if (contact?.user!.email) {
  //         this.billedTo = contact.user;
  //         return;
  //       } else {
  //         const parents = (await this.linkSvc.getGivenLinksByAccount(contact?.user!.id))
  //           ?.filter(link => link.status === AccountLinkStatus.ACTIVE)
  //           .map(link => link.accessor!);

  //         if (parents.length) {
  //           this.billedTo = parents[0];
  //           return;
  //         } else {
  //           this.billedTo = undefined;
  //         }
  //       }
  //     }, 200);
  //   });

  //   this.user = await this.userSvc.getSelf();
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
