<greco-simple-dialog [data]="dialogData" class="create-custom-purchase-dialog">
  <form [formGroup]="form">
    <mat-vertical-stepper #stepper [selectedIndex]="currentStep" (selectionChange)="setStep($event.selectedIndex)">
      <mat-step>
        <ng-template matStepLabel>Select User</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>User</mat-label>
          <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId">
          </greco-contact-picker>
        </mat-form-field>
        <div>
          <button [disabled]="!form.value.contact" mat-button matStepperNext>Select Product</button>
        </div>
      </mat-step>

      <ng-container *ngIf="form.value.contact">
        <ng-container *ngFor="let variantAndQuantityControl of variantControls; let i = index; let last = last">
          <ng-container *ngIf="variantAndQuantityControl.variant as variantControl">
            <mat-step>
              <ng-template matStepLabel>
                <div style="display: flex; flex-direction: column; justify-content: center">
                  <span>Select Product{{variantControls.length > 1 ? ' ' + (i + 1) : ''}}</span>
                  <p *ngIf="variantControl.value as variant" style="padding: 0; margin: 0">
                    <small>
                      {{variantAndQuantityControl.quantity.value}} x {{variant.title || ((variant.variantOpts || []) |
                      pluck: 'value').join(', ')}}
                    </small>
                  </p>
                </div>
              </ng-template>
              <div style="display: flex; flex-direction: row; gap: 4px; width: 100%; align-items: center">
                <!-- Quantity (when one-time variant) -->
                <div style="display: flex; flex-direction: row; gap: 1px; align-items: center; max-width: 30%">
                  <button
                    mat-icon-button
                    [disabled]="!variantControl.value || variantControl.value?.recurrence?.frequency || variantAndQuantityControl.quantity.value <= 1"
                    (click)="variantAndQuantityControl.quantity.setValue(variantAndQuantityControl.quantity.value - 1); variantChanges()"
                  >
                    <mat-icon
                      color="primary"
                      [class]="!variantControl.value || variantControl.value?.recurrence?.frequency || variantAndQuantityControl.quantity.value <= 1 ? 'disabled' : ''"
                      >remove_circle</mat-icon
                    >
                  </button>
                  <mat-form-field appearance="standard" style="width: 48px">
                    <input
                      matInput
                      type="number"
                      [min]="1"
                      [disabled]="!variantControl.value || variantControl.value?.recurrence?.frequency"
                      [readonly]="!variantControl.value || variantControl.value?.recurrence?.frequency"
                      [formControl]="variantAndQuantityControl.quantity"
                      (input)="variantChanges()"
                      (change)="variantChanges()"
                      style="text-align: center"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    [disabled]="!variantControl.value || variantControl.value?.recurrence?.frequency"
                    (click)="variantAndQuantityControl.quantity.setValue(variantAndQuantityControl.quantity.value + 1); variantChanges()"
                  >
                    <mat-icon
                      color="primary"
                      [class]="!variantControl.value || variantControl.value?.recurrence?.frequency ? 'disabled' : ''"
                      >add_circle</mat-icon
                    >
                  </button>
                </div>
                <!-- Variant Picker -->
                <mat-form-field appearance="standard">
                  <mat-label>Product</mat-label>

                  <greco-product-variant-picker
                    style="width: 100%"
                    placeholder="Start typing to search for products..."
                    [communityId]="communityId"
                    [required]="true"
                    [showRestrictedVariants]="(canSellRestrictedVariants$ | async) || false"
                    [formControl]="$any(variantControl)"
                    (selectedVariantChange)=" ($event.recurrence?.frequency ? variantAndQuantityControl.quantity.setValue(1) : {}); variantChanges();"
                    [onlyOneTime]="variantControls.length > 1"
                    [variantIdsToExclude]="(variantIds$ | async) || []"
                  ></greco-product-variant-picker>
                </mat-form-field>

                <!-- Deselect Variant (when multiple) -->
                <button
                  *ngIf="variantControls.length > 1"
                  mat-icon-button
                  (click)="$event.stopImmediatePropagation(); removeVariant(i)"
                  color="warn"
                >
                  <mat-icon style="color: var(--warn-color)">delete</mat-icon>
                </button>
              </div>

              <mat-error *ngIf="variantControl?.value?.recurrence?.frequency && variantControls.length > 1"
                >Cannot select multiple products when purchasing recurring products. Please review your
                selections!</mat-error
              >
              <div
                style="display: flex; flex-direction: row; justify-content: space-between"
                *grecoLet="canSelectMultipleVariants$ | async as canSelectMultipleVariants"
              >
                <button *ngIf="!last && variantControl?.value" mat-button matStepperNext>Next Product</button>
                <div
                  *ngIf="last && variantControl?.value"
                  [matTooltip]="canSelectMultipleVariants ? 'Add additional one-time products to this purchase' : 'Membership products can only be purchased one at a time'"
                >
                  <button
                    mat-button
                    (click)="addAdditionalProduct()"
                    [disabled]="(canSelectMultipleVariants$ | async) !== true"
                  >
                    Add Additional Product
                  </button>
                </div>

                <button
                  *ngIf="variantControl?.value"
                  mat-button
                  (click)="copyCheckoutUrlToClipboard( form.value.contact.user.id)"
                >
                  Copy Link
                </button>
              </div>
              <div *ngIf="last && variantControl?.value " style="width: 100%; margin-top: 8px">
                <mat-divider></mat-divider>
                <button style="width: 100%; margin-top: 16px" mat-stroked-button color="primary" matStepperNext>
                  Preview Purchase
                </button>
              </div>
            </mat-step>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="form.value.contact && allVariantsSelected$ | async">
        <mat-step #checkout>
          <ng-template matStepLabel>Checkout</ng-template>
          <greco-checkout-page
            *ngIf="stepper.selected === checkout"
            [isStaff]="true"
            [user]="billedTo"
            [purchasedBy]="billedTo"
            [preventRedirect]="true"
            [variants]="(variantsAndQuantities$ | async) || []"
            (purchaseSuccess)="close()"
          ></greco-checkout-page>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </form>
</greco-simple-dialog>
