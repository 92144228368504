import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import {
  BookingOption,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventTemplateResourceAssignment,
  Resource,
  ResourceAvailabilityStatus,
  ResourceTag,
  ResourceType,
  RoomResource,
  Tag,
} from '@greco/booking-events';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import type { FormEntity } from '@greco/nestjs-typeform';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { Timezone, userDefaultTimezone } from '@greco/timezone';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { combineLatest, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BookingOptionService } from '../../services';
import { TagsInputComponent } from '../tags-input/tags-input.component';

export interface EventFromTemplateDetails {
  startDate?: Date | null;
  timezone?: Timezone;

  color?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  description?: string | null;
  tags?: Tag[] | null;
  resourceTags?: ResourceTag[] | null;
  resourceAssignments?: EventTemplateResourceAssignment[] | null;
  autoAssign?: boolean | null;
  enableUserSpotBooking: boolean;
  duration?: number | null;
  checkInWindow?: number | null;
  private?: boolean | null;
  maxCapacity?: number | null;
  typeform?: (FormEntity & { reusable: boolean; required: boolean })[];

  roomAssignment: EventTemplateResourceAssignment | null;
  zoomAssignment: EventTemplateResourceAssignment | null;
  zoomMeetingId?: string | null;

  extraDates?: Date[] | null;

  preBookings: {
    user: User;

    bookingOption: BookingOption | null;
    paymentMethod: PaymentMethod | null;

    bookPending: boolean;
    canBookPending: boolean;
    requiredPendingBookings: number;
  }[];

  equipmentTitle: string;
  equipmentOptions: string[];
}

@Component({
  selector: 'greco-event-from-template-input',
  templateUrl: './event-from-template-input.component.html',
  styleUrls: ['./event-from-template-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EventFromTemplateInputComponent), multi: true },
  ],
})
export class EventFromTemplateInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private authSvc: UserService,
    private bookingOptSvc: BookingOptionService
  ) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  @ViewChild('tagsInput') tagsInput?: TagsInputComponent;
  @Input() isCompleted = false;
  @Input() initialImage?: string;

  memberToAdd = new FormControl();

  readonly stateChanges = new Subject<void>();

  private onTouched?: () => void;
  private onChanged?: (value: EventFromTemplateDetails | null) => void;
  private _onDestroy$ = new Subject<void>();

  private user$ = this.authSvc.getUserId().pipe(shareReplay(1));

  canBook$ = this.user$.pipe(
    switchMap(() =>
      this.securitySvc.hasAccess(EventBookingSecurityResource.key, EventBookingSecurityResourceAction.CREATE, {}, true)
    )
  );

  canBookPending$ = this.user$.pipe(
    switchMap(() =>
      this.securitySvc.hasAccess(
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.CREATE_PENDING,
        {},
        true
      )
    )
  );

  canBookPendingFromSub$ = this.user$.pipe(
    switchMap(() =>
      this.securitySvc.hasAccess(
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.CREATE_PENDING_FROM_SUBSCRIPTION,
        {},
        true
      )
    )
  );

  @Input() initialStartDate?: Date | null;
  @Input() readonly = false;
  @Input() forSeries = false;
  @Input() lockResources = false;
  @Input() privateTag?: boolean;
  @Input() customAccess = false;
  @Input() tagsLocked = false;
  @Input() durationLocked = false;
  @Input() maxCapacityLocked = false;
  @Input() titleLocked = false;
  @Input() imageUrlLocked = false;
  @Input() colorLocked = false;
  @Input() resourcesLocked = false;
  @Input() typeformLocked = false;
  @Input() descriptionLocked = false;
  @Input() privateLocked = false;
  @Input() checkInWindowLocked = false;
  @Input() resource: Resource | null = null;
  @Input() resourceTag: ResourceTag | null = null;
  @Input() enableUserSpotBookingLocked = false;
  @Input() equipmentLocked = false;

  availability: { [key: string]: ResourceAvailabilityStatus } = {};
  @Output() resourceAvailability = new EventEmitter<{ [key: string]: ResourceAvailabilityStatus }>();

  weeksRecurring = 0;
  startDate = new Date();
  extraDates: Date[] = [];
  weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  saving = false;

  _form = this.formBuilder.group({
    eventStart: [
      { date: null, timezone: userDefaultTimezone() },
      [this.forSeries ? Validators.nullValidator : Validators.required],
    ],

    color: ['#005574', [Validators.required]],
    title: ['', [Validators.required]],
    imageUrl: [null],
    description: ['', []],
    tags: [[], [Validators.required, Validators.minLength(1)]],
    resourceAssignments: [[], []],
    autoAssign: [false],
    enableUserSpotBooking: [false],
    duration: [null, [Validators.required]],
    checkInWindow: [null, [Validators.required]],
    private: [true],
    maxCapacity: [[Validators.required, Validators.min(0)]],
    roomAssignment: [null],
    zoomAssignment: [null],
    zoomMeetingId: [null],
    typeform: [[]],

    equipmentTitle: [null],
    equipmentOptions: [[]],

    extraDates: [[]],
    preBookings: [<EventFromTemplateDetails['preBookings']>[]],
  });

  _required = true;

  _readonly = false;

  @Input() communityId!: string | null;

  bookingOptions$ = combineLatest([
    this._form.valueChanges.pipe(startWith(null)),
    this.stateChanges.pipe(startWith(undefined)),
  ]).pipe(
    switchMap(async () => {
      const formValue = this._form.getRawValue();
      if (!this.communityId) return;
      const ids = formValue.tags
        ? formValue.tags.filter((tag: any) => !!tag.id).map((tag: any) => tag.id as string)
        : [];
      if (!ids.length) return;
      const query = new RequestQueryBuilder();
      query.search({
        'tags.id': { $in: ids },
        isCourseOption: { $eq: false },
      });
      return await this.bookingOptSvc.paginate(query, this.communityId, { limit: 100 });
    }),
    map(data => data?.items || []),
    shareReplay(1)
  );

  @Input() get value() {
    this.resourceAvailability.emit(this.availability);

    const data: EventFromTemplateDetails = this._form.getRawValue();

    const resourceTags = data.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment.resourceTag && assignment.resourceTagId) {
        if (!acc.map(resourceTag => resourceTag.id).includes(assignment?.resourceTagId)) {
          acc.push(assignment.resourceTag);
        }
      }
      return acc;
    }, [] as ResourceTag[]);

    const newStartDate = this._form.value?.eventStart?.date;
    if (newStartDate && newStartDate?.toString() !== this.startDate.toString()) {
      this.extraDates = this.extraDates.filter(date => date?.toString() !== this.startDate.toString());
      this.extraDates.push(newStartDate);
      this.startDate = newStartDate;
    }

    let extraDates = this.generateExtraDates();
    extraDates = extraDates?.filter(date => date.toISOString() !== newStartDate.toISOString());

    return this._form.valid
      ? <EventFromTemplateDetails>{
          startDate: (data as any).eventStart.date,
          timezone: (data as any).eventStart.timezone,

          color: data.color || '#005574',
          title: data.title,
          imageUrl: data.imageUrl,
          description: data.description,
          tags: data.tags,
          resourceTags,
          resourceAssignments: data.resourceAssignments,
          autoAssign: data.autoAssign,
          enableUserSpotBooking: data.enableUserSpotBooking,
          duration: data.duration,
          checkInWindow: data.checkInWindow,
          private: data.private,
          maxCapacity: data.maxCapacity,
          typeform: data.typeform || [],
          roomAssignment: data.roomAssignment,
          zoomAssignment: data.zoomAssignment,
          zoomMeetingId: data.zoomMeetingId,

          equipmentTitle: data.equipmentTitle,
          equipmentOptions: data.equipmentOptions,

          preBookings: [...(this._form.value.preBookings ?? [])],
          extraDates,
        }
      : null;
  }

  set value(value: EventFromTemplateDetails | null) {
    this._form.patchValue({
      eventStart: {
        date: value?.startDate || null,
        timezone: value?.timezone || userDefaultTimezone(),
      },

      color: value?.color || '#005574',
      title: value?.title || null,
      imageUrl: value?.imageUrl || null,
      description: value?.description || null,
      tags: value?.tags || [],
      resourceAssignments: value?.resourceAssignments || [],
      autoAssign: value?.autoAssign || false,
      enableUserSpotBooking: value?.enableUserSpotBooking || false,
      duration: value?.duration || null,
      checkInWindow: value?.checkInWindow ?? null,
      private: value ? value.private : true,
      maxCapacity: value?.maxCapacity ?? null,
      typeform: value?.typeform || [],
      roomAssignment: value?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM),
      zoomAssignment: value?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ZOOM),
      zoomMeetingId: value?.zoomMeetingId || null,

      equipmentTitle: value?.equipmentTitle || null,
      equipmentOptions: value?.equipmentOptions || [],

      extraDates: value?.extraDates || [],
    });

    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('title')?.setValidators(validator);
    this._form.get('tags')?.setValidators(validator);
    this._form.get('startDate')?.setValidators(validator);
    this._form.get('duration')?.setValidators(validator);
    this._form.get('checkInWindow')?.setValidators(validator);

    this.stateChanges.next();
  }

  addPrebooking() {
    const preBookings = <EventFromTemplateDetails['preBookings']>(this._form.value.preBookings ?? []);
    if (preBookings.length >= (this._form.value.maxCapacity || 0)) return;

    const sameUser = preBookings.some(preBooking => preBooking.user.id === this.memberToAdd.value.id);
    if (sameUser) return;

    this._form.patchValue({
      preBookings: [
        ...preBookings,
        {
          user: { ...this.memberToAdd.value },
          requiredPendingBookings: 0,
          canBookPending: false,
          bookingOption: null,
          paymentMethod: null,
          bookPending: false,
        },
      ],
    });

    this.memberToAdd.setValue(null);
  }

  setPreBookingOption(index: number, preBookingOption: any, pending: boolean) {
    const preBookings = <EventFromTemplateDetails['preBookings']>(this._form.value.preBookings ?? []);

    if (preBookings[index]) {
      preBookings[index].bookPending = pending;
      preBookings[index].bookingOption = preBookingOption ?? null;
      preBookings[index].canBookPending = preBookingOption?.canBookPending ?? false;
      preBookings[index].requiredPendingBookings = preBookingOption?.requiredPendingBookings ?? 0;

      this._form.patchValue({ preBookings: [...preBookings] });
    }
  }

  setPaymentMethod(index: number, paymentMethod: PaymentMethod | null) {
    const preBookings = <EventFromTemplateDetails['preBookings']>(this._form.value.preBookings ?? []);

    if (preBookings[index]) {
      preBookings[index].paymentMethod = paymentMethod;
      this._form.patchValue({ preBookings: [...preBookings] });
    }
  }

  removePreBooking(preBooking: any) {
    const preBookings = <EventFromTemplateDetails['preBookings']>(this._form.value.preBookings ?? []);
    preBookings.splice(preBookings.indexOf(preBooking), 1);
    this._form.patchValue({ preBookings: [...preBookings] });
  }

  updateCapacity(room: RoomResource) {
    if (!this._form.value.maxCapacity && room?.spotCount) this._form.patchValue({ maxCapacity: room.spotCount });
  }

  getDayTimes(day: string) {
    return this.extraDates.filter(date => date.toString().includes(day.substring(0, 3)));
  }

  removeTime(time: Date) {
    this.extraDates = this.extraDates.filter(date => date.toString() !== time.toString());
    const extraDates = this.generateExtraDates();
    this._form.patchValue({ extraDates });
  }

  addTime(time: Date, day: number) {
    const date = new Date((this._form.value.eventStart.date as Date) || null);
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
    date.setDate(date.getDate() + ((day + 7 - date.getDay()) % 7));

    if (!this.extraDates.map(extraDate => extraDate.toString()).includes(date.toString())) {
      this.extraDates.push(date);
      const extraDates = this.generateExtraDates();
      this._form.patchValue({ extraDates });
    }
  }

  createEquipmentOption(equipmentOption: string) {
    this._form.patchValue({ equipmentOptions: [...this._form.value.equipmentOptions, equipmentOption] });
  }

  removeEquipmentOption(equipmentOption: string) {
    this._form.patchValue({
      equipmentOptions: this._form.value.equipmentOptions.filter((option: any) => option !== equipmentOption),
    });
  }

  generateExtraDates() {
    if (!this._form.value?.eventStart?.date) return [];

    if (this.extraDates && this.weeksRecurring > 0) {
      const newDates: Date[] = [];

      for (let week = 0; week <= this.weeksRecurring; week++) {
        this.extraDates.forEach(time => {
          const date = new Date();
          date.setTime(time.getTime());
          date.setDate(time.getDate() + 7 * week);
          newDates.push(date);
        });
      }

      return newDates;
    } else {
      return this.extraDates;
    }
  }

  setWeeksRecurring(str: string) {
    const num = +str;
    this.weeksRecurring = num;
    const extraDates = this.generateExtraDates();
    this._form.patchValue({ extraDates });
  }

  writeValue(value: EventFromTemplateDetails): void {
    this.value = value;
  }

  registerOnChange(fn: (value: EventFromTemplateDetails | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    // if (this.initialStartDate) {
    //   this.startDate = this.initialStartDate || new Date();
    //   this._form.patchValue({
    //     eventStart: { date: this.initialStartDate, timezone: userDefaultTimezone() || null },
    //   });
    // }
    if (this.colorLocked && !this.customAccess) this._form.controls['color'].disable();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
