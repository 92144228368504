<greco-table [data]="(userAlertHistory$ | async)?.items || []">
  <mat-icon
    *grecoTableCol="''; fitContent: true; let alert"
    [matTooltip]="alert.type"
    [class]="alert | alertColor"
    style="padding: 0"
    >{{ alert | alertIcon }}</mat-icon
  >

  <p
    *grecoTableCol="'Alert'; let alert"
    [class]="alert | alertColor"
    [class.strikethrough]="alert.resolved && alert.resolved.getTime() < now"
  >
    {{ alert.title }}
  </p>

  <p *grecoTableCol="'Created'; let alert">{{ alert.created | date }}</p>

  <p *grecoTableCol="'Resolved'; let alert">{{ alert.resolved ? (alert.resolved | date) : '-' }}</p>

  <p *grecoTableCol="'Correlation ID'; let alert">{{ alert.correlationId || '-' }}</p>

  <ng-container *ngIf="canResolve$ | async">
    <div *grecoTableCol="''; fitContent: true; stickyEnd: true; let alert">
      <ng-container *ngIf="!alert.resolved || alert.resolved.getTime() > now">
        <button #trigger mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu>
          <button mat-menu-item (click)="resolveLog(alert.id)">Mark as resolved</button>
        </mat-menu>
      </ng-container>
    </div>
  </ng-container>
</greco-table>
<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
