import { BaseDto } from '@greco/util-dto';

export class CreateCheckInDto extends BaseDto {
  stationId: string;
  userId: string;

  accessPoint?: {
    accessPointExternalId: string;
    accessPointName: string;
  };
}

export class ExpressCheckInDto extends BaseDto {
  stationId: string;
  data: string;

  accessPoint?: {
    accessPointExternalId: string;
    accessPointName: string;
  };
}
