import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { Contact } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { InventoryProductAddon, Product, ProductVariant, ProductVariantInventory } from '@greco/sales-products';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CheckoutStationPayDialog } from '../../dialogs';
import { CheckoutStationService } from '../../services';

@Component({
  selector: 'greco-checkout-station-page',
  templateUrl: './checkout-station.page.html',
  styleUrls: ['./checkout-station.page.scss'],
})
export class CheckoutStationPage {
  constructor(
    private stationSvc: CheckoutStationService,
    private securitySvc: SecurityService,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() communityId!: string;
  @Input() account!: Account;

  @PropertyListener('stationId') stationId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() stationId!: string;

  _search$ = new BehaviorSubject<string>('');

  @PropertyListener('cart') cart$ = new BehaviorSubject<{ variant: ProductVariant; quantity: number }[]>([]);
  cart: { product: Product; variant: ProductVariant; quantity: number }[] = [];

  memberControl = new FormControl(null as Contact | null);
  searchControl = new FormControl('');
  applyCouponsControl = new FormControl(true);

  selectedProductId: string | null = null;

  timeout: any = null;

  loading = false;

  subtotal$ = this.cart$.pipe(map(cart => cart.reduce((a, b) => a + b.quantity * b.variant.price, 0)));

  station$ = this.stationId$.pipe(
    switchMap(async stationId => {
      if (!stationId) return null;
      return await this.stationSvc.getCheckoutStation(stationId);
    })
  );

  canSellOutOfStock$ = this.communityId$.pipe(
    switchMap(async communityId => {
      if (!communityId) return false;

      return await this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.SELL_OUT_OF_STOCK, {
        communityId,
      });
    })
  );

  stationProducts$ = this.stationId$.pipe(
    tap(() => (this.loading = true)),
    switchMap(async stationId => {
      if (!stationId) return [];
      return await this.stationSvc.getStationProducts(stationId);
    }),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  stationProductsRecord$ = this.stationProducts$.pipe(
    map(products => products.reduce((a, b) => ({ ...a, [b.id]: b }), {} as Record<string, Product>))
  );

  searchResults$ = combineLatest([this._search$, this.stationProducts$]).pipe(
    map(([search, products]) => {
      if (!search) return products;

      return products.reduce((a, b) => {
        return [
          ...a,
          {
            ...b,
            variants: b.variants.filter(
              v =>
                v.title?.toLowerCase().includes(search.toLowerCase()) ||
                v.variantOpts?.some(vo => vo.value.toLowerCase().includes(search.toLowerCase()))
            ),
          },
        ];
      }, [] as Product[]);
    })
  );

  async goBack() {
    await this.router.navigate(['admin', 'sales', this.communityId, 'stations']);
  }

  selectProductId(productId: string | null) {
    this.selectedProductId = productId;
    this._search$.next('');
    this.searchControl.setValue('');
  }

  onInputChange(data: string) {
    clearTimeout(this.timeout);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const $this = this;
    this.timeout = setTimeout(function () {
      $this.search(data);
    }, 250);
  }

  search(data: string) {
    if (this.timeout) clearTimeout(this.timeout);
    this.selectedProductId = null;
    this._search$.next(data);
  }

  addToCart(product: Product, variant: ProductVariant, canSellOutOfStock: boolean) {
    const quantity = this.cart.reduce((a, b) => a + (b.variant.id === variant.id ? b.quantity : 0), 0);

    const inventoryAddon: InventoryProductAddon | null = (product as any).inventoryAddon;
    if (inventoryAddon && inventoryAddon.enabled) {
      const variantInventory: ProductVariantInventory | null = (variant as any).variantInventory;
      if (!canSellOutOfStock && (!variantInventory || (variantInventory.availableInventoryCount || 0) <= quantity)) {
        this.snacks.open(`Not enough inventory!`, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        return;
      }
    }

    const index = this.cart.findIndex(i => i.variant.id === variant.id);

    //item is already in cart
    if (index >= 0) {
      this.cart[index].quantity += 1;
      this.cart = [...this.cart];
      this.snacks.open(`Added to cart!`, 'Ok', { duration: 1500, panelClass: 'mat-primary' });
      return;
    }

    // //item is not in cart yet
    this.cart = [...this.cart, { product, variant, quantity: 1 }];
    this.snacks.open(`Added to cart!`, 'Ok', { duration: 1500, panelClass: 'mat-primary' });
  }

  removeOneFromCart(index: number) {
    const item = this.cart[index];
    if (item.quantity > 1) {
      this.cart[index].quantity -= 1;
    } else {
      this.cart.splice(index, 1);
    }

    this.cart = [...this.cart];
  }

  addOneToCart(index: number, canSellOutOfStock: boolean) {
    const { product, variant } = this.cart[index];

    const quantity = this.cart.reduce((a, b) => a + (b.variant.id === variant.id ? b.quantity : 0), 0);

    const inventoryAddon: InventoryProductAddon | null = (product as any).inventoryAddon;
    if (inventoryAddon && inventoryAddon.enabled) {
      const variantInventory: ProductVariantInventory | null = (variant as any).variantInventory;
      if (!canSellOutOfStock && (!variantInventory || (variantInventory.availableInventoryCount || 0) <= quantity)) {
        this.snacks.open(`Not enough inventory!`, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        return;
      }
    }

    this.cart[index].quantity += 1;
    this.cart = [...this.cart];
  }

  async checkout() {
    const variants = this.cart.map(item => ({ variantId: item.variant.id, quantity: item.quantity }));
    const applyCoupons = (await this.applyCouponsControl.value) || false;
    const user = this.memberControl.value?.user;

    if (!user) {
      this.snacks.open('Please select a member', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      return;
    }
    if (!variants.length) {
      this.snacks.open('Please select a product', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      return;
    }

    const dialog = this.dialog.open(CheckoutStationPayDialog, {
      data: { communityId: this.communityId, user, variants, applyCoupons, billedTo: user },
      width: '1000px',
      maxWidth: '90%',
    });

    const response = await toPromise(dialog.afterClosed());
    if (response) {
      this.reset();
    }
  }

  reset() {
    this.clearCart();
    this.memberControl.setValue(null);
    this.searchControl.setValue('');
    this.stationId$.next(this.stationId);
  }

  clearCart() {
    this.cart = [];
  }
}
