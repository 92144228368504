<greco-simple-dialog [data]="{title: 'Add Linked Account', buttons: (dialogButtons$ | async) || []}">
  <ng-container *grecoLet="canCreateChild$ | async as canCreateChild">
    <ng-container *grecoLet="canCreateLink$ | async as canCreateLink">
      <ng-container *grecoLet="canRequestLink$ | async as canRequestLink">
        <ng-container *grecoLet="signedInUser$ | async as signedInUser">
          <mat-tab-group (selectedTabChange)="tabChange($event)">
            <!-- Invite Tab -->
            <mat-tab label="Invite" *ngIf="canCreateLink || canRequestLink">
              <form [formGroup]="inviteFormGroup">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Email Address</mat-label>
                  <input
                    matInput
                    type="email"
                    formControlName="email"
                    placeholder="Enter email of the account you wish to access"
                  />
                </mat-form-field>

                <div class="request-abilities">
                  <div class="checkbox-container">
                    <mat-checkbox matInput color="primary" [checked]="true" [disabled]="true">
                      <span style="font-weight: bold"> Purchase for them </span>
                    </mat-checkbox>
                    <mat-hint> Buy products and services for your friends to use on their account. </mat-hint>
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox
                      matInput
                      color="primary"
                      formControlName="usePerks"
                      [checked]="inviteFormGroup.value.usePerks"
                    >
                      <span style="font-weight: bold"> Book for them </span>
                    </mat-checkbox>
                    <mat-hint> Book your friends into classes and events using their membership perks. </mat-hint>
                  </div>

                  <div class="checkbox-container">
                    <mat-checkbox matInput color="primary" formControlName="twoWay">
                      <span style="font-weight: bold"> Two way access </span>
                    </mat-checkbox>
                    <mat-hint> Give your friends access to book or buy for you too! </mat-hint>
                  </div>
                </div>

                <!--invisible input to stop scroll bar and have dialog scale properly because of mat checkboxes-->
                <input matInput type="email" style="width: 100%; visibility: hidden" />
              </form>
            </mat-tab>

            <!-- Add Child Tab -->
            <mat-tab label="Add Child" *ngIf="canCreateChild?.hasAccess">
              <div *ngIf="canCreateChild?.hasAccess && canCreateChild?.admin">
                <form [formGroup]="linkChildFormGroup">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Find Existing Child</mat-label>
                    <greco-child-account-picker
                      #child
                      formControlName="child"
                      placeholder="Search by email for an existing child"
                    ></greco-child-account-picker>
                    <small *ngIf="child._form.valid && child._form.get('search')?.value && !child.hasResults">
                      <mat-error style="padding-top: 4px"> Unable to find any matching accounts </mat-error>
                    </small>
                  </mat-form-field>
                </form>

                <div class="divider">
                  <mat-divider></mat-divider>
                  <small> OR Create New </small>
                  <mat-divider></mat-divider>
                </div>
              </div>

              <form [formGroup]="childFormGroup">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Display Name</mat-label>
                  <input
                    #nameInput
                    matInput
                    type="text"
                    formControlName="displayName"
                    placeholder="Enter the name of your child"
                  />
                </mat-form-field>
              </form>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</greco-simple-dialog>
