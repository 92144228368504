import { SecurityResource } from '@greco/security';

export const BRIVO_SECURITY_RESOURCE = 'brivo';
export enum BrivoSecurityActions {
  OPEN_ACCESS_POINT = 'OPEN_ACCESS_POINT',
  LINK_ACCESS_POINT = 'LINK_ACCESS_POINT',
  LINK_SITE = 'LINK_SITE',
}

export const BrivoSecurityResource: SecurityResource = {
  key: BRIVO_SECURITY_RESOURCE,
  module: 'community',

  title: 'Brivo Community',
  description: 'Manage brivo configuration for community',

  context: class {
    communityId: string;
  },

  actions: [
    {
      key: BrivoSecurityActions.OPEN_ACCESS_POINT,
      title: 'Open Gate',
      description: 'Ability to manually open gates from stations',
    },
    {
      key: BrivoSecurityActions.LINK_ACCESS_POINT,
      title: 'Link Gate',
      description: 'Ability to link gates to stations',
    },
    {
      key: BrivoSecurityActions.LINK_SITE,
      title: 'Link Site',
      description: 'Ability to link Brivo sites to communities',
    },
  ],
};
