<greco-simple-dialog [data]="dialogData" class="create-custom-purchase-dialog">
  <greco-checkout-page
    [isStaff]="true"
    [user]="user"
    [purchasedBy]="billedTo"
    [preventRedirect]="true"
    [variants]="variants"
    [applyCoupons]="applyCoupons"
    (purchaseSuccess)="close(true)"
  ></greco-checkout-page>
</greco-simple-dialog>
