import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { CommunitySecurityServiceModule } from '@greco/ngx-identity-community-staff-util';
import { LetModule } from '@greco/ngx-let-util';
import { TableModule } from '@greco/ui-table';
import { UserAlertHistoryTableComponent } from './components';
import { AlertColorPipe, AlertIconPipe } from './pipes';
import { AlertLogsService } from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // Mat
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSnackBarModule,

    // Greco
    LetModule,
    TableModule,
    FiltersModule,
    CommunitySecurityServiceModule,
  ],
  declarations: [UserAlertHistoryTableComponent, AlertColorPipe, AlertIconPipe],
  exports: [UserAlertHistoryTableComponent],
  providers: [AlertLogsService],
})
export class AlertLogsModule {}
