/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { GrecoRoute } from '@greco/ngx-routes-util';
import {
  PreviewUnlockCollectionVideoWrapperPage,
  PreviewUnlockEventVideoWrapperPage,
  UserCoursesWrapperPage,
  UserVideoCollectionsWrapperPage,
  VideoCollectionWrapperPage,
} from 'libs/greco/routes/route-user-booking/src/lib/pages';
import { LibraryTempPage } from 'libs/greco/routes/route-user-booking/src/lib/pages/library-temp/library-temp.page';
import { UserCourseWrapperPage } from 'libs/greco/routes/route-user-booking/src/lib/pages/user-course-wrapper/user-course-wrapper.page';
import { UserVideoStoreWrapperPage } from 'libs/greco/routes/route-user-booking/src/lib/pages/user-video-store-wrapper/user-video-store.page';
import { EventWrapperPage } from './pages';
import { EventsPage } from './pages/events/events.page';
import { WorkoutsLayoutPage } from './pages/workouts-layout/workouts-layout.page';
import { EventUserResolver } from './resolvers';

const root: GrecoRoute = {
  path: '',
  component: WorkoutsLayoutPage,
  children: [
    {
      path: '',
      component: EventsPage,
    },
    {
      path: ':eventId',
      component: EventWrapperPage,
      resolve: { user: EventUserResolver },
    },
  ],
};

export const routes: GrecoRoute[] = [
  {
    path: 'courses',
    children: [
      { path: '', component: UserCoursesWrapperPage },
      { path: ':courseId', component: UserCourseWrapperPage },
    ],
  },

  { path: 'library', component: LibraryTempPage },
  {
    path: 'go',
    children: [
      { path: '', component: UserVideoStoreWrapperPage },
      { path: ':eventVideoId', component: PreviewUnlockEventVideoWrapperPage },
    ],
  },
  {
    path: 'collections',
    children: [
      { path: '', component: UserVideoCollectionsWrapperPage },
      {
        path: ':collectionId',
        children: [
          { path: '', component: VideoCollectionWrapperPage },
          { path: ':collectionVideoId', component: PreviewUnlockCollectionVideoWrapperPage },
        ],
      },
    ],
  },
  root,
];
