<form *grecoLet="communitySite$ | async as communitySite" [formGroup]="form" style="width: 100%">
  <mat-form-field appearance="standard" style="width: 100%" *grecoLet="sites$ | async as sites">
    <mat-label>Site</mat-label>
    <mat-select
      formControlName="site"
      placeholder="No site selected..."
      (selectionChange)="selectSite($event.value, sites || [], communitySite)"
      [disabled]="(canLinkSite$ | async) !== true"
    >
      <!-- <mat-option value="" [disabled]="true">No site selected</mat-option> -->
      <mat-option *ngIf="communitySite" [value]="communitySite.siteExternalId" [disabled]="true">{{
        communitySite.siteName
      }}</mat-option>
      <mat-option *ngFor="let site of sites" [value]="site.id">{{ site.siteName }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
