import { BaseDto } from '@greco/util-dto';
import { Expose, Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDefined, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { AccountLinkPrivilege } from '../models';
export class RequestAccountLinkDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  accessorId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  accountId!: string;

  @Expose()
  @IsDefined()
  @IsArray()
  @IsEnum(AccountLinkPrivilege, { each: true })
  privileges!: AccountLinkPrivilege[];

  @IsOptional()
  @IsBoolean()
  @Type(() => Boolean)
  @Transform(({ value }) =>
    typeof value === 'boolean' ? value : typeof value === 'string' ? (value === 'true' ? true : false) : false
  )
  twoWay?: boolean;
}
