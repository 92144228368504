import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { AlertLogPriority } from '../models';

export class CreateAlertLogDto {
  @IsString()
  @IsNotEmpty()
  userId: string;

  @IsString()
  @IsNotEmpty()
  type: string;

  @IsEnum(AlertLogPriority)
  @IsNotEmpty()
  priority: AlertLogPriority;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsString()
  @IsOptional()
  correlationId?: string;

  @IsString()
  @IsOptional()
  correlationLabel?: string;
}
