<form [formGroup]="_form">
  <div class="fields-section">
    <h3>Event Template Details</h3>
    <div class="color-and-title">
      <!-- Color -->
      <div matPrefix class="color">
        <mat-form-field style="width: 48px; display: flex" appearance="standard">
          <input
            #colorInput
            matInput
            type="color"
            [required]="required"
            [readonly]="readonly"
            formControlName="color"
          />
          <mat-icon class="color-icon">palette</mat-icon>
          <mat-slide-toggle
            matTooltip="This toggle locks the Color field in your template"
            color="primary"
            formControlName="colorLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.colorLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.colorLocked">lock_open</mat-icon>
        </mat-form-field>
      </div>

      <!-- Title -->
      <mat-form-field style="margin: 13px 0px 0px -1px; padding-left: 77px" appearance="standard">
        <mat-label>Title</mat-label>
        <input matInput type="text" [required]="required" [readonly]="readonly" formControlName="title" />
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            matTooltip="This toggle locks the Title field in your template"
            color="primary"
            formControlName="titleLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.titleLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.titleLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Event Template Image -->
    <mat-form-field floatLabel="always">
      <mat-label>Image</mat-label>
      <greco-image-upload
        formControlName="imageUrl"
        [required]="false"
        [multiple]="false"
        [urls]="initialImage ? [initialImage] : []"
        [disabled]="readonly"
      ></greco-image-upload>
      <div matSuffix style="display: flex; flex-direction: row">
        <mat-slide-toggle
          matTooltip="This toggle locks the image in your template"
          color="primary"
          formControlName="imageUrlLocked"
        ></mat-slide-toggle>
        <mat-icon *ngIf="_form.value.imageUrlLocked" color="primary">lock</mat-icon>
        <mat-icon *ngIf="!_form.value.imageUrlLocked">lock_open</mat-icon>
      </div>
    </mat-form-field>

    <!-- Description -->
    <div class="color-and-title">
      <mat-form-field appearance="standard">
        <mat-label>Description</mat-label>
        <textarea matInput type="text" formControlName="description" rows="3" [readonly]="readonly"></textarea>
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            matTooltip="This toggle locks the Description field in your template"
            color="primary"
            formControlName="descriptionLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.descriptionLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.descriptionLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Tags -->
    <greco-tags-input
      *ngIf="communityId"
      [disabled]="readonly"
      [communityId]="communityId"
      [tableEnabled]="true"
      placeholder="Tags"
      formControlName="tags"
    >
      <div matSuffix style="display: flex; flex-direction: row">
        <mat-slide-toggle
          matTooltip="This toggle locks the Booking Tags field in your template"
          color="primary"
          formControlName="tagsLocked"
        ></mat-slide-toggle>
        <mat-icon *ngIf="_form.value.tagsLocked" color="primary">lock</mat-icon>
        <mat-icon *ngIf="!_form.value.tagsLocked">lock_open</mat-icon>
      </div>
    </greco-tags-input>

    <!-- Duration -->
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Duration</mat-label>
        <greco-minutes-input
          [disabled]="readonly"
          placeholder="Duration"
          formControlName="duration"
        ></greco-minutes-input>
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            color="primary"
            matTooltip="This toggle locks the Duration field in your template"
            formControlName="durationLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.durationLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.durationLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Check-In Window -->
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Booking Cut-Off Window</mat-label>
        <greco-minutes-input
          [disabled]="readonly"
          placeholder="Cut-Off Window"
          formControlName="checkInWindow"
        ></greco-minutes-input>
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            color="primary"
            matTooltip="This toggle locks the Booking Cut-Off Window field in your template"
            formControlName="checkInWindowLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.checkInWindowLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.checkInWindowLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Max Capacity -->
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Attendee Capacity</mat-label>
        <input
          matInput
          min="0"
          step="1"
          type="number"
          placeholder="Max Capacity"
          [readonly]="readonly"
          formControlName="maxCapacity"
        />
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-icon
            *ngIf="
              _form.value.roomAssignment && _form.value.maxCapacity > _form.value.roomAssignment?.resource.spotCount
            "
            matSuffix
            style="color: var(--warn-color)"
            [matTooltip]="
              'Attendee capacity is greater than room capacity (' + _form.value.roomAssignment.resource.spotCount + ')!'
            "
          >
            error_outlined
          </mat-icon>
          <mat-slide-toggle
            color="primary"
            matTooltip="This toggle locks the Attende Capacity field in your template"
            formControlName="maxCapacityLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.maxCapacityLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.maxCapacityLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Private -->
    <div>
      <mat-form-field appearance="standard">
        <input matInput style="display: none" />
        <mat-checkbox [disabled]="readonly" style="margin-bottom: -4px" color="primary" formControlName="private">
          Private Event
        </mat-checkbox>
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            color="primary"
            matTooltip="This toggle locks the Private Event field in your template"
            formControlName="privateLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.privateLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.privateLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Typeform -->
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Typeform</mat-label>
        <greco-typeform-form-picker
          [disabled]="readonly"
          [communityId]="communityId"
          placeholder="Select one or multiple forms"
          formControlName="typeform"
        ></greco-typeform-form-picker>
        <div matSuffix style="display: flex; flex-direction: row">
          <mat-slide-toggle
            color="primary"
            matTooltip="This toggle locks the Typeform field in your template"
            formControlName="typeformLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.typeformLocked" color="primary">lock</mat-icon>
          <mat-icon *ngIf="!_form.value.typeformLocked">lock_open</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <!-- Resources -->
    <greco-collapsible-section [header]="{ title: 'Resource Assignments', small: true }">
      <div header style="display: flex; flex-direction: row; margin: 2px -12px 0 0">
        <mat-slide-toggle
          style="margin-top: 2px"
          color="primary"
          matTooltip="This toggle locks the Resources field in your template"
          (click)="$event.stopImmediatePropagation()"
          formControlName="resourcesLocked"
        ></mat-slide-toggle>
        <mat-icon *ngIf="_form.value.resourcesLocked" color="primary" style="margin-top: 0">lock</mat-icon>
        <mat-icon *ngIf="!_form.value.resourcesLocked" style="margin-top: 0">lock_open</mat-icon>
      </div>

      <!-- Room Resources-->
      <mat-form-field appearance="standard" *ngIf="communityId">
        <mat-label>Room</mat-label>
        <greco-assignment-picker
          [communityId]="communityId"
          [disabled]="readonly"
          formControlName="roomAssignment"
          placeholder="Room Resource"
          [filterType]="{ type: 'ROOM', limit: 10, exclude: null }"
          (resourceChanged)="updateCapacity($any($event))"
        ></greco-assignment-picker>

        <div matSuffix style="display: flex; flex-direction: row; margin: 2px -12px 0 0">
          <mat-checkbox matSuffix color="primary" formControlName="enableUserSpotBooking" [disabled]="readonly"
            >Allow users to book spots
          </mat-checkbox>
          <mat-slide-toggle
            style="margin-top: 2px"
            color="primary"
            matTooltip="This toggle locks the 'Allow users to book spots' field in your template"
            (click)="$event.stopImmediatePropagation()"
            formControlName="enableUserSpotBookingLocked"
          ></mat-slide-toggle>
          <mat-icon *ngIf="_form.value.enableUserSpotBookingLocked" color="primary" style="margin-top: 0"
            >lock</mat-icon
          >
          <mat-icon *ngIf="!_form.value.enableUserSpotBookingLocked" style="margin-top: 0">lock_open</mat-icon>
        </div>
      </mat-form-field>

      <mat-form-field appearance="standard" *ngIf="communityId">
        <mat-label>Resources Assignments</mat-label>
        <greco-event-resource-assignment
          *ngIf="communityId"
          [communityId]="communityId"
          [startDate]="_form.value.eventStart?.date"
          [duration]="_form.value.duration"
          [disabled]="readonly"
          formControlName="resourceAssignments"
        ></greco-event-resource-assignment>

        <div matSuffix></div>
      </mat-form-field>
      <mat-checkbox
        *ngIf="communityId"
        style="margin-top: 4px; margin-bottom: -8px"
        color="primary"
        [disabled]="readonly"
        formControlName="autoAssign"
      >
        Allow auto-assignment for substitutions
      </mat-checkbox>

      <!-- Resources For Zoom-->
      <mat-form-field appearance="standard" *ngIf="communityId">
        <mat-label>Zoom Resource</mat-label>
        <greco-assignment-picker
          [communityId]="communityId"
          [disabled]="readonly"
          formControlName="zoomAssignment"
          placeholder="Zoom Resource"
          [filterType]="{ type: 'ZOOM', limit: 10, exclude: null }"
        ></greco-assignment-picker>
      </mat-form-field>

      <!--manual Input for meetingId-->
      <mat-form-field appearance="standard" *ngIf="communityId && !_form.value.zoomAssignment">
        <mat-label>Zoom Meeting ID</mat-label>
        <input
          matInput
          type="text"
          [readonly]="readonly"
          formControlName="zoomMeetingId"
          placeholder="Manually Input Zoom Meeting ID"
        />
      </mat-form-field>
    </greco-collapsible-section>

    <greco-collapsible-section [header]="{ title: 'Equipment', small: true }">
      <div header style="display: flex; flex-direction: row; margin: 2px -12px 0 0">
        <mat-slide-toggle
          style="margin-top: 2px"
          color="primary"
          matTooltip="This toggle locks the Equipment field in your template"
          formControlName="equipmentLocked"
          (click)="$event.stopImmediatePropagation()"
        ></mat-slide-toggle>

        <mat-icon *ngIf="_form.value.equipmentLocked" color="primary" style="margin-top: 0">lock</mat-icon>
        <mat-icon *ngIf="!_form.value.equipmentLocked" style="margin-top: 0">lock_open</mat-icon>
      </div>

      <mat-form-field appearance="standard">
        <mat-label>Equipment Title</mat-label>
        <input matInput type="text" [readonly]="readonly" formControlName="equipmentTitle" />
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Equipment Options</mat-label>

        <div style="display: flex">
          <mat-chip-list #equipmentOptions>
            <mat-chip *ngFor="let equipmentOption of _form.value.equipmentOptions">
              {{ equipmentOption }}
              <mat-icon *ngIf="!readonly" matChipRemove (click)="removeEquipmentOption(equipmentOption)">
                cancel
              </mat-icon>
            </mat-chip>
          </mat-chip-list>

          <input
            #equipmentInput
            style="width: 100%"
            placeholder="Enter an equipment option"
            [disabled]="readonly"
            [matChipInputFor]="equipmentOptions"
            [matChipInputSeparatorKeyCodes]="[]"
          />

          <button
            *ngIf="!readonly && equipmentInput.value"
            mat-icon-button
            type="button"
            color="primary"
            style="max-height: 32px"
            matTooltip="Create equipment option"
            (click)="createEquipmentOption(equipmentInput.value); equipmentInput.value = ''"
          >
            <mat-icon>new_label</mat-icon>
          </button>
        </div>
      </mat-form-field>
    </greco-collapsible-section>
  </div>
</form>
