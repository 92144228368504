<greco-simple-dialog [data]="dialogData">
  <greco-table [data]="(subscriptions$ | async) || []" [loading]="loading">
    <ng-container *ngIf="data.updateDefault">
      <mat-checkbox
        *grecoTableCol="''; fitContent: true; let subscription"
        color="primary"
        [value]="subscription"
        (change)="updateSubscriptions(subscription)"
      >
      </mat-checkbox>
    </ng-container>

    <mat-icon *grecoTableCol="''; fitContent: true; let subscription" [matTooltip]="subscription.id">
      autorenew
    </mat-icon>

    <div *grecoTableCol="'Product'; let subscription">
      <p style="margin-bottom: 0" [matTooltip]="subscription.items?.length ? subscription.items[0]?.description : null">
        {{ subscription.items?.length ? subscription.items[0]?.displayName : 'Unknown Product' }}
      </p>

      <ng-container *ngIf="subscription.user.id !== subscription.subscribedById">
        <small *ngIf="subscription.user.id !== data.userId" style="font-style: italic">
          (For {{ subscription.user.displayName }})
        </small>
        <small *ngIf="subscription.subscribedById !== data.userId" style="font-style: italic">
          (Purchased By {{ subscription.subscribedBy?.displayName }})
        </small>
      </ng-container>
    </div>

    <p *grecoTableCol="'Account'; let subscription">
      {{ subscription.account.name }}
    </p>

    <p *grecoTableCol="'Created Date '; let subscription">{{ subscription.created | date : 'fullDate' }}</p>

    <p *grecoTableCol="'Commitment End Date'; let subscription">
      {{ subscription.minimumCommitmentDate | date }}
    </p>
  </greco-table>

  <div *ngIf="data.updateDefault" style="display: flex; justify-content: flex-end; gap: 8px; margin-top: 12px">
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="updateAll()">Update All</button>
    <button
      *ngIf="subscriptionsToUpdate?.length"
      mat-flat-button
      color="primary"
      [disabled]="!subscriptionsToUpdate.length"
      (click)="close()"
    >
      Update {{ subscriptionsToUpdate.length }}
    </button>
  </div>
</greco-simple-dialog>
