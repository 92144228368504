<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="!config?.hideTabs"
    [selectedIndex]="tabIndex"
    (selectedIndexChange)="tabIndex = $event; formGroup.get('name')?.updateValueAndValidity()"
  >
    <mat-tab label="Sign-In"> </mat-tab>
    <mat-tab label="Create Account">
      <a
        class="external-link"
        color="primary"
        mat-flat-button
        [href]="config?.externalRegistrationLink"
        target="_blank"
        *ngIf="tabIndex === 1 && config?.externalRegistrationLink"
        ><span>{{ config.externalRegistrationLabel || 'External Registration' }}</span> <mat-icon>launch</mat-icon></a
      >
      <div
        style="display: flex; flex-direction: column; margin-top: 16px"
        *ngIf="!(tabIndex === 1 && config?.externalRegistrationLink)"
      >
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            matInput
            placeholder="First Name"
            [formControl]="$any(formGroup.get('firstName'))"
            [errorStateMatcher]="matcher"
            autocomplete="firstName"
          />
          <mat-error align="end" *ngIf="formGroup.get('firstName')?.hasError('required')">
            {{ formGroup.get('firstName')?.errors?.required }}!
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            placeholder="Last Name"
            [formControl]="$any(formGroup.get('lastName'))"
            [errorStateMatcher]="matcher"
            autocomplete="lastName"
          />
          <mat-error align="end" *ngIf="formGroup.get('lastName')?.hasError('required')">
            {{ formGroup.get('lastName')?.errors?.required }}!
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="config?.collectPhoneNumber">
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              placeholder="Phone Number"
              [formControl]="$any(formGroup.get('phoneNumber'))"
              [errorStateMatcher]="matcher"
              type="tel"
              mask="(000) 000-0000"
              formControlName="phoneNumber"
            />
            <mat-error align="end" *ngIf="formGroup.get('phoneNumber')?.hasError('required')">
              {{ formGroup.get('phoneNumber')?.errors?.required }}!
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div
    style="display: flex; flex-direction: column"
    [style.margin-top]="tabIndex === 0 ? '16px' : ''"
    *ngIf="!(tabIndex === 1 && config?.externalRegistrationLink)"
  >
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        cdkFocusInitial
        matInput
        placeholder="Email"
        [formControl]="$any(formGroup.get('email'))"
        [errorStateMatcher]="matcher"
        autocomplete="email"
      />
      <mat-error
        align="end"
        *ngIf="formGroup.get('email')?.hasError('email') && !formGroup.get('email')?.hasError('required')"
      >
        Please enter a valid email address
      </mat-error>
      <mat-error align="end" *ngIf="formGroup.get('email')?.hasError('required')">
        Email is <strong>required</strong>!
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        [type]="passwordVisible ? 'text' : 'password'"
        [formControl]="$any(formGroup.get('password'))"
        placeholder="Password"
        [autocomplete]="tabIndex === 0 ? 'current-password' : 'new-password'"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="passwordVisible = !passwordVisible"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="passwordVisible"
      >
        <mat-icon>{{ passwordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>

      <mat-error
        align="end"
        *ngIf="formGroup.get('password')?.errors && !formGroup.get('password')?.hasError('required')"
      >
        Password must be at least 6 characters.
        <a class="clickable" style="display: inline-flex" (click)="resetPassword()">
          Reset <mat-icon>refresh</mat-icon>
        </a>
      </mat-error>

      <mat-error align="end" *ngIf="formGroup.get('password')?.hasError('required')">
        Password is <strong>required</strong>!
        <a class="clickable" style="display: inline-flex" (click)="resetPassword()">
          Reset <mat-icon>refresh</mat-icon>
        </a>
      </mat-error>

      <mat-hint *ngIf="tabIndex === 0" align="end">
        <a class="clickable" style="display: flex" (click)="resetPassword()">
          Forgot Password <mat-icon style="margin-left: 8px; line-height: 1rem">refresh</mat-icon>
        </a>
      </mat-hint>
    </mat-form-field>

    <div style="margin-top: 16px">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        style="width: 100%"
        [disabled]="
          !formGroup.valid ||
          (tabIndex === 1 && (!formGroup.value.email || !formGroup.value.firstName || !formGroup.value.lastName))
        "
      >
        {{ tabIndex === 0 ? 'Sign-In' : 'Register' }}
        <mat-icon>login</mat-icon>
      </button>
    </div>
    <div
      *ngIf="!config?.externalRegistrationLink"
      style="display: flex; flex-direction: column; gap: 8px; margin: 24px 0"
    >
      <button type="button" mat-stroked-button style="width: 100%" (click)="google()" *ngIf="tabIndex === 0">
        <img
          src="data:image/svg+xml,%3Csvg enable-background='new 0 0 533.5 544.3' version='1.1' viewBox='0 0 533.5 544.3' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234285F4;%7D .st1%7Bfill:%2334A853;%7D .st2%7Bfill:%23FBBC04;%7D .st3%7Bfill:%23EA4335;%7D%0A%3C/style%3E%3Cpath class='st0' d='m533.5 278.4c0-18.5-1.5-37.1-4.7-55.3h-256.7v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z'/%3E%3Cpath class='st1' d='m272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3h-90.5v70.1c46.2 91.9 140.3 149.9 243.2 149.9z'/%3E%3Cpath class='st2' d='M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z'/%3E%3Cpath class='st3' d='m272.1 107.7c38.8-0.6 76.3 14 104.4 40.8l77.7-77.7c-49.2-46.2-114.5-71.6-182.1-70.8-102.9 0-197 58-243.2 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z'/%3E%3C/svg%3E"
          style="height: 16px; margin-right: 12px; width: auto"
          slot="start"
        />
        <span>Continue with Google</span>
      </button>
    </div>
  </div>
</form>
