<ng-container *ngIf="community$ | async as community">
  <div *grecoLet="_selectInput$ | async" style="padding: 24px 32px; display: flex; flex-direction: column; gap: 16px">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Check-in Member</mat-label>
      <input #nativeInput matInput (input)="onInputChange(nativeInput.value)" />
    </mat-form-field>

    <ng-container *ngIf="brivoCommunitySite$ | async as brivoCommunitySite">
      <ng-container *grecoLet="linkedAccessPoints$ | async as linkedAccessPoints">
        <div *ngIf="linkedAccessPoints?.length" style="display: flex; flex-direction: column; gap: 8px">
          <div style="display: flex; justify-content: space-between">
            <div class="heading">Gate</div>

            <!-- <mat-checkbox
          style="margin-top: -2px"
          color="primary"
          [checked]="syncEntries"
          (change)="toggleSyncEntries()"
        >
          <label>Sync Entries</label>
        </mat-checkbox> -->
          </div>
          <div style="display: flex; justify-content: space-between; width: 100%; gap: 8px; flex-wrap: wrap">
            <mat-form-field appearance="outline" style="flex: 1">
              <!-- <mat-label>Gate</mat-label> -->
              <mat-select [formControl]="gateControl" placeholder="Select a gate...">
                <mat-option *ngIf="gateControl.value" [value]="gateControl.value"
                  >{{ gateControl.value?.accessPointName }}</mat-option
                >
                <ng-container *ngFor="let accessPoint of linkedAccessPoints">
                  <mat-option
                    *ngIf="accessPoint.accessPointExternalId !== gateControl.value?.accessPointExternalId"
                    [value]="accessPoint"
                  >
                    {{ accessPoint.accessPointName }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <button
              mat-stroked-button
              style="margin-top: 4px; margin-bottom: 4px; width: 100%; flex: 1"
              [disabled]="!gateControl.value"
              (click)="openGate(gateControl.value?.accessPointExternalId)"
            >
              Open Gate
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf=" (canReadCheckInList$ | async)" class="sidebar">
      <div style="display: flex; justify-content: space-between">
        <div class="heading">Latest Check-Ins</div>

        <mat-checkbox style="margin-top: -2px" color="primary" [checked]="true" disabled (change)="toggleAutoCheckIn()">
          <label>Auto Check-In</label>
        </mat-checkbox>
      </div>

      <greco-station-checkin-list
        *ngIf="station"
        [stationId]="station.id"
        [communityId]="community.id"
        [autoSync]="false"
        (refreshEntries)="getMostRecentAccessPointActivity()"
      >
      </greco-station-checkin-list>
    </div>
  </div>
</ng-container>
