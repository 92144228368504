import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertsModule } from '@greco/ngx-alerts';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { CommunitiesModule } from '@greco/ngx-identity-communities';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { TabRoutesModule } from '@greco/ngx-routes-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { ProductsModule } from '@greco/ngx-sales-products';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { SubscriptionsModule } from '@greco/ngx-sales-subscriptions';
import { SecurityUtilsModule } from '@greco/ngx-security-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { TabsModule } from '@greco/ui-tabs';
import { AccountLinkingModule } from '@greco/web-account-linking';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { SwiperModule } from 'swiper/angular';
import {
  CartButtonComponent,
  CartComponent,
  CartItemsComponent,
  CartPreviewComponent,
  CartSummaryComponent,
  CollectionsGridComponent,
  CollectionSlidesComponent,
  ExpressProductCardComponent,
  ProductCardComponent,
  ProductGridComponent,
  ProductImagesComponent,
  ProductInfoComponent,
  ProductPurchaseButtonsComponent,
  ProductVariantSelectionComponent,
  ShopFiltersComponent,
  UserFinderComponent,
} from './components';
import { LinkedAccountBuyButtonComponent } from './components/linked-account-buy-button/linked-account-buy-button.component';
import { CheckoutStationPayDialog, CreateCheckoutStationDialog, UpdateCheckoutStationDialog } from './dialogs';
import { CreateQuickPurchaseDialog } from './dialogs/create-quick-purchase';
import {
  CartPage,
  CheckoutPage,
  CheckoutStationPage,
  CheckoutStationsPage,
  ProductPage,
  ProductsPage,
  ShopPage,
} from './pages';
import { UserPerksPage } from './pages/user-perks/user-perks.page';
import { CartRecordToArrayPipe, GetVariantInventoryPipe, RecurrenceLabelPipe } from './pipes';
import { CheckoutStationService, ShopService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // Material
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTabsModule,
    MatChipsModule,

    // Greco
    LetModule,
    BoxModule,
    PerksModule,
    PaymentsModule,
    JoinPipeModule,
    ProductsModule,
    TabRoutesModule,
    PurchasesModule,
    CommunitiesModule,
    CollapsibleSectionModule,
    SimpleDialogModule,
    ContactsModule,
    ProductsModule,
    TableModule,
    SubscriptionsModule,
    UsersModule,
    AccountLinkingModule,
    SmartImgModule,
    AlertsModule,
    TabsModule,
    PerksModule,
    FiltersModule,
    MatProgressBarModule,
    SecurityUtilsModule,

    // Other
    SwiperModule,
    NgPipesModule,
    SafePipeModule,
    SignaturePadModule,
  ],
  declarations: [
    //Pipes
    RecurrenceLabelPipe,
    GetVariantInventoryPipe,
    CartRecordToArrayPipe,

    // Component
    CartComponent,
    CartItemsComponent,
    CartButtonComponent,
    ShopFiltersComponent,
    ProductGridComponent,
    ProductInfoComponent,
    ProductCardComponent,
    CartSummaryComponent,
    CartPreviewComponent,
    ProductImagesComponent,
    CollectionsGridComponent,
    CollectionSlidesComponent,
    ProductPurchaseButtonsComponent,
    ProductVariantSelectionComponent,
    LinkedAccountBuyButtonComponent,
    UserFinderComponent,
    ExpressProductCardComponent,

    // Page
    CartPage,
    ShopPage,
    ProductPage,
    CheckoutPage,
    ProductsPage,
    UserPerksPage,
    CheckoutStationsPage,
    CheckoutStationPage,

    // Dialogs
    CreateQuickPurchaseDialog,
    LinkedAccountBuyButtonComponent,
    CreateCheckoutStationDialog,
    UpdateCheckoutStationDialog,
    CheckoutStationPayDialog,
  ],
  exports: [
    // Pipes
    RecurrenceLabelPipe,

    // Component
    CartButtonComponent,
    CartSummaryComponent,
    CartPreviewComponent,
    ProductCardComponent,

    // Page
    CartPage,
    ShopPage,
    ProductPage,
    ProductsPage,
    CheckoutPage,
    UserPerksPage,
    CheckoutStationsPage,
    CheckoutStationPage,

    // Dialogs
    CreateQuickPurchaseDialog,
  ],
  providers: [ShopService, CheckoutStationService],
})
export class ShopModule {}
