import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { BrivoStationAccessPointActivity } from '@greco/domain-brivo';
import { Contact } from '@greco/identity-contacts';
import { CheckIn, ExpressCheckInDto } from '@greco/stations';

@Injectable({
  providedIn: 'root',
})
export class CheckInService {
  constructor(private http: HttpClient) {}

  // @Get('paginate')
  async paginateCheckIns(
    communityId: string,
    stationId: string,
    pagination?: PaginatedQueryParams
  ): Promise<PaginatedDto<CheckIn>> {
    return await toPromise(
      this.http.get<PaginatedDto<CheckIn>>('/api/check-in/paginate', {
        params: {
          ...(communityId && { communityId }),
          ...(stationId && { stationId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async getUserCheckIns(
    userId: string,
    communityId: string,
    pagination?: PaginatedQueryParams
  ): Promise<PaginatedDto<CheckIn>> {
    return await toPromise(
      this.http.get<PaginatedDto<CheckIn>>(`/api/check-in/${userId}/check-ins`, {
        params: {
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  //@Delete()
  async deleteCheckedInUser(checkInId: string): Promise<CheckIn> {
    return await toPromise(this.http.delete<CheckIn>(`/api/check-in/${checkInId}`));
  }

  // @Post()
  async createCheckedInUser(CreateCheckInDto: CheckIn): Promise<CheckIn> {
    return await toPromise(this.http.post<CheckIn>('/api/check-in', CreateCheckInDto));
  }

  // @Get()
  async getLastCheckInByUserId(userId: string): Promise<CheckIn> {
    return await toPromise(this.http.get<CheckIn>(`/api/check-in/${userId}`));
  }

  async expressCheckIn(dto: ExpressCheckInDto) {
    return await toPromise(
      this.http.post<{
        checkIn: CheckIn | null;
        contact: Contact;
        accessPointActivity?: BrivoStationAccessPointActivity;
      } | null>(`/api/brivo/express-check-in`, dto)
    );
  }
}
