import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariant } from '@greco/sales-products';

@Pipe({ name: 'cartRecordToArray' })
export class CartRecordToArrayPipe implements PipeTransform {
  transform(cart: Record<string, { variant: ProductVariant; quantity: number }>) {
    if (!cart) return [];
    const array = [];
    for (const key in cart) {
      array.push(cart[key]);
    }
    return array;
  }
}
