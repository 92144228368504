import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CreateCheckoutStationDto, UpdateCheckoutStationDto } from '@greco/nestjs-sales-shop';
import { Product } from '@greco/sales-products';
import { CheckoutStation } from '@greco/sales-shop';

@Injectable()
export class CheckoutStationService {
  constructor(private http: HttpClient) {}
  baseUrl = '/api/checkout-stations';

  // @Get('paginate/:communityId')
  async paginateCheckoutStations(communityId: string, pagination: Partial<PaginatedQueryParams>, search?: string) {
    return await toPromise(
      this.http.get<PaginatedDto<CheckoutStation>>('/api/checkout-stations/paginate/' + communityId, {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          ...(search && { search }),
        },
      })
    );
  }

  // @Get(':stationId')
  async getCheckoutStation(stationId: string) {
    return await toPromise(this.http.get<CheckoutStation>(this.baseUrl + '/' + stationId));
  }

  // @Get(':stationId/products')
  async getStationProducts(stationId: string) {
    return await toPromise(this.http.get<Product[]>(this.baseUrl + '/' + stationId + '/products'));
  }

  // @Post(':communityId')
  async createCheckoutStation(communityId: string, dto: CreateCheckoutStationDto) {
    return await toPromise(this.http.post<CheckoutStation>(this.baseUrl + '/' + communityId, dto));
  }

  // @Patch(':stationId')
  async updateCheckoutStation(stationId: string, dto: UpdateCheckoutStationDto) {
    return await toPromise(this.http.patch<CheckoutStation>(this.baseUrl + '/' + stationId, dto));
  }
}
