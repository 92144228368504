import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import type { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { UserAgreementDto } from '@greco/community-agreements';
import { DataExport } from '@greco/data-exports';
import type {
  CreateSubscriptionDto,
  StartSubscriptionDto,
  SubscriptionCancellationDto,
  SubscriptionUpdateDto,
  SubscriptionUpdatePreview,
} from '@greco/nestjs-sales-subscriptions';
import { Purchase } from '@greco/sales-purchases';
import { Subscription, SubscriptionStatus } from '@greco/sales-subscriptions';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}
@Injectable()
export class SubscriptionsService {
  constructor(private http: HttpClient, private snacks: MatSnackBar, private matDialog: MatDialog) {}

  // @Get()
  getUpdatableUserSubscriptions(userId: string) {
    return toPromise(
      this.http.get<Subscription[]>(this.path('/updatable'), {
        params: {
          userId,
        },
      })
    );
  }

  // @Get()
  paginateSubscriptions(
    accountId: string,
    query: RequestQueryBuilder,
    actionQuery: RequestQueryBuilder,
    pagination?: PaginatedQueryParams,
    filters?: {
      variantIds?: string[];
    }
  ) {
    return toPromise(
      this.http.get<PaginatedDto<Subscription>>(this.path(), {
        params: {
          accountId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          actionQuery: actionQuery.queryObject.s || '{}',
          ...query.queryObject,
          ...filters,
        },
      })
    );
  }

  // @Get('bulk-get-valid')
  async getValidBulkSubscriptionData(subscriptions: RequestQueryBuilder, actions: RequestQueryBuilder) {
    return await toPromise(
      this.http.get(this.path(`/bulk-get-valid`), {
        params: {
          actionQuery: actions.queryObject.s || '{}',
          ...subscriptions.queryObject,
        },
      })
    );
  }

  // @Get('filtered')
  getSubscriptionsByUserAndAccount(userId?: string, accountId?: string) {
    return toPromise(
      this.http.get<Subscription[]>(this.path('filtered'), {
        params: {
          ...(userId && { userId }),
          ...(accountId && { accountId }),
        },
      })
    );
  }

  // @Get('paginatedsubscription')
  async getPaginatedSubscriptionsByUserAndAccount(
    queryBuilder: RequestQueryBuilder,
    pagination: IPaginationOptions,
    accountId?: string,
    userId?: string
  ): Promise<PaginatedDto<Subscription>> {
    return await toPromise(
      this.http.get<PaginatedDto<Subscription>>(this.path(`paginatedSubscriptions`), {
        params: {
          ...queryBuilder.queryObject,
          ...paginationQueryParams(pagination),
          ...(userId && { userId }),
          ...(accountId && { accountId }),
        },
      })
    );
  }

  // @Get(':subscriptionId')
  getSubscription(subscriptionId: string) {
    return toPromise(this.http.get<Subscription>(this.path(subscriptionId)));
  }

  // @Get(':subscriptionId/purchases')
  getSubscriptionPurchases(subscriptionId: string, pagination: PaginatedQueryParams) {
    return toPromise(
      this.http.get<PaginatedDto<Purchase>>(this.path(`${subscriptionId}/purchases`), {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(':subscriptionId/purchase-preview')
  getSubscriptionPurchasePreview(subscriptionId: string, actionId?: string) {
    return toPromise(
      this.http.get<Purchase>(this.path(`${subscriptionId}/purchase-preview`), {
        params: {
          ...(actionId && { actionId }),
        },
      })
    );
  }

  // @Get(':subscriptionId/ltv')
  getSubscriptionLtv(subscriptionId: string) {
    return toPromise(this.http.get<number>(this.path(`${subscriptionId}/ltv`)));
  }

  async setSoldBy(subscriptionId: string, soldById: string | null) {
    return await toPromise(this.http.post<Subscription>(`/api/subscriptions/${subscriptionId}/sold-by`, { soldById }));
  }

  async setReferredBy(subscriptionId: string, referredById: string) {
    return await toPromise(
      this.http.patch<Subscription>(`/api/subscriptions/${subscriptionId}/referred-by`, { referredById })
    );
  }

  async setTransferredFrom(subscriptionId: string, transferredFromId: string | null) {
    return await toPromise(
      this.http.post<Subscription>(`/api/subscriptions/${subscriptionId}/transferred-from`, { transferredFromId })
    );
  }

  async updateMinimumCommitment(subscriptionId: string, minimumCommitment: Date | null) {
    return await toPromise(
      this.http.post<Subscription>(`/api/subscriptions/${subscriptionId}/minimum-commitment`, { minimumCommitment })
    );
  }

  // @Post('export')
  async exportSubscriptions(
    queryBuilder: RequestQueryBuilder,
    actionQuery: RequestQueryBuilder,
    filters?: { accountId?: string; userId?: string; productVariantIds?: string[] }
  ) {
    return await toPromise(
      this.http.get<DataExport>('/api/subscriptions/export', {
        params: { ...filters, actionQuery: actionQuery.queryObject.s || '{}', ...queryBuilder.queryObject },
      })
    );
  }

  // @Post()
  createSubscription(dto: CreateSubscriptionDto, hash: string, data: UserAgreementDto[]) {
    return toPromise(this.http.post<Subscription>(this.path(), { dto, hash, data }));
  }

  // @Post(':subscriptionId/start')
  /** This will open a confirmation dialog if necessary and trigger a snack on success */
  async startSubscription(subscriptionId: string, dto: StartSubscriptionDto, skipConfirmation = false) {
    if (!skipConfirmation) {
      const confirmation = await toPromise(
        this.matDialog
          .open(SimpleDialog, {
            data: {
              title: 'Start Subscription',
              subtitle: dto.startDate
                ? `Are you sure you want to start the subscription on ${moment(dto.startDate).format('ll')}?`
                : 'Are you sure you want to start the subscription immediately?',
              buttons: [
                { role: 'no', label: 'Cancel' },
                { role: 'yes', label: 'Yes, start the subscription', color: 'primary' },
              ],
            } as DialogData,
          })
          .afterClosed()
      );
      if (confirmation !== 'yes') return;
    }

    const result = await toPromise(this.http.post<Subscription>(this.path(`${subscriptionId}/start`), dto));
    if (result.status === SubscriptionStatus.VOIDED) {
      this.snacks.open(
        'Something went wrong when trying to start the subscription. Please try again by creating a new subscription.',
        'Ok',
        { panelClass: 'mat-warn', duration: 6000 }
      );
    } else this.snacks.open('Subscription started!', 'Ok', { panelClass: 'mat-primary', duration: 6000 });
    return result;
  }

  // @Post(':subscriptionId/payment-method')
  updatePaymentMethod(subscriptionId: string, paymentMethodId: string) {
    return toPromise(this.http.post<Subscription>(this.path(`${subscriptionId}/payment-method`), { paymentMethodId }));
  }

  // @Post(':subscriptionId/payment-method-multiple')
  updatePaymentMethodMultiple(subscriptionIds: string[], paymentMethodId: string) {
    return toPromise(
      this.http.post<Subscription[]>(this.path(`payment-method-multiple`), { subscriptionIds, paymentMethodId })
    );
  }

  // @Post('update-all-to-default-payment-method')
  updateAllSubscriptionsToDefaultPaymentMethod(userId: string) {
    return toPromise(this.http.post<void>(this.path(`update-all-to-default-payment-method`), { userId }));
  }

  //@Put(':subscriptionId/subscribedBy')
  updateSubscribedBy(subscriptionId: string, userId: string, paymentMethodId: string) {
    return toPromise(
      this.http.put<Subscription>(this.path(`${subscriptionId}/subscribedBy`), {
        userId,
        paymentMethodId,
      })
    );
  }

  // @Post(':subscriptionId/cancel')
  cancelSubscription(subscriptionId: string, dto: SubscriptionCancellationDto) {
    return toPromise(this.http.post<Subscription>(this.path(`${subscriptionId}/cancel`), dto));
  }

  // @Post(':subscriptionId/update')
  updateSubscription(subscriptionId: string, dto: SubscriptionUpdateDto, hash: string, agreements: UserAgreementDto[]) {
    return toPromise(this.http.post<Subscription>(this.path(`${subscriptionId}/update`), { dto, hash, agreements }));
  }

  // @Post(':subscriptionId/cancel')
  previewUpdateSubscription(subscriptionId: string, dto: SubscriptionUpdateDto) {
    return toPromise(this.http.post<SubscriptionUpdatePreview>(this.path(`${subscriptionId}/preview-update`), dto));
  }

  // @Post(':subscriptionId/stop-cancellation')
  async stopCancellation(subscriptionId: string, actionId: string, skipConfirmation = false) {
    if (!skipConfirmation) {
      const confirmation = await toPromise(
        this.matDialog
          .open(SimpleDialog, {
            data: {
              title: 'Stop Scheduled Cancellation',
              subtitle: 'Are you sure you want to stop the scheduled cancellation?',
              buttons: [
                { role: 'no', label: "No, let's keep it" },
                { role: 'yes', label: 'Yes, stop the scheduled cancellation', color: 'primary' },
              ],
            } as DialogData,
          })
          .afterClosed()
      );
      if (confirmation !== 'yes') return;
    }

    const result = await toPromise(
      this.http.post<Subscription>(this.path(`${subscriptionId}/stop-cancellation/${actionId}`), null)
    );
    this.snacks.open('Subscription cancellation stopped!', 'Ok', { panelClass: 'mat-primary', duration: 6000 });
    return result;
  }

  // @Post(':subscriptionId/stop-update')
  async stopUpdate(subscriptionId: string, actionId: string, skipConfirmation = false) {
    if (!skipConfirmation) {
      const confirmation = await toPromise(
        this.matDialog
          .open(SimpleDialog, {
            data: {
              title: 'Stop Scheduled Update',
              subtitle: 'Are you sure you want to stop the scheduled update?',
              buttons: [
                { role: 'no', label: "No, let's keep it" },
                { role: 'yes', label: 'Yes, stop the scheduled update', color: 'primary' },
              ],
            } as DialogData,
          })
          .afterClosed()
      );
      if (confirmation !== 'yes') return;
    }

    const result = await toPromise(
      this.http.post<Subscription>(this.path(`${subscriptionId}/stop-update/${actionId}`), null)
    );
    this.snacks.open('Subscription update stopped!', 'Ok', { panelClass: 'mat-primary', duration: 6000 });
    return result;
  }

  //  // @Post(':subscriptionId/manual-processing-secured')
  manualProcessing(subscriptionId: string) {
    return toPromise(this.http.post<Subscription>(this.path(`${subscriptionId}/manual-processing-secured`), {}));
  }

  private path(suffix?: string) {
    return '/api/subscriptions' + (suffix && !suffix.startsWith('/') ? '/' + suffix : suffix || '');
  }
  private statsPath(suffix?: string) {
    return '/api/stats' + (suffix && !suffix.startsWith('/') ? '/' + suffix : suffix || '');
  }

  //--------------- BULK ACTIONS ----------------------//

  // @Post('/bulk-start')
  async bulkStartSubscription(
    accountId: string,
    filters: { subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder },
    dto: StartSubscriptionDto
  ) {
    return await toPromise(
      this.http.post(this.path(`/bulk-start`), dto, {
        params: {
          accountId,
          actionQuery: filters.actions.queryObject.s || '{}',
          ...filters.subscriptions.queryObject,
        },
      })
    );
  }

  // @Post('/bulk-cancel')
  async bulkCancelSubscription(
    accountId: string,
    filters: { subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder },
    dto: SubscriptionCancellationDto
  ) {
    return await toPromise(
      this.http.post(this.path(`/bulk-cancel`), dto, {
        params: {
          accountId,
          actionQuery: filters.actions.queryObject.s || '{}',
          ...filters.subscriptions.queryObject,
        },
      })
    );
  }

  // @Post('/bulk-update')
  async bulkUpdateSubscription(
    accountId: string,
    filters: { subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder },
    dto: SubscriptionUpdateDto
  ) {
    return await toPromise(
      this.http.post(this.path(`/bulk-update`), dto, {
        params: {
          accountId,
          actionQuery: filters.actions.queryObject.s || '{}',
          ...filters.subscriptions.queryObject,
        },
      })
    );
  }

  // @Post('/bulk-stop-cancellation')
  async bulkStopCancellation(
    accountId: string,
    filters: { subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder }
  ) {
    return await toPromise(
      this.http.post(this.path(`/bulk-stop-cancellation`), null, {
        params: {
          accountId,
          actionQuery: filters.actions.queryObject.s || '{}',
          ...filters.subscriptions.queryObject,
        },
      })
    );
  }

  // @Post('/bulk-stop-update')
  async bulkstopUpdateSubscription(
    accountId: string,
    filters: { subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder }
  ) {
    return toPromise(
      this.http.post(this.path(`/bulk-stop-update`), null, {
        params: {
          accountId,
          actionQuery: filters.actions.queryObject.s || '{}',
          ...filters.subscriptions.queryObject,
        },
      })
    );
  }
}
