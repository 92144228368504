import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EventTemplate,
  EventTemplateResourceAssignment,
  EventTemplateSecurityResource,
  EventTemplateSecurityResourceAction,
  ResourceType,
  TypeformBookingRequirement,
} from '@greco/booking-events';
import { TypeformRequirementDto, UpdateEventTemplateDetailsDto } from '@greco/nestjs-booking-events';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventTemplateService } from '../../services';
import { EventTemplateDetails } from '../event-templates-input/event-templates-input.component';

@Component({
  selector: 'greco-event-templates-details',
  templateUrl: './event-templates-details.component.html',
  styleUrls: ['./event-templates-details.component.scss'],
})
export class EventTemplatesDetailsComponent {
  constructor(
    private formBuilder: FormBuilder,
    private eventTemplatesSvc: EventTemplateService,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService
  ) {}

  private _eventTemplate$ = new BehaviorSubject<EventTemplate | null>(null);
  @Input() set eventTemplate(eventTemplate) {
    this._eventTemplate$.next(eventTemplate);
    setTimeout(() => {
      this.resetDetails();
    });
  }
  get eventTemplate() {
    return this._eventTemplate$.value;
  }

  saving = false;
  template: EventTemplate | null = null;

  canUpdate$ = this._eventTemplate$.pipe(
    switchMap(async template => {
      this.template = template;
      return template
        ? await this.comSecSvc.hasAccess(
            template.communityId,
            EventTemplateSecurityResource.key,
            EventTemplateSecurityResourceAction.UPDATE
          )
        : false;
    })
  );

  resetDetailsValue: EventTemplateDetails = {
    color: '',
    title: '',
    titleLocked: false,
    imageUrl: null,
    imageUrlLocked: false,
    colorLocked: false,
    description: '',
    descriptionLocked: false,
    tags: [],
    tagsLocked: false,
    resourceTags: [],
    resourceAssignments: [],
    autoAssign: false,
    resourcesLocked: false,
    enableUserSpotBooking: false,
    enableUserSpotBookingLocked: false,
    duration: null,
    durationLocked: false,
    checkInWindow: null,
    checkInWindowLocked: false,
    maxCapacity: null,
    maxCapacityLocked: false,
    private: false,
    privateLocked: false,
    typeform: [],
    typeformLocked: false,
    zoomMeetingId: '',
    equipmentTitle: '',
    equipmentOptions: [],
    equipmentLocked: false,
  };

  detailsForm = this.formBuilder.group({ eventTemplateDetails: [null] });

  saveDetails = async () => {
    this.saving = true;

    try {
      const eventTemplateId = this.eventTemplate?.id;
      if (!eventTemplateId) return;

      const detailsData: EventTemplateDetails = this.detailsForm.value.eventTemplateDetails;
      const data: UpdateEventTemplateDetailsDto = {
        titleLocked: detailsData.titleLocked,
        imageUrlLocked: detailsData.imageUrlLocked,
        colorLocked: detailsData.colorLocked,
        descriptionLocked: detailsData.descriptionLocked,
        durationLocked: detailsData.durationLocked,
        maxCapacityLocked: detailsData.maxCapacityLocked,
        checkInWindowLocked: detailsData.checkInWindowLocked,
        privateLocked: detailsData.privateLocked,
        tagsLocked: detailsData.tagsLocked,
        resourcesLocked: detailsData.resourcesLocked,
        typeformLocked: detailsData.typeformLocked,
        enableUserSpotBookingLocked: detailsData.enableUserSpotBookingLocked,
        equipmentLocked: detailsData.equipmentLocked,
      };

      if (typeof detailsData.color === 'string') data.color = detailsData.color;
      if (typeof detailsData.title === 'string') data.title = detailsData.title;
      if (typeof detailsData.imageUrl !== null) data.imageUrl = detailsData.imageUrl as any;
      if (typeof detailsData.description === 'string') data.description = detailsData.description;
      if (typeof detailsData.duration === 'number') data.duration = detailsData.duration;
      if (typeof detailsData.maxCapacity === 'number') data.maxCapacity = detailsData.maxCapacity;
      if (typeof detailsData.checkInWindow === 'number') data.checkInWindow = detailsData.checkInWindow;
      if (typeof detailsData.private === 'boolean') data.private = detailsData.private;
      if (typeof detailsData.enableUserSpotBooking === 'boolean')
        data.enableUserSpotBooking = detailsData.enableUserSpotBooking;
      if (Array.isArray(detailsData.resourceTags)) data.resourceTagIds = detailsData.resourceTags.map(t => t.id);
      if (typeof detailsData.equipmentTitle === 'string') data.equipmentTitle = detailsData.equipmentTitle;
      if (Array.isArray(detailsData.equipmentOptions)) data.equipmentOptions = detailsData.equipmentOptions;

      let resourceAssignments: EventTemplateResourceAssignment[] = [];
      let zoomMeetingId = '';
      if (detailsData?.resourceAssignments?.length) resourceAssignments = detailsData.resourceAssignments;

      // Add/remove zoom assignment
      if (detailsData?.zoomAssignment) {
        if (!detailsData.zoomAssignment?.id) {
          const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ZOOM);
          if (index !== -1) resourceAssignments.splice(index, 1);
          resourceAssignments.push(detailsData.zoomAssignment);
          zoomMeetingId = detailsData.zoomAssignment.resourceId || '';
        }
      } else {
        const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ZOOM);
        if (index !== -1) resourceAssignments.splice(index, 1);
        if (!zoomMeetingId) {
          if (typeof detailsData.zoomMeetingId === 'string') data.zoomMeetingId = detailsData.zoomMeetingId;
        }
      }

      // Add/remove room assignment
      if (detailsData?.roomAssignment) {
        if (!detailsData.roomAssignment?.id) {
          const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ROOM);
          if (index !== -1) resourceAssignments.splice(index, 1);
          resourceAssignments.push(detailsData.roomAssignment);
        }
      } else {
        const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ROOM);
        if (index !== -1) resourceAssignments.splice(index, 1);
      }

      data.resourceAssignments = resourceAssignments;
      data.zoomMeetingId = zoomMeetingId;
      if (typeof detailsData.autoAssign === 'boolean') data.autoAssign = detailsData.autoAssign;

      if (Array.isArray(detailsData.tags)) {
        data.tags = detailsData.tags.map(tag => {
          return {
            ...tag,
            communityId: this.template?.community?.id || '',
          };
        });
      }

      if (Array.isArray(detailsData.typeform)) {
        data.typeform = detailsData.typeform.map(({ id, reusable, required }) => ({ formId: id, reusable, required }));

        data.typeform = data.typeform.reduce((acc: TypeformRequirementDto[], req) => {
          if (!acc.some(item => item.formId === req.formId)) {
            acc.push(req);
          }
          return acc;
        }, []);
      }

      this.eventTemplate = await this.eventTemplatesSvc.updateEventTemplateDetails(eventTemplateId, data as any);
      this.resetDetails();

      this.snacks.open('Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.saving = false;
  };

  resetDetails() {
    this.resetDetailsValue = {
      color: this.eventTemplate?.color || null,
      title: this.eventTemplate?.title || null,
      titleLocked: this.eventTemplate?.titleLocked || false,
      imageUrl: this.eventTemplate?.imageUrl || null,
      imageUrlLocked: this.eventTemplate?.imageUrlLocked || false,
      colorLocked: this.eventTemplate?.colorLocked || false,
      description: this.eventTemplate?.description || null,
      descriptionLocked: this.eventTemplate?.descriptionLocked || false,
      tags: this.eventTemplate?.tags || null,
      tagsLocked: this.eventTemplate?.tagsLocked || false,
      resourceTags: this.eventTemplate?.resourceTags || null,
      resourceAssignments: this.eventTemplate?.resourceAssignments || null,
      autoAssign: this.eventTemplate?.autoAssign || false,
      resourcesLocked: this.eventTemplate?.resourcesLocked || false,
      enableUserSpotBooking: this.eventTemplate?.enableUserSpotBooking || false,
      enableUserSpotBookingLocked: this.eventTemplate?.enableUserSpotBookingLocked || false,
      duration: this.eventTemplate?.duration || null,
      durationLocked: this.eventTemplate?.durationLocked || false,
      checkInWindow: this.eventTemplate?.checkInWindow ?? null,
      checkInWindowLocked: this.eventTemplate?.checkInWindowLocked || false,
      maxCapacity: this.eventTemplate?.maxCapacity,
      maxCapacityLocked: this.eventTemplate?.maxCapacityLocked || false,
      private: this.eventTemplate?.private || false,
      privateLocked: this.eventTemplate?.privateLocked || false,
      typeform: (
        (this.eventTemplate?.requirements || []).filter(req => req.type === 'typeform') as TypeformBookingRequirement[]
      ).map(({ form, reusable, required }) => ({ ...form, reusable, required })),
      typeformLocked: this.eventTemplate?.requirementsLocked || false,
      roomAssignment:
        this.eventTemplate?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM) ||
        null,
      zoomAssignment:
        this.eventTemplate?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ZOOM) ||
        null,
      zoomMeetingId: this.eventTemplate?.zoomMeetingId || null,

      equipmentTitle: this.eventTemplate?.equipmentTitle || null,
      equipmentOptions: this.eventTemplate?.equipmentOptions || [],
      equipmentLocked: this.eventTemplate?.equipmentLocked || false,
    };

    this.detailsForm.reset({ eventTemplateDetails: this.resetDetailsValue });
    this.detailsForm.markAsPristine();

    if (this.eventTemplate) this.detailsForm.enable();
    else this.detailsForm.disable();
  }
}
