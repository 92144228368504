<ng-container *ngIf="canReadInventories$ | async">
  <ng-container *grecoLet="canManageInventories$ | async as canManageInventories">
    <greco-filter-bar
      [hideSeparator]="true"
      [filterOptions]="filterOptions"
      [saleCategories]="true"
      (changed)="filters$.next($event[1]); onFilterApplied()"
    >
      <greco-sale-category-picker
        saleCategoryPicker
        #saleCategoryPicker
        [accountId]="account.id"
        (loaded)="selectedCategories$.next($event)"
        (categoriesChanged)="selectedCategories$.next($event)"
        (showUncategorizedChanged)="showUncategorized$.next($event)"
      ></greco-sale-category-picker>

      <button *ngIf="communityId" mat-menu-item (click)="exportInventories(communityId)">
        <mat-icon>file_download</mat-icon>
        <span>Export Inventories</span>
      </button>
    </greco-filter-bar>

    <greco-table
      [data]="(products$ | async) || []"
      [highlight]="true"
      [loading]="loading"
      (rowClick)="openProduct($event)"
    >
      <mat-icon *grecoTableCol="''; fitContent: true; let product" [matTooltip]="product.id">inventory_2</mat-icon>

      <p *grecoTableCol="'Product'; fitContent: true; let product" [matTooltip]="product.description">
        {{product.title}}
      </p>
      <p *grecoTableCol="'Sales Category'; fitContent:true; let product" [matTooltip]="product.saleCategoryId">
        {{ product.saleCategory?.label || '-' }}
      </p>

      <mat-chip-list *grecoTableCol="'Variants'; let product">
        <ng-container *ngIf="product?.inventoryAddon?.inventories?.length; else notConfigured">
          <ng-container *ngFor="let inventory of product?.inventoryAddon?.inventories; let index = index">
            <mat-chip
              *ngIf="index < 4 || product?.inventoryAddon?.inventories.length < 6"
              (click)="openProduct(product)"
            >
              <span>{{ '(' + inventory.availableInventoryCount + ') ' + (inventory.variant | variantTitle) }}</span>
            </mat-chip>
            <mat-chip
              *ngIf="index === 4 && product?.inventoryAddon?.inventories.length > 5"
              (click)="openProduct(product)"
            >
              <span>+{{ product?.inventoryAddon?.inventories.length - 4 }} more</span>
            </mat-chip>
          </ng-container>
        </ng-container>
        <ng-template #notConfigured>
          <mat-chip>
            <span>Not Configured ({{product.variants?.length || 0}} variants)</span>
          </mat-chip>
        </ng-template>
      </mat-chip-list>

      <mat-chip-list *grecoTableCol="'Product Status'; fitContent: true; let product">
        <mat-chip> {{ product.status | titlecase }} </mat-chip>
      </mat-chip-list>

      <ng-container *grecoTableCol="'Enabled'; fitContent: true; alignCenter: true; let product">
        <ng-container *ngIf="product.inventoryAddon?.id;">
          <mat-icon [color]="product.inventoryAddon?.enabled ? 'success' : 'warn'">
            {{product.inventoryAddon?.enabled ? 'check_circle' : 'remove_circle_outline'}}
          </mat-icon>
        </ng-container>
      </ng-container>

      <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let product">
        <button
          *ngIf="!!product.variants?.length && (canManageInventories || product.inventoryAddon?.id)"
          [matMenuTriggerFor]="menu"
          mat-icon-button
          (click)="$event.stopImmediatePropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <ng-container *ngIf="canManageInventories">
            <ng-container *ngIf="product.inventoryAddon?.id; else notConfigured">
              <button
                *ngIf="product.inventoryAddon?.enabled"
                mat-menu-item
                color="primary"
                (click)="updateAddonStatus(product.id, product.inventoryAddon.id, false)"
              >
                <mat-icon>disabled</mat-icon>
                Disable
              </button>

              <button
                *ngIf="!product.inventoryAddon?.enabled"
                mat-menu-item
                color="primary"
                (click)="updateAddonStatus(product.id, product.inventoryAddon.id, true)"
              >
                <mat-icon>check_circle</mat-icon>
                Enable
              </button>
              <button mat-menu-item color="primary" (click)="updateAddon(product, product.inventoryAddon)">
                <mat-icon>settings</mat-icon>
                Configure
              </button>
            </ng-container>
            <ng-template #notConfigured>
              <button
                *ngIf="!product.inventoryAddon?.id"
                mat-menu-item
                color="primary"
                (click)="configureAddon(product)"
              >
                <mat-icon>settings</mat-icon>
                Configure
              </button>
            </ng-template>
          </ng-container>

          <!-- View Details -->
          <button *ngIf="product.inventoryAddon?.id" mat-menu-item color="primary" (click)="openProduct(product)">
            <mat-icon>open_in_new</mat-icon>
            View Details
          </button>
        </mat-menu>
      </ng-container>
    </greco-table>

    <mat-paginator
      *ngIf="pagination?.totalItems"
      showFirstLastButtons
      [pageSizeOptions]="[10, 20, 50]"
      [length]="pagination!.totalItems || 0"
      [pageSize]="pagination!.itemsPerPage || 10"
      (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </ng-container>
</ng-container>
