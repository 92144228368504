<greco-table [data]="(subscriptions$ | async) || []" [highlight]="true" (rowClick)="rowClick.next($event)">
  <mat-icon *grecoTableCol="''; fitContent: true; let subscription" [matTooltip]="subscription.id">autorenew</mat-icon>
  <div *grecoTableCol="'Product'; let subscription">
    <p style="margin-bottom: 0" [matTooltip]="subscription.items?.length ? subscription.items[0]?.description : null">
      {{ subscription.items?.length ? subscription.items[0]?.displayName : 'Unknown Product' }}
    </p>
    <ng-container *ngIf="subscription.user.id !== subscription.subscribedById">
      <small style="font-style: italic" *ngIf="subscription.user.id !== user?.id">
        (For {{ subscription.user.displayName }})
      </small>
      <small style="font-style: italic" *ngIf="subscription.subscribedById !== user?.id">
        (Purchased By {{ subscription.subscribedBy?.displayName }})
      </small>
    </ng-container>
  </div>

  <ng-container *ngIf="showAccountColumn">
    <p *grecoTableCol="'Account'; let subscription">
      {{ subscription.account.name }}
    </p>
  </ng-container>

  <p *grecoTableCol="'Next Billing Cycle'; let subscription">{{ subscription | nextRenewal | async }}</p>

  <mat-chip *grecoTableCol="'Status '; let subscription">
    <mat-icon
      *ngIf="cancelling.includes(subscription.id)"
      style="margin-right: 8px; font-size: 16px; width: 16px; height: 16px"
      class="spin"
    >
      loop
    </mat-icon>
    <span>
      {{
        (subscription | nextRenewal | async | test : '^Cancelling')
          ? 'Cancelling'
          : subscription.status === 'ACTIVE' && (!subscription.startDate || subscription.startDate.getTime() > now)
          ? 'Not Started'
          : (subscription.status | titlecase)
      }}
    </span>
  </mat-chip>

  <p *grecoTableCol="'Created Date '; let subscription">{{ subscription.created | date : 'fullDate' }}</p>

  <p *grecoTableCol="'Commitment End Date'; let subscription">
    {{ subscription.minimumCommitmentDate | date }}
  </p>

  <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let subscription">
    <div style="display: flex; align-items: center; gap: 8px">
      <button
        style="height: 32px"
        *ngIf="
          subscription.status === 'ACTIVE' &&
          (showCancellationOptions$ | async) === false &&
          !subscription.account.name.toLowerCase().includes('altea')
        "
        mat-stroked-button
        color="primary"
        (click)="$event.stopImmediatePropagation(); getTypeform(subscription)"
      >
        Cancel
      </button>
      <button
        *ngIf="subscription.status === 'ACTIVE'"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        #menuTrigger="matMenuTrigger"
        (click)="$event.stopImmediatePropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #menu>
      <button
        mat-menu-item
        *ngIf="subscription.status === 'ACTIVE'"
        (click)="changePaymentMethodDialog(subscription, user)"
      >
        <mat-icon>payment</mat-icon>
        <span>Change Payment Method</span>
      </button>

      <ng-container *ngIf="subscription.status === 'ACTIVE' && (showCancellationOptions$ | async)">
        <ng-container *grecoLet="cancelling.includes(subscription.id) as alreadyCancelling">
          <button mat-menu-item [disabled]="alreadyCancelling" (click)="cancel(subscription)">
            <mat-icon [class.spin]="alreadyCancelling">{{ alreadyCancelling ? 'loop' : 'cancel' }}</mat-icon>
            <span>Cancel</span>
          </button>
        </ng-container>
      </ng-container>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="paginationMeta?.totalItems"
  showFirstLastButtons
  [length]="paginationMeta!.totalItems || 0"
  [pageSize]="paginationMeta!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
