import { Pipe, PipeTransform } from '@angular/core';
import { AlertLog, AlertLogPriority } from '@greco/domain-alert-logs';

@Pipe({ name: 'alertIcon' })
export class AlertIconPipe implements PipeTransform {
  transform(value?: AlertLog): string {
    switch (value?.priority) {
      case AlertLogPriority.HIGH:
        return 'error';
      case AlertLogPriority.LOW:
        return 'warning';
      case AlertLogPriority.INFO:
      default:
        return 'info';
    }
  }
}
