import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  CalendarEvent,
  EVENT_SECURITY_RESOURCE,
  EventResourceAssignment,
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  EventSecurityResource,
  EventSecurityResourceAction,
  EventSeries,
  EventSeriesSecurityResource,
  EventSeriesSecurityResourceAction,
  EventStatus,
  EventTemplate,
  Resource,
  ResourceAssignmentStatus,
  ResourceAvailabilityStatus,
  ResourceTag,
  ResourceType,
  TypeformBookingRequirement,
} from '@greco/booking-events';
import { TypeformRequirementDto, UpdateEventDto } from '@greco/nestjs-booking-events';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { userDefaultTimezone } from '@greco/timezone';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment, { Moment } from 'moment';
import { RRule } from 'rrule';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  BusyResourcesDialog,
  ReviewInstancesDialog,
  SpecifyConfirmationsDialog,
  SpecifySubstitutionsDialog,
  UpdateSeriesFromEventDialog,
} from '../../dialogs';
import { CourseService, EventService } from '../../services';
import { EventDetails, EventDetailsInputComponent } from '../event-details-input/event-details-input.component';
import { SeriesSchedule } from '../series-schedule-input/series-schedule-input.component';

@Component({
  selector: 'greco-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent {
  constructor(
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private courseSvc: CourseService,
    private formBuilder: FormBuilder,
    private comSecSvc: CommunitySecurityService
  ) {}

  saving = false;
  cancelling = false;

  now = new Date().getTime();
  eventTemplate = {} as EventTemplate;

  availability: { [key: string]: ResourceAvailabilityStatus } = {};
  @ViewChild('details') private details?: ElementRef<EventDetailsInputComponent>;

  @Input() readonly = false;
  @Input() lockResources = false;

  @Input() forSeries = false;
  @Input() seriesId: string | null = null;
  @Input() hideCancelButton?: boolean = false;

  private _event$ = new BehaviorSubject<CalendarEvent | EventSeries | null>(null);
  @Input() set event(event) {
    this._event$.next(event);

    if (event) {
      const splitId = event.id.split('_') || [];
      if (splitId.length > 2) this.seriesId = splitId[0] + '_' + splitId[1];
    }

    this.resetDetails();
    this.resetSchedule();
  }
  get event() {
    return this._event$.value;
  }

  @Output() eventUpdated = new EventEmitter<boolean>();
  @Output() eventCancelled = new EventEmitter<boolean>();
  @Output() seriesUpdated = new EventEmitter<boolean>();

  readonly canCancel$ = this._event$.pipe(
    switchMap(async event => {
      const isActive = event?.status === EventStatus.ACTIVE;
      return (
        isActive &&
        (await this.comSecSvc.hasAccess(
          event.community.id,
          EventSecurityResource.key,
          EventSecurityResourceAction.CANCEL
        ))
      );
    })
  );

  readonly canDoubleBook$ = this._event$.pipe(
    switchMap(async event => {
      if (event?.community?.id) {
        return await this.comSecSvc.hasAccess(
          event.community.id,
          EventResourceSecurityResource.key,
          EventResourceSecurityResourceAction.DOUBLE_BOOK
        );
      } else return false;
    })
  );

  readonly canUpdateEvent$ = this._event$.pipe(
    switchMap(async event =>
      event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EVENT_SECURITY_RESOURCE,
            EventSecurityResourceAction.UPDATE
          )
        : false
    )
  );

  readonly canUpdateSeries$ = this._event$.pipe(
    switchMap(async series =>
      series
        ? await this.comSecSvc.hasAccess(
            series.community.id,
            EventSeriesSecurityResource.key,
            EventSeriesSecurityResourceAction.UPDATE
          )
        : false
    )
  );

  readonly canUpdateCustomEvent$ = this._event$.pipe(
    switchMap(async event =>
      event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EVENT_SECURITY_RESOURCE,
            EventSecurityResourceAction.UPDATE_CUSTOM
          )
        : false
    )
  );

  readonly canUpdateCustomSeries$ = this._event$.pipe(
    switchMap(async series =>
      series
        ? await this.comSecSvc.hasAccess(
            series.community.id,
            EventSeriesSecurityResource.key,
            EventSeriesSecurityResourceAction.UPDATE_CUSTOM
          )
        : false
    )
  );

  detailsForm = this.formBuilder.group({ details: [null] });
  scheduleForm = this.formBuilder.group({ schedule: [null] });

  resetDetailsValue: EventDetails = {
    startDate: null,
    timezone: userDefaultTimezone(),

    color: '',
    title: '',
    imageUrl: null,
    description: '',
    tags: [],
    resourceAssignments: [],
    resourceTags: [],
    autoAssign: false,
    enableUserSpotBooking: false,
    duration: null,
    checkInWindow: null,
    private: true,
    maxCapacity: null,
    roomAssignment: null,
    zoomMeetingId: null,
    zoomAssignment: null,
    typeform: [],
    calendarId: '',

    equipmentTitle: '',
    equipmentOptions: [],
  };

  resetScheduleValue: SeriesSchedule = {
    timezone: userDefaultTimezone(),
    startDate: null,
    endDate: null,
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    resources: {},
    availableAsCourse: false,
    availableAsInstances: false,
    courseImage: [],
  };

  saveDetails = async () => {
    this.saving = true;
    const details = this.detailsForm.value.details;

    const removePendingWithTag: string[] = [];
    const busyResources: EventResourceAssignment[] = [];
    const newConfirmedResources: EventResourceAssignment[] = [];
    const resourceStatusChangeList: EventResourceAssignment[] = [];
    const removedPendingOrConfirmedResources: EventResourceAssignment[] = [];
    const resourceAssignments = details.resourceAssignments as EventResourceAssignment[];

    resourceAssignments.forEach(assignment => {
      // Get busy resources
      if (assignment.resource && this.availability[assignment.resource.id] === ResourceAvailabilityStatus.BUSY) {
        busyResources.push(assignment);
      }

      // Get new confirmed resources
      if (
        !assignment.id &&
        assignment.resource &&
        assignment.resource.type === ResourceType.PERSON &&
        assignment.resourceStatus === ResourceAssignmentStatus.CONFIRMED
      ) {
        newConfirmedResources.push(assignment);
      }

      // Get new empty assignments
      if (!assignment.id && assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION) {
        resourceStatusChangeList.push(assignment);
      }

      // Get assignments now requesting substitution (they weren't before)
      this.event?.resourceAssignments.forEach(resourceAssignment => {
        if (
          resourceAssignment.resourceId === assignment.resourceId &&
          resourceAssignment.resourceStatus !== assignment.resourceStatus &&
          assignment?.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION
        ) {
          resourceStatusChangeList.push(assignment);
        }
      });

      // Get assignments now confirmed (they were pending before)
      this.event?.resourceAssignments.forEach(resourceAssignment => {
        if (
          resourceAssignment.id === assignment.id &&
          assignment?.resourceStatus === ResourceAssignmentStatus.CONFIRMED &&
          resourceAssignment.resourceStatus === ResourceAssignmentStatus.PENDING
        ) {
          removePendingWithTag.push(resourceAssignment?.resourceTagId || '');
        }
      });
    });

    // Get confirmed and pending assignments that were removed
    const resourceAssignmentIds = resourceAssignments.map(assignment => assignment.id);
    this.event?.resourceAssignments.forEach(resourceAssignment => {
      if (
        !resourceAssignmentIds.includes(resourceAssignment.id) &&
        (resourceAssignment.resourceStatus === ResourceAssignmentStatus.CONFIRMED ||
          resourceAssignment.resourceStatus === ResourceAssignmentStatus.PENDING)
      ) {
        removedPendingOrConfirmedResources.push(resourceAssignment);
      }
    });

    // Check if room is busy
    if (details.roomAssignment) {
      const room = details.roomAssignment as EventResourceAssignment;
      if (room.resource && this.availability[room.resource.id] === ResourceAvailabilityStatus.BUSY) {
        if (!busyResources.map(resource => resource.id).includes(room.id)) busyResources.push(room);
      }
    }

    try {
      const event = this.event;
      if (!event) return;

      const shouldContinue = await this.checkForBusyResources();
      if (!shouldContinue) return;

      let confirmationResult: string[] = [];
      if (newConfirmedResources.length) {
        const result = await toPromise(
          this.matDialog
            .open(SpecifyConfirmationsDialog, {
              data: {
                communityId: event.community.id,
                assignments: [...newConfirmedResources],
              },
            })
            .afterClosed()
        );
        confirmationResult = result.emails;
      }

      let removedPendingOrConfirmedResourcesResult: string[] = [];
      if (removedPendingOrConfirmedResources.length) {
        const result = await toPromise(
          this.matDialog
            .open(SpecifyConfirmationsDialog, {
              data: {
                communityId: event.community.id,
                title: 'A Resource Has Been Removed To This Event',
                assignments: [...removedPendingOrConfirmedResources],
              },
            })
            .afterClosed()
        );
        removedPendingOrConfirmedResourcesResult = result.emails;
      }

      if (resourceStatusChangeList.length) {
        const tagsForSubstitution = resourceStatusChangeList.reduce((acc, assignment) => {
          if (
            !acc.map(resourceTag => resourceTag.id).includes(assignment.resourceTagId || '') &&
            assignment.resourceTag
          )
            acc.push(assignment.resourceTag);
          return acc;
        }, [] as ResourceTag[]);

        const resourcesForSubstitution = resourceStatusChangeList.reduce((acc, assignment) => {
          if (!acc.map(resource => resource.id).includes(assignment.resourceId || '') && assignment.resource)
            acc.push(assignment.resource);
          return acc;
        }, [] as Resource[]);

        const substitutionResult = await toPromise(
          this.matDialog
            .open(SpecifySubstitutionsDialog, {
              data: {
                communityId: event.community.id,
                resourceTags: tagsForSubstitution,
                resources: resourcesForSubstitution,
              },
            })
            .afterClosed()
        );

        if (substitutionResult !== 'cancel')
          await this.submitEvent(event, removePendingWithTag, substitutionResult, confirmationResult);
      } else {
        await this.submitEvent(
          event,
          removePendingWithTag,
          ['no emails'],
          confirmationResult,
          removedPendingOrConfirmedResourcesResult
        );
      }
    } catch (err: any) {
      const errorMessage = 'Failed to update' + err.error?.message || '';
      console.error(err);
      this.snacks.open('' + errorMessage, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.saving = false;
  };

  saveSchedule = async () => {
    this.saving = true;
    const series = this.event as EventSeries;

    try {
      const shouldContinue = await this.checkForBusyResources();
      if (!shouldContinue) return;

      const {
        schedule: {
          startDate,
          timezone,
          endDate,
          resources,
          availableAsCourse,
          availableAsInstances,
          courseImage,
          ...days
        },
      } = this.scheduleForm.value;

      let newEndDate = endDate
        ? this.event?.endDate
          ? moment(endDate).isSame(this.event.endDate, 'day')
            ? undefined
            : endDate
          : endDate
        : null;

      newEndDate = newEndDate ? moment(newEndDate).endOf('day').toDate() : newEndDate;
      const computedRecurrence = this.computeRecurrence(days, startDate);
      const newRecurrence = series?.recurrence?.join() === computedRecurrence.join() ? undefined : computedRecurrence;

      const seriesId = this.event?.id;
      if (!seriesId) return;

      const { toCancel: instances } = await this.eventSvc.previewUpdateSeriesSchedule(seriesId, {
        recurrence: newRecurrence,
        endDate: newEndDate,
        timezone,
        availableAsCourse: availableAsCourse,
        availableAsInstances: availableAsInstances,
      });

      const result = instances.length
        ? await toPromise(this.matDialog.open(ReviewInstancesDialog, { data: { instances, series } }).afterClosed())
        : [];

      if (Array.isArray(result)) {
        await this.eventSvc.updateSeriesSchedule(seriesId, {
          recurrence: newRecurrence,
          endDate: newEndDate,
          instances: result,
          timezone,
          availableAsCourse: availableAsCourse,
          availableAsInstances: availableAsInstances,
        });
        if (courseImage?.length) {
          if (!series.imageURL || series.imageURL !== courseImage[0].name) {
            const formData = new FormData();
            formData.append('file', courseImage[0]);
            await this.courseSvc.uploadCourseImage(seriesId, formData);
          }
        } else await this.courseSvc.removeCourseImage(seriesId);
        this.snacks.open('Schedule Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { panelClass: 'mat-warn' });
    }
  };

  async resetDetails() {
    this.resetDetailsValue = {
      startDate: this.event?.startDate || null,
      timezone: this.event?.timezone || userDefaultTimezone(),

      color: this.event?.color || null,
      title: this.event?.title || null,
      imageUrl: this.event?.imageUrl || null,
      description: this.event?.description || null,
      tags: this.event?.tags || null,
      resourceAssignments: this.event?.resourceAssignments || null,
      resourceTags: this.event?.resourceTags || null,
      autoAssign: this.event?.autoAssign || false,
      enableUserSpotBooking: this.event?.enableUserSpotBooking || false,
      duration: this.event?.duration || null,
      checkInWindow: this.event?.checkInWindow ?? null,
      private: this.event?.private || false,
      maxCapacity: this.event?.maxCapacity || null,
      roomAssignment: this.event?.resourceAssignments?.find(a => a?.resource?.type === ResourceType.ROOM) || null,
      zoomAssignment: this.event?.resourceAssignments?.find(a => a?.resource?.type === ResourceType.ZOOM) || null,
      zoomMeetingId: this.event?.zoomMeetingId || null,
      typeform: (
        (this.event?.requirements || []).filter(req => req.type === 'typeform') as TypeformBookingRequirement[]
      ).map(({ form, reusable, required }) => ({ ...form, reusable, required })),
      calendarId: this.event?.calendar?.id || '',

      equipmentTitle: this.event?.equipmentTitle || '',
      equipmentOptions: this.event?.equipmentOptions || [],
    };

    this.detailsForm.reset({ details: this.resetDetailsValue });
    this.detailsForm.markAsPristine();

    if (this.event?.eventTemplate) this.eventTemplate = this.event?.eventTemplate;

    if (this.event) this.detailsForm.enable();
    else this.detailsForm.disable();
  }

  resetSchedule() {
    const schedule = this._getWeekDaySchedules();
    const series = this.event as EventSeries;
    this.resetScheduleValue = {
      ...schedule,
      endDate: series?.endDate || null,
      startDate: series?.startDate || null,
      timezone: series?.timezone || userDefaultTimezone(),
      availableAsCourse: series?.availableAsCourse || false,
      availableAsInstances: series?.availableAsInstances,
      courseImage: series?.imageURL ? [new File([], series.imageURL || '')] : [],
    };

    this.scheduleForm.reset({ schedule: this.resetScheduleValue });
    this.scheduleForm.markAsPristine();

    if (series) this.scheduleForm.enable();
    else this.scheduleForm.disable();
  }

  async cancel() {
    const event = this.event;
    if (!event) throw new Error();

    this.cancelling = true;

    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Confirm Cancellation',
          subtitle: 'Are you sure you want to cancel this event?',
          content: 'This will cancel and void all active bookings. No user will be charged.',
          buttons: [
            { label: "No, Don't Cancel", role: 'no' },
            { label: 'Yes, Cancel Event', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.eventSvc.cancelEvent(event.id);
        this.eventCancelled.emit(true);
        this.event = await this.eventSvc.getOneEvent(event.id);
        this.snacks.open('Event cancelled', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    this.cancelling = false;
  }

  host() {
    const hostUrl = this.event?.zoomMeetingId
      ? `https://zoom.us/s/${this.event.zoomMeetingId}`
      : this.event?.zoomEvent?.hostUrl;

    if (hostUrl) window.open(hostUrl, '_blank');
  }

  async submitEvent(
    event: CalendarEvent | EventSeries,
    removePendingWithTag: string[],
    substitutionEmails?: string[],
    confirmationEmails?: string[],
    removedConfirmedOrPendingResourcesEmails?: string[]
  ) {
    const details: EventDetails = this.detailsForm.value.details;
    const data: UpdateEventDto = { calendarId: details.calendarId };
    if (details.startDate) {
      if (details.startDate.getTime() != event.startDate.getTime()) {
        if (event.startDate.getTime() < Date.now()) {
          this.snacks.open('Cannot Update Start Date: Event has already completed', 'Ok', {
            duration: 2500,
            panelClass: 'mat-warn',
          });
          return;
        } else if (details.startDate.getTime() < Date.now()) {
          this.snacks.open('Cannot Update: Start Date in the past', 'Ok', {
            duration: 2500,
            panelClass: 'mat-warn',
          });
          return;
        }
      }
    }

    if (event.startDate.getTime() < Date.now() && !this.forSeries) {
      if (details.resourceAssignments !== event.resourceAssignments) {
        try {
          const dialog = this.matDialog.open(SimpleDialog, {
            data: {
              showCloseButton: false,
              title: 'Confirm Resource Update',
              subtitle: 'Are you sure you want to update the resource assignments?',
              content: 'Note: this event has already completed',
              buttons: [
                { label: "No, Don't Update", role: 'no' },
                { label: 'Yes, Update Event', role: 'yes' },
              ],
            } as DialogData,
          });

          if ((await toPromise(dialog.afterClosed())) === 'no') {
            this.snacks.open('Update Cancelled', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return;
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    if (typeof details.color === 'string') data.color = details.color;
    if (typeof details.title === 'string') data.title = details.title;
    if (typeof details.imageUrl !== null) data.imageUrl = details.imageUrl as any;
    if (typeof details.calendarId === 'string') data.calendarId = details.calendarId;
    if (details.startDate?.valueOf()) data.startDate = details.startDate;
    if (typeof details.private === 'boolean') data.private = details.private;
    if (typeof details.duration === 'number') data.duration = details.duration;
    if (typeof details.enableUserSpotBooking === 'boolean') data.enableUserSpotBooking = details.enableUserSpotBooking;
    if (Array.isArray(details.resourceTags)) data.resourceTagIds = details.resourceTags.map(t => t.id);
    if (typeof details.equipmentTitle === 'string') data.equipmentTitle = details.equipmentTitle;
    if (Array.isArray(details.equipmentOptions)) data.equipmentOptions = details.equipmentOptions;

    let resourceAssignments: EventResourceAssignment[] = [];
    if (details?.resourceAssignments?.length) resourceAssignments = details.resourceAssignments;
    if (removePendingWithTag?.length > 0) {
      resourceAssignments = resourceAssignments.filter(
        assignment =>
          !(
            assignment.resourceStatus === ResourceAssignmentStatus.PENDING &&
            removePendingWithTag.includes(assignment.resourceTagId || '')
          )
      );
    }

    // Add/remove zoom assignment
    if (details?.zoomAssignment) {
      if (!details.zoomAssignment?.id) {
        const index = resourceAssignments.findIndex(a => a?.resource?.type === ResourceType.ZOOM);
        if (index !== -1) resourceAssignments.splice(index, 1);
        resourceAssignments.push(details.zoomAssignment);
        data.zoomMeetingId = details.zoomAssignment.resourceId;
      }
    } else {
      const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ZOOM);
      if (index !== -1) resourceAssignments.splice(index, 1);
    }

    // Add/remove room assignment
    if (details?.roomAssignment) {
      if (!details.roomAssignment?.id) {
        const index = resourceAssignments.findIndex(a => a?.resource?.type === ResourceType.ROOM);
        if (index !== -1) resourceAssignments.splice(index, 1);
        resourceAssignments.push(details.roomAssignment);
        data.zoomMeetingId = details.roomAssignment.resourceId;
      }
    } else {
      const index = resourceAssignments?.findIndex(a => a?.resource?.type === ResourceType.ROOM);
      if (index !== -1) resourceAssignments.splice(index, 1);
    }

    data.resourceAssignments = resourceAssignments;
    if (typeof details.autoAssign === 'boolean') data.autoAssign = details.autoAssign;

    if (typeof details.maxCapacity === 'number') data.maxCapacity = details.maxCapacity;
    if (typeof details.description === 'string') data.description = details.description;
    if (typeof details.checkInWindow === 'number') data.checkInWindow = details.checkInWindow;
    if (typeof details.zoomMeetingId === 'string') data.zoomMeetingId = details.zoomMeetingId;
    if (Array.isArray(details.tags)) {
      data.tags = details.tags.map(tag => ({
        ...tag,
        communityId: event.community.id,
        calendarId: event.calendar?.id || '',
      }));
    }

    if (Array.isArray(details.typeform)) {
      data.typeform = details.typeform.map(({ id, reusable, required }) => ({ formId: id, reusable, required }));

      data.typeform = data.typeform.reduce((acc: TypeformRequirementDto[], req) => {
        if (!acc.some(item => item.formId === req.formId)) {
          acc.push(req);
        }
        return acc;
      }, []);
    }

    data.timezone = details.timezone || userDefaultTimezone();
    if (substitutionEmails) data.substitutionEmails = substitutionEmails;
    if (confirmationEmails) data.confirmationEmails = confirmationEmails;
    if (removedConfirmedOrPendingResourcesEmails) {
      data.removedConfirmedOrPendingResourcesEmails = removedConfirmedOrPendingResourcesEmails;
    }

    if (this.event?.eventTemplateId) data.eventTemplateId = this.event?.eventTemplateId;

    if (!this.forSeries) this.event = await this.eventSvc.updateEventDetails(event.id, data);
    else this.event = await this.eventSvc.updateSeriesDetails(event.id, data);

    this.eventUpdated.emit(true);
    this.snacks.open('Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
  }

  async checkForBusyResources() {
    const detailsData = this.detailsForm.value.details;

    const busyResources: EventResourceAssignment[] = [];
    (detailsData.resourceAssignments as EventResourceAssignment[])?.forEach(assignment => {
      if (
        assignment.resource &&
        assignment.resource?.type === ResourceType.PERSON &&
        this.availability[assignment.resource.id] === ResourceAvailabilityStatus.BUSY
      ) {
        busyResources.push(assignment);
      }
    });
    if (detailsData.roomAssignment) {
      const roomAssignment = detailsData.roomAssignment as EventResourceAssignment;
      if (
        roomAssignment.resource &&
        this.availability[roomAssignment.resource.id] === ResourceAvailabilityStatus.BUSY
      ) {
        busyResources.push(roomAssignment);
      }
    }

    if (busyResources.length) {
      const result = await toPromise(
        this.matDialog
          .open(BusyResourcesDialog, {
            data: {
              resourceAssignments: busyResources,
              canDoubleBook$: this.canDoubleBook$,
            },
          })
          .afterClosed()
      );
      if (!result?.continue) {
        this.saving = false;
        return false;
      }
    }

    return true;
  }

  computeRecurrence(days: any, startDate: Date): string[] {
    const series = this.event as EventSeries;
    const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    return weekDays.reduce(
      (acc, day) => [
        ...acc,
        ...(days[day]?.map((date: Date) => {
          let recurrence = new RRule({
            dtstart: date,
            freq: RRule.WEEKLY,
            byweekday: {
              monday: RRule.MO,
              tuesday: RRule.TU,
              wednesday: RRule.WE,
              thursday: RRule.TH,
              friday: RRule.FR,
              saturday: RRule.SA,
              sunday: RRule.SU,
            }[day],
            byhour: [moment(date).hour()],
            byminute: [moment(date).minute()],
            bysecond: [0],
          });
          const previousRecurrence = series.recurrence.find(item => {
            const rule = RRule.fromString(item);
            return (
              recurrence.options.byweekday?.join() === rule.options.byweekday.join() &&
              recurrence.options.byhour.join() === rule.options.byhour.join() &&
              recurrence.options.byminute.join() === rule.options.byminute.join()
            );
          });

          const hasDTstart = series.recurrence[0] ? series.recurrence[0].startsWith('DTSTART') : false;

          if (!previousRecurrence && hasDTstart) {
            recurrence = new RRule({
              ...recurrence.options,
              dtstart: moment(new Date()).isAfter(moment(startDate))
                ? new Date(startDate.setUTCHours(0, 4, 0))
                : new Date(startDate.setUTCHours(0, 4, 0)),
            });
          } else if (previousRecurrence) {
            recurrence = new RRule({
              ...recurrence.options,
              dtstart: new Date(RRule.fromString(previousRecurrence).options.dtstart.setUTCHours(0, 4, 0)),
            });
          }

          return recurrence.toString();
        }) || ([] as string[])),
      ],
      [] as string[]
    );
  }

  refreshAvailability(schedule: SeriesSchedule) {
    const { startDate, timezone, endDate, resources, availableAsCourse, availableAsInstances, courseImage, ...days } =
      schedule;

    if (days && startDate) {
      const computedRecurrence = this.computeRecurrence(days, startDate);
      (this.details as any)?.refreshAvailability(computedRecurrence, startDate);
    }
  }

  private _getWeekDaySchedules(): Omit<SeriesSchedule, 'startDate' | 'endDate'> {
    const series = this.event as EventSeries;
    const rruleStrings = series?.recurrence;

    const mondayTimes: RRule[] = [];
    const tuesdayTimes: RRule[] = [];
    const wednesdayTimes: RRule[] = [];
    const thursdayTimes: RRule[] = [];
    const fridayTimes: RRule[] = [];
    const saturdayTimes: RRule[] = [];
    const sundayTimes: RRule[] = [];

    const days = [mondayTimes, tuesdayTimes, wednesdayTimes, thursdayTimes, fridayTimes, saturdayTimes, sundayTimes];

    rruleStrings?.forEach(ruleString => {
      const rule = RRule.fromString(ruleString);
      const day = rule.options.byweekday[0];
      if (day >= 0 && day <= 6) days[day].push(rule);
    });

    const mondayMoments: Moment[] = [];
    const tuesdayMoments: Moment[] = [];
    const wednesdayMoments: Moment[] = [];
    const thursdayMoments: Moment[] = [];
    const fridayMoments: Moment[] = [];
    const saturdayMoments: Moment[] = [];
    const sundayMoments: Moment[] = [];

    for (let i = 0; i < days.length; i++) {
      const currentDay = {
        0: 'monday',
        1: 'tuesday',
        2: 'wednesday',
        3: 'thursday',
        4: 'friday',
        5: 'saturday',
        6: 'sunday',
      }[i];
      const dayRules = days[i];
      dayRules.forEach(rule => {
        const hour = rule.options.byhour[0];
        const minute = rule.options.byminute[0];

        let minuteStr = '';
        let hourStr = '';

        if (minute < 10) {
          minuteStr = '0' + minute.toString();
        } else {
          minuteStr = minute.toString();
        }

        if (hour < 10) {
          hourStr = '0' + hour.toString();
        } else {
          hourStr = hour.toString();
        }
        const time = `${hourStr}:${minuteStr}`;
        const checkValues = moment(rule.options.dtstart).format('HH:mm') === time;
        const dateStr = moment(series.startDate).format('YYYY-MM-DD') + ' ' + time;
        const newDate = new Date(dateStr);
        const momentInTime = checkValues ? moment(rule.options.dtstart) : moment(newDate);
        switch (currentDay) {
          case 'monday':
            mondayMoments.push(momentInTime);
            return;
          case 'tuesday':
            tuesdayMoments.push(momentInTime);
            return;
          case 'wednesday':
            wednesdayMoments.push(momentInTime);
            return;
          case 'thursday':
            thursdayMoments.push(momentInTime);
            return;
          case 'friday':
            fridayMoments.push(momentInTime);
            return;
          case 'saturday':
            saturdayMoments.push(momentInTime);
            return;
          case 'sunday':
            sundayMoments.push(momentInTime);
            return;
        }
      });
    }

    return {
      monday: mondayMoments,
      tuesday: tuesdayMoments,
      wednesday: wednesdayMoments,
      thursday: thursdayMoments,
      friday: fridayMoments,
      saturday: saturdayMoments,
      sunday: sundayMoments,
    };
  }

  async editSeries() {
    if (!this.seriesId) return;

    const series = await this.eventSvc.getOneSeries(this.seriesId);
    console.log(this.seriesId, series);
    const confirmation = await toPromise(
      this.matDialog
        .open(UpdateSeriesFromEventDialog, {
          data: { series },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
        .pipe(tap(() => this.seriesUpdated.emit(true)))
    );

    if (confirmation === 'updated') {
      if (this.event) {
        this.event = await this.eventSvc.getOneEvent(this.event.id);
        this.resetDetails();
        this.resetSchedule();
      }
    }
  }
}
