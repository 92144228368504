<greco-simple-dialog [data]="dialogData">
  <div>
    <ng-template #loading>
      <mat-spinner [diameter]="20"></mat-spinner>
    </ng-template>
  </div>

  <ng-container *grecoLet="formValue$ | async">
    <form [formGroup]="formGroup">
      <mat-vertical-stepper #stepper>
        <mat-step *ngIf="handlerForm">
          <ng-template matStepLabel>{{handlerForm.stepLabel}}</ng-template>
          <div #formContainer></div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Select Date</ng-template>
          <div style="display: flex; align-items: flex; gap: 8px; margin-top: 6px">
            <!-- Update Type  -->
            <mat-radio-group formControlName="dateRadioButton" aria-label="Start Subscription: ">
              <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
              <mat-radio-button style="margin-left: 16px" color="primary" value="endOfPeriod">
                At the end of current period
              </mat-radio-button>
              <mat-radio-button style="margin: 10px" color="primary" value="future"> Custom Date </mat-radio-button>
            </mat-radio-group>

            <!-- Update Proration -->
            <mat-checkbox
              style="margin-left: auto; align-self: center"
              color="primary"
              formControlName="prorateUpdate"
              labelPosition="before"
            >
              <small>Prorate unused to balance</small>
            </mat-checkbox>
          </div>

          <div style="display: block; font-style: italic; margin-top: 5px; margin-bottom: 5px">
            Your subscriptions update date is {{ formGroup.value.dateRadioButton === 'endOfPeriod' ? "at the end of the
            current period. " : (formGroup.value.dateSelection | date) }}
          </div>

          <!-- Update Date  -->
          <div>
            <mat-form-field
              *ngIf="formGroup.value.dateRadioButton == 'future'"
              style="margin-top: 6px"
              appearance="fill"
            >
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [min]="minimalDate"
                [max]="maximalDate"
                [matDatepicker]="picker"
                (click)="picker.open()"
                formControlName="dateSelection"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Update Details -->
          <div>
            <mat-form-field style="margin-top: 6px" appearance="outline" class="custom">
              <mat-label>Reason</mat-label>
              <mat-select required formControlName="updateDetails">
                <mat-option *ngFor="let reason of updateReasons" [value]="reason">{{ reason }}</mat-option>

                <button
                  mat-menu-item
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    width: 100%;
                  "
                  [matMenuTriggerFor]="freeze"
                >
                  Freeze
                  <mat-icon style="margin-left: auto; margin-right: 0">arrow_right</mat-icon>
                </button>
              </mat-select>
            </mat-form-field>

            <mat-menu #freeze xPosition="before">
              <mat-option *ngFor="let freezeReason of freezeReasons" [value]="freezeReason">
                {{ freezeReason }}
              </mat-option>
            </mat-menu>
          </div>

          <mat-checkbox formControlName="honorCommitment" [disabled]="!canManageMinimumCommitment">
            Schedule after minimum commitment (if applicable)
          </mat-checkbox>
        </mat-step>
      </mat-vertical-stepper>

      <div
        *ngIf="validBulkSubscriptionInfo$ | async as info; else loading;"
        style="align-items: flex; gap: 8px; margin-top: 6px; margin-bottom: 30px"
        strong
      >
        <div style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
          <mat-icon color="info" style="margin-right: 8px; size: 10px">info</mat-icon>
          The number of selected items is: <b>&nbsp; {{data?.stats?.totalItems}}</b>.
        </div>

        <div style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
          <mat-icon color="info" style="margin-right: 8px; size: 10px">info</mat-icon>
          The number of items that will be processed is:&nbsp; <b> {{$any(info)?.result}}</b>.
        </div>
      </div>
    </form>

    <!-- Confirmation -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        [disabled]="!!(!formGroup.valid || !handlerFormComponent?.instance?.form?.valid  || processing) || loading$.value"
        type="button"
        (click)="submitUpdate()"
        color="primary"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-progress-spinner
          diameter="18"
          mode="indeterminate"
          *ngIf="processing"
          style="filter: grayscale(1) opacity(0.5)"
        ></mat-progress-spinner>
      </button>
    </div>
  </ng-container>
</greco-simple-dialog>
