import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { BaseUrlToken } from '../tokens';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

  constructor(
    @Inject(BaseUrlToken) private baseUrl: string,
    private ngFireAuth: AngularFireAuth,
    private snackBar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/api')) {
      return this.ngFireAuth.idToken.pipe(
        switchMap(idToken =>
          next.handle(this.cloneWithToken(request, idToken)).pipe(
            map((val: HttpEvent<any>) => {
              // Convert the body of responses to make strings into dates
              if (val instanceof HttpResponse) {
                const body = val.body;
                this.convert(body);
              }
              return val;
            })
          )
        ),
        catchError(err => {
          if (request.headers.get('X-GrecoIgnoreErrors') !== 'true') {
            this.onError(err);
          }
          throw err;
        })
      );
    }
    return next.handle(request).pipe(shareReplay(1));
  }

  private isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) return false;
    if (typeof value === 'string') return this._isoDateFormat.test(value);
    return false;
  }

  private convert(body: any) {
    if (body === null || body === undefined) return body;
    if (typeof body !== 'object') return body;
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) body[key] = new Date(value);
      else if (typeof value === 'object') this.convert(value);
    }
  }

  private cloneWithToken(request: HttpRequest<any>, idToken: string | null) {
    const baseUrl = this.baseUrl.endsWith('/') ? this.baseUrl.substr(0, this.baseUrl.length - 1) : this.baseUrl;
    const requestUrl = request.url.startsWith('/') ? request.url : '/' + request.url;
    return request.clone({
      url: baseUrl + requestUrl,
      setHeaders: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });
  }

  private onError(error: any) {
    if (error.error.statusCode === 401 || error.error.statusCode === 403) {
      this.snackBar.open('Oops, make sure you are logged in and are allowed to perform this action.', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
    } else if (error.error.statusCode === 500) {
      this.snackBar.open(
        "Oops, we're not sure what you were trying to do there. Report this error to us so we can resolve it for you.",
        'Ok',
        {
          duration: 2500,
          panelClass: 'mat-warn',
        }
      );
    } else {
      const message = (error?.error?.statusCode ? error.error.statusCode + ': ' : '') + error?.error?.message;

      if (
        error?.statusText === 'Unknown Error' &&
        error?.status === 0 &&
        error?.message?.startsWith('Http failure response for')
      ) {
        // This is a CORS error, we can ignore it
        return;
      }

      this.snackBar.open(
        message && message !== 'undefined' ? message : 'Oops, please check your connection and try again later.',
        'Ok',
        {
          duration: 2500,
          panelClass: 'mat-warn',
        }
      );
    }
  }
}
