<form [formGroup]="_form">
  <div class="color-and-title">
    <!-- Color -->
    <div matPrefix class="color">
      <mat-form-field style="width: 48px" appearance="standard">
        <input #colorInput matInput type="color" [required]="required" [readonly]="readonly" formControlName="color" />
        <mat-icon class="color-icon">palette</mat-icon>
      </mat-form-field>
    </div>

    <!-- Title -->
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
      <mat-label>Title</mat-label>
      <input
        matInput
        type="text"
        [required]="required"
        [readonly]="readonly || (!customAccess && titleLocked)"
        formControlName="title"
      />
    </mat-form-field>
  </div>

  <!-- Event Image -->
  <mat-form-field floatLabel="always">
    <mat-label>Image</mat-label>
    <greco-image-upload
      formControlName="imageUrl"
      [required]="false"
      [multiple]="false"
      [urls]="initialImage ? [initialImage] : []"
      [disabled]="readonly || (!customAccess && imageUrlLocked)"
    ></greco-image-upload>
  </mat-form-field>

  <!-- Event Start/Timezone -->
  <div *ngIf="!forSeries" style="display: flex">
    <mat-form-field appearance="standard">
      <mat-label>Event Start</mat-label>
      <greco-datetime-timezone-input
        [required]="true"
        [disabled]="readonly || isCompleted"
        [initialStartDate]="$any(initialStartDate)"
        formControlName="eventStart"
      ></greco-datetime-timezone-input>
    </mat-form-field>
  </div>

  <!-- Extra start dates -->
  <greco-collapsible-section *ngIf="resource" [header]="{ title: 'Recurrence', small: true }" [expanded]="false">
    <p>Specify the dates when this event should occur</p>
    <p *ngIf="!_form.value.eventStart?.date" style="color: var(--warn-color)">
      <i>Please enter a start date first!</i>
    </p>
    <mat-form-field *ngFor="let day of weekDays; let dayIndex = index" appearance="standard">
      <mat-label>{{ day | titlecase }}</mat-label>
      <mat-chip-list #dayChipList>
        <mat-chip
          *ngFor="let time of getDayTimes(day)"
          [selectable]="'true'"
          [removable]="'true'"
          [disabled]="readonly"
          (removed)="removeTime(time)"
        >
          {{ time | date : 'shortTime' }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input
          #dayInput
          matInput
          [disabled]="!_form.value.eventStart?.date"
          [readonly]="readonly"
          (dateInput)="dayInput.value = ''"
          [matDatetimepicker]="timePicker"
          [matChipInputFor]="dayChipList"
          [matChipInputSeparatorKeyCodes]="[188, 13]"
          autocomplete="false"
          style="color: transparent; user-select: none; pointer-events: none"
        />
      </mat-chip-list>
      <mat-datetimepicker
        #timePicker
        type="time"
        [openOnFocus]="true"
        [disabled]="readonly"
        [timeInterval]="5"
        (selectedChanged)="addTime($event, dayIndex)"
      ></mat-datetimepicker>
      <mat-datetimepicker-toggle [disabled]="readonly" [for]="timePicker" matSuffix></mat-datetimepicker-toggle>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Number of Additional Weeks (Beyond the first)</mat-label>
      <input
        #input
        matInput
        min="0"
        step="1"
        type="number"
        placeholder="How many weeks should this event repeat?"
        [readonly]="readonly"
        (input)="setWeeksRecurring(input.value)"
      />
    </mat-form-field>
  </greco-collapsible-section>

  <!-- Resources -->
  <mat-form-field appearance="standard" *ngIf="communityId && !lockResources">
    <mat-label>Resource Assignments</mat-label>
    <greco-event-resource-assignment
      *ngIf="communityId"
      [communityId]="communityId"
      [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
      [startDate]="_form.value.eventStart?.date"
      [duration]="_form.value.duration"
      [resource]="resource"
      [resourceTag]="resourceTag"
      (resourceAvailability)="availability = $event"
      formControlName="resourceAssignments"
    ></greco-event-resource-assignment>
  </mat-form-field>

  <mat-checkbox
    *ngIf="communityId && !lockResources"
    color="primary"
    [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
    formControlName="autoAssign"
  >
    Allow auto-assignment for substitutions
  </mat-checkbox>

  <!-- Advanced Options -->
  <greco-collapsible-section #details [header]="{ title: 'Advanced Options', small: true }" [expanded]="false">
    <!-- Private Heading -->
    <div header style="height: 30px">
      <p style="margin-top: 5px">{{ privateTag ? 'Private' : 'Public' }}</p>
    </div>

    <!-- Room Resources-->
    <mat-form-field
      appearance="standard"
      *ngIf="communityId"
      [style.display]="!customAccess && resourcesLocked ? 'none' : ''"
    >
      <mat-label>Room</mat-label>
      <greco-assignment-picker
        [disabled]="readonly || (!customAccess && resourcesLocked)"
        [communityId]="communityId"
        [availability]="availability"
        [filterType]="{ type: 'ROOM', limit: 10, exclude: null }"
        (resourceChanged)="updateCapacity($any($event))"
        placeholder="Room Resource"
        formControlName="roomAssignment"
      ></greco-assignment-picker>
      <mat-checkbox
        matSuffix
        color="primary"
        formControlName="enableUserSpotBooking"
        [disabled]="lockResources || readonly || (!customAccess && enableUserSpotBookingLocked)"
        >Allow users to book spots
      </mat-checkbox>
    </mat-form-field>

    <!-- Tags -->
    <greco-tags-input
      *ngIf="communityId"
      [style.display]="!customAccess && tagsLocked ? 'none' : ''"
      [required]="required"
      [communityId]="communityId"
      [disabled]="readonly || (!customAccess && tagsLocked)"
      [tableEnabled]="true"
      formControlName="tags"
      placeholder="Tags"
    ></greco-tags-input>

    <div style="display: flex; flex-wrap: wrap; gap: 12px">
      <!-- Duration -->
      <mat-form-field class="flex-1" appearance="standard">
        <mat-label>Duration</mat-label>
        <greco-minutes-input
          [required]="required"
          [disabled]="readonly || (!customAccess && durationLocked)"
          placeholder="Duration"
          formControlName="duration"
        ></greco-minutes-input>
      </mat-form-field>

      <!-- Check-In Window -->
      <mat-form-field
        class="flex-1"
        appearance="standard"
        style="min-width: 300px"
        [style.display]="!customAccess && checkInWindowLocked ? 'none' : ''"
      >
        <mat-label>Booking Cut-Off Window</mat-label>
        <greco-minutes-input
          [required]="required"
          [disabled]="readonly || (!customAccess && checkInWindowLocked)"
          placeholder="Cut-Off Window"
          formControlName="checkInWindow"
        ></greco-minutes-input>
      </mat-form-field>

      <mat-form-field class="flex-1" appearance="standard">
        <mat-label>Attendee Capacity</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          placeholder="Max Capacity"
          formControlName="maxCapacity"
          [required]="true"
          [readonly]="readonly || (!customAccess && maxCapacityLocked)"
        />
        <mat-icon
          *ngIf="_form.value.roomAssignment && _form.value.maxCapacity > _form.value.roomAssignment?.resource.spotCount"
          matSuffix
          style="color: var(--warn-color)"
          [matTooltip]="
            'Attendee capacity is greater than room capacity (' + _form.value.roomAssignment.resource.spotCount + ')!'
          "
        >
          error_outlined
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Private -->
    <mat-form-field *ngIf="!lockResources" appearance="standard">
      <input matInput style="display: none" />
      <mat-checkbox formControlName="private" color="primary" [disabled]="readonly || (!customAccess && privateLocked)">
        Private Event
      </mat-checkbox>
    </mat-form-field>

    <!-- Resources For Zoom-->
    <mat-form-field
      appearance="standard"
      *ngIf="communityId"
      [style.display]="!customAccess && resourcesLocked ? 'none' : ''"
    >
      <mat-label>Zoom Resource</mat-label>
      <greco-assignment-picker
        [communityId]="communityId"
        [disabled]="readonly || (!customAccess && resourcesLocked)"
        formControlName="zoomAssignment"
        placeholder="Zoom Resource"
        [filterType]="{ type: 'ZOOM', limit: 1, exclude: null }"
      ></greco-assignment-picker>
    </mat-form-field>

    <!--manual Input for meetingId-->
    <mat-form-field
      appearance="standard"
      *ngIf="communityId && !_form.value.zoomAssignment"
      [style.display]="!customAccess && resourcesLocked ? 'none' : ''"
    >
      <mat-label>Zoom Meeting ID</mat-label>
      <input
        matInput
        type="text"
        [readonly]="lockResources"
        [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
        formControlName="zoomMeetingId"
        placeholder="Manually Input Zoom Meeting ID"
      />
    </mat-form-field>

    <!-- Typeform -->
    <mat-form-field appearance="standard" [style.display]="!customAccess && typeformLocked ? 'none' : ''">
      <mat-label>Typeform</mat-label>
      <greco-typeform-form-picker
        formControlName="typeform"
        [communityId]="communityId"
        [disabled]="readonly || (!customAccess && typeformLocked)"
        placeholder="Select one or multiple forms"
      ></greco-typeform-form-picker>
    </mat-form-field>

    <!-- Description -->
    <mat-form-field appearance="standard">
      <mat-label>Event Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        rows="3"
        [readonly]="readonly || (!customAccess && descriptionLocked)"
      ></textarea>
    </mat-form-field>
  </greco-collapsible-section>

  <greco-collapsible-section [header]="{ title: 'Equipment', small: true }" [expanded]="false">
    <mat-form-field appearance="standard">
      <mat-label>Equipment Title</mat-label>
      <input matInput type="text" [readonly]="readonly" formControlName="equipmentTitle" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Equipment Options</mat-label>

      <div style="display: flex">
        <mat-chip-list #equipmentOptions>
          <mat-chip *ngFor="let equipmentOption of _form.value.equipmentOptions">
            {{ equipmentOption }}
            <mat-icon *ngIf="!readonly" matChipRemove (click)="removeEquipmentOption(equipmentOption)">
              cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>

        <input
          #equipmentInput
          style="width: 100%"
          placeholder="Enter an equipment option"
          [disabled]="readonly"
          [matChipInputFor]="equipmentOptions"
          [matChipInputSeparatorKeyCodes]="[]"
        />

        <button
          *ngIf="!readonly && equipmentInput.value"
          mat-icon-button
          type="button"
          color="primary"
          style="max-height: 32px"
          matTooltip="Create equipment option"
          (click)="createEquipmentOption(equipmentInput.value); equipmentInput.value = ''"
        >
          <mat-icon>new_label</mat-icon>
        </button>
      </div>
    </mat-form-field>
  </greco-collapsible-section>

  <!-- Pre-Add Attendees -->
  <greco-collapsible-section
    *ngIf="canBook$ | async"
    [expanded]="false"
    [header]="{
      small: true,
      title: 'Pre-Add Attendees',
      badge: _form.value.preBookings.length ? '' + _form.value.preBookings.length : undefined
    }"
  >
    <mat-form-field style="width: 100%">
      <mat-label>Select Member</mat-label>
      <greco-user-picker
        [showPhoto]="true"
        [formControl]="memberToAdd"
        [disabled]="_form.value.preBookings.length >= _form.value.maxCapacity"
        placeholder="Select a member to pre-add to this event"
      ></greco-user-picker>

      <button
        *ngIf="memberToAdd.value"
        matSuffix
        mat-stroked-button
        color="primary"
        style="width: 32px; height: 32px"
        (click)="addPrebooking()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>

    <div *ngIf="_form.value.preBookings.length" class="pre-bookings">
      <p *ngIf="!(bookingOptions$ | async)?.length" [style.color]="'var(--warn-color)'">
        <em><small>No Booking Option Available!</small></em> {{ bookingOptions$ | async | json }}
      </p>

      <ng-container *grecoLet="canBookPending$ | async as canBookPending">
        <ng-container *grecoLet="canBookPendingFromSub$ | async as canBookPendingWithSub">
          <div class="booking-card" *ngFor="let booking of _form.value.preBookings; let index = index">
            <div class="card-header">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <p style="margin-bottom: 0">
                  <strong>Booking for {{ booking.user.displayName }}</strong>
                </p>

                <button mat-icon-button (click)="removePreBooking(booking)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>

            <div class="card-content">
              <greco-pre-booking-option-picker
                [user]="booking.user"
                [onlyAvailablePerks]="!canBookPending && !canBookPendingWithSub"
                [instancesToBook]="1 + (((value || {}).extraDates || []).length || 0)"
                [event]="
                  $any({
                    startDate: _form.value.eventStart?.date,
                    tags: _form.value.tags,
                    type: 'EVENT',
                    community: {
                      id: communityId
                    }
                  })
                "
                (selected)="
                  setPreBookingOption(
                    index,
                    $event,
                    ($event.allowPendingBookings && (canBookPending || canBookPendingWithSub)) || false
                  )
                "
              ></greco-pre-booking-option-picker>

              <greco-cancellation-policy-requirement
                *ngIf="booking.bookingOption && booking.bookingOption?.cancellation"
                [bookingOption]="booking.bookingOption"
                [event]="
                  $any({
                    title: _form.value.title,
                    startDate: _form.value.eventStart?.date,
                    tags: _form.value.tags,
                    type: 'EVENT',
                    community: {
                      id: communityId
                    }
                  })
                "
              ></greco-cancellation-policy-requirement>

              <mat-form-field
                *ngIf="booking.bookingOption?.price || booking.bookingOption?.cancellationPrice"
                appearance="standard"
                class="payment-method"
              >
                <greco-select-payment-method
                  #selectPaymentMethod
                  placeholder="Select a payment method"
                  [required]="true"
                  [userId]="booking.user.id"
                  [allowBankPayments]="false"
                  [value]="booking.paymentMethod"
                  [disabled]="!selectPaymentMethod.userDefaultPaymentMethod"
                  (valueChange)="setPaymentMethod(index, $event)"
                ></greco-select-payment-method>

                <greco-add-payment-method-button
                  [onlyIcon]="true"
                  [color]="undefined"
                  [userId]="booking.user.id"
                  [hiddenForms]="['Bank Account']"
                  (saved)="selectPaymentMethod.refreshPaymentMethods(); $event && setPaymentMethod(index, $event)"
                ></greco-add-payment-method-button>
              </mat-form-field>

              <p
                style="display: block; padding: 0 12px"
                [style.color]="
                  !booking.requiredPendingBookings || booking.bookingOption?.id === 'prk_complimentarybooking'
                    ? ''
                    : 'var(--warning-color)'
                "
              >
                {{
                  !booking.requiredPendingBookings || booking.bookingOption?.id === 'prk_complimentarybooking'
                    ? "Based on the member's current perk count, no pending bookings are required for this user with the selected booking option."
                    : booking.canBookPending
                    ? booking.bookPending
                      ? "Based on the member's current perk count, " +
                        booking.requiredPendingBookings +
                        ' out of ' +
                        (1 + (((value || {}).extraDates || []).length || 0)) +
                        ' bookings will remain pending until the user receives the appropriate perks. Pending bookings can be confirmed at any time before the time of the event.'
                      : "Based on the member's current perk count, " +
                        (1 + (((value || {}).extraDates || []).length || 0) - booking.requiredPendingBookings) +
                        ' out of ' +
                        (1 + (((value || {}).extraDates || []).length || 0)) +
                        ' bookings will be created for now. Additional bookings should be created manually once the user receives the appropriate perks.'
                    : "Unable to create pending bookings for this user. Based on the member's current perk count, only " +
                      (1 + (((value || {}).extraDates || []).length || 0) - booking.requiredPendingBookings) +
                      ' out of ' +
                      (1 + (((value || {}).extraDates || []).length || 0)) +
                      ' bookings will be created for now. Additional bookings should be created manually once the user receives the appropriate perks.'
                }}
              </p>
              <p
                style="display: block; padding: 0 12px"
                [style.color]="
                  !booking.requiredPendingBookings || booking.bookingOption?.id === 'prk_complimentarybooking'
                    ? ''
                    : 'var(--warning-color)'
                "
              >
                Note that bookings will not be created (pending or confirmed) if the selected booking option doesn't
                have a sufficient booking window.
              </p>
            </div>
          </div>

          <!-- <div class="pre-bookings-grid">
            <ng-container
              *ngFor="let booking of _form.value.preBookings; let index = index"
              grecoPreBooking
              #grecoPreBooking="grecoPreBooking"
              [user]="booking.user"
              [canBookPending]="canBookPending"
              [bookingOptions]="bookingOptions$ | async"
              [canBookPendingWithSub]="canBookPendingWithSub"
              [instancesToBook]="1 + (((value || {}).extraDates || []).length || 0)"
            >
              <div class="user-info">
                <div>
                  <greco-user-avatar [user]="booking.user" size="small" [showOnlineStatus]="false"></greco-user-avatar>
                </div>
                <div>
                  <p>
                    <strong>{{ booking.user.displayName }}</strong>
                  </p>
                  <p>{{ booking.user.email }}</p>
                </div>
              </div>

              <mat-form-field appearance="standard">
                <mat-label>Booking Option</mat-label>

                <mat-select
                  placeholder="Select a booking option"
                  (selectionChange)="setPreBookingOption(index, $event.value)"
                >
                  <mat-select-trigger>{{ booking.bookingOption?.title }}</mat-select-trigger>

                  <ng-container *ngFor="let opt of grecoPreBooking.data$ | async">
                    <mat-option
                      [disabled]="!!opt.requiredPendingBookings && !opt.canBookPending"
                      [value]="opt"
                      style="height: auto"
                    >
                      <div style="line-height: normal; margin: 12px 0">
                        <div class="booking-option">
                          <greco-perk-badge
                            [small]="true"
                            [matTooltip]="opt.option.id"
                            [text]="opt.option.badge.text"
                            [icon]="opt.option.badge.icon"
                            [color]="opt.option.badge.color"
                            [shape]="opt.option.badge.shape"
                          ></greco-perk-badge>
                          {{ opt.option.title }}
                        </div>
                        <div
                          *ngIf="opt.stats?.consumable || opt.stats?.reusable"
                          style="padding-left: 40px; margin-top: 4px"
                        >
                          <p style="margin: 0">
                            <strong>Available:&nbsp;</strong>
                            <span *ngIf="opt.stats?.consumable">{{ opt.stats!.consumable }}</span>
                            <span *ngIf="opt.stats?.consumable && opt.stats?.reusable">&nbsp;+&nbsp;</span>
                            <span *ngIf="opt.stats?.reusable"
                              >{{ opt.stats!.reusable }}<mat-icon>replay</mat-icon></span
                            >
                            <span *ngIf="!opt.stats?.consumable && !opt.stats?.reusable">-</span>
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <div class="pending-checkbox">
                <mat-checkbox
                  matTooltipShowDelay="450"
                  (change)="setBookPending(index, $event.checked)"
                  [checked]="booking.bookPending"
                  [matTooltipDisabled]="!booking.bookingOption"
                  [disabled]="!booking.requiredPendingBookings || !booking.canBookPending"
                  [matTooltip]="
                    !booking.requiredPendingBookings
                      ? 'No pending bookings are required for this user with the selected booking option.'
                      : booking.canBookPending
                      ? booking.bookPending
                        ? booking.requiredPendingBookings +
                          ' out of ' +
                          (1 + (((value || {}).extraDates || []).length || 0)) +
                          ' bookings will remain pending until the user receives the appropriate perks. Pending bookings can be confirmed at any time before the time of the event.'
                        : 'Only ' +
                          (1 + (((value || {}).extraDates || []).length || 0) - booking.requiredPendingBookings) +
                          ' out of ' +
                          (1 + (((value || {}).extraDates || []).length || 0)) +
                          ' bookings will be created for now. Following bookings should be created manually once the user receives the appropriate perks.'
                      : 'Unable to create pending bookings for this user. Only ' +
                        (1 + (((value || {}).extraDates || []).length || 0) - booking.requiredPendingBookings) +
                        ' out of ' +
                        (1 + (((value || {}).extraDates || []).length || 0)) +
                        ' bookings will be created for now. Following bookings should be created manually once the user receives the appropriate perks.'
                  "
                >
                  Pending bookings
                </mat-checkbox>
              </div>

              <button mat-icon-button (click)="removePreBooking(booking)">
                <mat-icon>close</mat-icon>
              </button>

              <mat-form-field
                *ngIf="booking.bookingOption?.price || booking.bookingOption?.cancellationPrice"
                appearance="standard"
                class="payment-method"
              >
                <greco-select-payment-method
                  #selectPaymentMethod
                  placeholder="Select a payment method"
                  [required]="true"
                  [userId]="booking.user.id"
                  [allowBankPayments]="false"
                  [value]="booking.paymentMethod"
                  [disabled]="!selectPaymentMethod.userDefaultPaymentMethod"
                  (valueChange)="setPaymentMethod(index, $event)"
                ></greco-select-payment-method>

                <greco-add-payment-method-button
                  [onlyIcon]="true"
                  [color]="undefined"
                  [userId]="booking.user.id"
                  [hiddenForms]="['Bank Account']"
                  (saved)="selectPaymentMethod.refreshPaymentMethods(); $event && setPaymentMethod(index, $event)"
                ></greco-add-payment-method-button>
              </mat-form-field>

              <div class="divider"></div>
            </ng-container>
          </div> -->
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>
</form>
