<greco-simple-dialog [data]="dialogData" *ngIf="station$ | async as station">
  <form [formGroup]="form">
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Sale Categories</mat-label>
      <mat-select multiple formControlName="saleCategories" [compareWith]="compareSaleCategories">
        <mat-option *ngFor="let saleCategory of saleCategories$ | async" [value]="saleCategory">
          {{ saleCategory.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="includeUncategorized">Include Uncategorized Products</mat-checkbox>
  </form>
</greco-simple-dialog>
