import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { InventoryProductAddon, ProductVariant, ProductVariantInventory } from '@greco/sales-products';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-express-product-card',
  templateUrl: './express-product-card.component.html',
  styleUrls: ['./express-product-card.component.scss'],
})
export class ExpressProductCardComponent {
  @PropertyListener('variant') variant$ = new BehaviorSubject<ProductVariant | null>(null);
  @Input() variant!: ProductVariant;
  @Input() inventoryAddon?: InventoryProductAddon;
  @Input() variantInventory?: ProductVariantInventory;

  @PropertyListener('cart') cart$ = new BehaviorSubject<{ variant: ProductVariant; quantity: number }[]>([]);
  @Input() cart: { variant: ProductVariant; quantity: number }[] = [];

  @Output() productClicked = new EventEmitter<ProductVariant>();
  @Output() addOne = new EventEmitter<ProductVariant>();
  @Output() removeOne = new EventEmitter<ProductVariant>();

  isSelected$ = combineLatest([this.variant$, this.cart$]).pipe(
    map(([variant, cart]) => cart?.some(item => item.variant.id === variant?.id))
  );

  addToCart() {
    this.productClicked.emit();
  }
}
