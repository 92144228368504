import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { SubscriptionUpdateDto } from '@greco/nestjs-sales-subscriptions';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';

import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import moment from 'moment';
import { SubscriptionsService } from '../../services';
import {
  SUBSCRIPTION_HANDLER_FORMS,
  UpdateSubscriptionHandlerForm,
  UpdateSubscriptionHandlerFormComponent,
} from '../update-subscription/update-subcsription-handler-form';

@Component({
  selector: 'greco-fit-bulk-update-subscription-dialog',
  templateUrl: './bulk-update-subscription.dialog.html',
  styleUrls: ['./bulk-update-subscription.dialog.scss'],
})
export class BulkUpdateSubscriptionDialog implements OnInit, AfterViewInit {
  @PropertyListener('data') private data$ = new ReplaySubject();
  @Input() public data: any;

  constructor(
    @Inject(SUBSCRIPTION_HANDLER_FORMS) private forms: UpdateSubscriptionHandlerForm[],
    private dialogRef: MatDialogRef<BulkUpdateSubscriptionDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly matDialogData: any,
    private factoryResolver: ComponentFactoryResolver,
    private subscriptionSvc: SubscriptionsService,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private fb: FormBuilder,
    private securitySvc: SecurityService
  ) {
    this.data = { ...this.matDialogData };
    this.handlerForm = this.forms[0];
  }

  readonly updateReasons = [
    'Admin Error',
    'Add On',
    'Add On Price Change',
    'Downgrade',
    'Product Change',
    'Price Change',
    'Complimentary Product',
    'Unfreeze',
    'Update to Promotion',
    'Upgrade',
    'Wrong subscription sold',
  ];

  readonly freezeReasons = [
    'Freeze (Financial)',
    'Freeze (Medical)',
    'Freeze (Non-Usage)',
    'Freeze (Other)',
    'Freeze (Vacation)',
    'Freeze (Health)',
    'Freeze (Work)',
  ];

  communityId?: string;

  handlerForm?: UpdateSubscriptionHandlerForm;
  handlerFormFactory?: ComponentFactory<UpdateSubscriptionHandlerFormComponent>;
  handlerFormComponent?: ComponentRef<UpdateSubscriptionHandlerFormComponent>;

  @ViewChild('formContainer', { read: ViewContainerRef }) formContainer?: ViewContainerRef;

  dialogData: DialogData = {
    title: 'Bulk Update Subscriptions',
    subtitle: 'When should the subscriptions be updated?',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  formGroup = this.fb.group({
    dateSelection: [moment().toDate(), Validators.required],
    dateRadioButton: ['now'],
    prorateUpdate: [true],
    updateDetails: [null, Validators.required],
    honorCommitment: [true],
  });

  canProrate = true;

  canManageMinimumCommitment = false;

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  processing = false;

  dto!: SubscriptionUpdateDto | null;

  formValue$?: Observable<SubscriptionUpdateDto | null>;

  loading$ = new BehaviorSubject<boolean>(true);

  validBulkSubscriptionInfo$ = this.data$.pipe(
    tap(() => this.loading$.next(true)),
    switchMap(async (data: any) => {
      return await this.subscriptionSvc.getValidBulkSubscriptionData(
        data?.filters?.subscriptions,
        data?.filters?.actions
      );
    }),
    tap(() => {
      this.loading$.next(false);
    })
  );

  async ngOnInit() {
    this.canManageMinimumCommitment = await this.securitySvc.hasAccess(
      SubscriptionResource.key,
      SubscriptionResourceAction.MANAGE_MINIMUM_COMMITMENT,
      {},
      true
    );
  }

  ngAfterViewInit(): void {
    if (this.handlerForm && this.formContainer) {
      this.handlerFormFactory = this.factoryResolver.resolveComponentFactory(this.handlerForm.formComponent);
      this.handlerFormComponent = this.formContainer.createComponent(this.handlerFormFactory);
      this.handlerFormComponent.instance.subscription = this.data;
      this.handlerFormComponent.instance.mode = 'staff';
    }

    this.formValue$ = combineLatest([
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.handlerFormComponent?.instance.form.valueChanges.pipe(
        startWith(this.handlerFormComponent?.instance.form.value)
      ),
    ]).pipe(
      switchMap(async () => {
        if (this.formGroup.value.dateRadioButton === 'now') this.formGroup.value.dateSelection = moment().toDate();
        this.dto = await this.generateUpdateDto();
        return this.dto;
      })
    );
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async generateUpdateDto(): Promise<SubscriptionUpdateDto | null> {
    if (this.formGroup.valid && this.handlerFormComponent?.instance.form.valid) {
      const currentUserId: string | undefined = await toPromise(this.userSvc.getUserId());

      const updateDto: SubscriptionUpdateDto = {
        proration: false,
        endOfPeriod: false,
        createdById: currentUserId,
        details: this.formGroup.value.updateDetails,
      };

      if (this.canProrate) {
        updateDto.proration = this.formGroup.value.prorateUpdate;
      }

      if (this.formGroup.value.dateRadioButton === 'now') {
        this.formGroup.value.dateSelection = moment().toDate();
      }

      if (this.formGroup.value.dateRadioButton === 'endOfPeriod') {
        updateDto.endOfPeriod = true;
      }

      if (this.formGroup.value.dateRadioButton === 'future') {
        const formattedUpdateDate: Date = this.formGroup.value.dateSelection;
        formattedUpdateDate.setHours(3, 0, 0, 0);
        updateDto.updateDate = formattedUpdateDate;
      }

      updateDto.honorCommitment = this.formGroup.value.honorCommitment || false;

      updateDto.data = await this.handlerFormComponent?.instance.getData();
      this.dto = updateDto;
      return updateDto;
    } else {
      return null;
    }
  }

  async submitUpdate() {
    this.processing = true;
    try {
      if (this.dto)
        await this.subscriptionSvc.bulkUpdateSubscription(this.data.accountId, this.data?.filters, this.dto);
      this.snacks.open('Bulk subscription update executed, email notification pending.', 'Ok', {
        duration: 6000,
        panelClass: 'mat-primary',
      });
      this.processing = false;
      this.dialogRef.close();
    } catch (error) {
      this.processing = false;
      this.snacks.open('Subscription could not be cancelled', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }
  }
}
